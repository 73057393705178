import { PATH_AUTH_API } from 'constants/api-routes';
import { useEffect, useState } from 'react';
import { useCheckTokenMutation } from 'services';
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';
const socketInstances: { [key: string]: Socket | null } = {};

export const getToken = () => localStorage.getItem('token') || '';

export const INIT_B2C2 = 'b2c2-init';

const RECONNECTION_DELAY = 10000;

type TokenAuthResponseType = {
  data: { result: boolean; message: string };
};

type UseSocketIOProps = {
  url: string;
  options?: Partial<ManagerOptions & SocketOptions>;
};

export const useSocketIO = (props?: UseSocketIOProps) => {
  const { url = SOCKET_URL, options = {} } = props || {};

  const [initialized, setInitialized] = useState(false);
  const [checkToken] = useCheckTokenMutation();

  const logOut = () => {
    localStorage.removeItem('token');
    window.location.replace(PATH_AUTH_API.ROOT);
  };

  const initSocketInstance = async () => {
    try {
      const token = getToken();
      const {
        data: { result: isTokenValid = false },
      } = (await checkToken({ token })) as TokenAuthResponseType;

      if (!isTokenValid) {
        logOut();
      } else {
        if (!socketInstances[url]) {
          socketInstances[url] = io(url, {
            auth: { token },
            reconnectionDelay: RECONNECTION_DELAY,
            ...options,
          });
        }
        setInitialized(() => true);
      }
    } catch (e) {
      console.error('Error initializing socket:', e);
    }
  };

  useEffect(() => {
    if (!initialized) {
      initSocketInstance();
    }
  }, [initialized]);

  return { socket: socketInstances[url] };
};
