const ROOTS_AUTH = '/auth';
const ROOTS_USER = '/user';
const ROOTS_REVIEW = '/review';
const ROOTS_PDF = '/pdf';
const ROOTS_ACCOUNT_STATEMENTS = '/account-statement';
const ROOT_NOTIFICATIONS = '/notification';
const ROOT_GREADER = '/greader';
const ROOT_CRYPTO_CURRENCY = '/crypto-currency';
const ROOT_COUNTRY = '/country';

export const PATH_AUTH_API = {
  ROOT: ROOTS_AUTH,
  TOKEN_VERIFY: `${ROOTS_AUTH}/check-token`,
  CHECK_EMAIL: `${ROOTS_AUTH}/check-email`,
  CHECK_PHONE: `${ROOTS_AUTH}/check-phone`,
  CHECK_REGISTRATION_NUMBER: `${ROOTS_AUTH}/check-entity-registration-number`,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  REGISTER_ENTITY: `${ROOTS_AUTH}/register-entity`,
  FORGOT_PASSWORD: `${ROOTS_AUTH}/forgot-password`,
  RESET_PASSWORD: `${ROOTS_AUTH}/reset-password`,
  CONFIRM_VERIFICATION: `${ROOTS_AUTH}/verify`,
  GET_USER: `${ROOTS_AUTH}/user/profile`,
  SEND_2FA_CODE: `${ROOTS_AUTH}/transaction-send-code`,
  VERIFY_2FA_CODE: `${ROOTS_AUTH}/transaction-verify-code`,
  GENERATE_2FA_TOTP: `${ROOTS_AUTH}/2fa-totp/generate`,
  VERIFY_LOGIN_2FA_TOTP: `${ROOTS_AUTH}/login/2fa-totp/verify`,
  VERIFY_2FA_TOTP: `${ROOTS_AUTH}/2fa-totp/verify`,
  SEND_PHONE_CODE: `${ROOTS_AUTH}/register/phone/send-code`,
  SEND_EMAIL_REGISTER_CODE: `${ROOTS_AUTH}/register/email/send-code`,
  SEND_EMAIL_CODE: `${ROOTS_AUTH}/email/send-code`,
  VERIFY_PHONE_CODE: `${ROOTS_AUTH}/register/phone`,
  CHANGE_2FA: `${ROOTS_AUTH}/2fa`,
  COUNTRY: ROOT_COUNTRY,
};

export const PATH_USER_API = {
  ROOT: ROOTS_USER,
  PROFILE: `${ROOTS_USER}/profile`,
  BALANCE_HISTORY: `${ROOTS_USER}/balance-history`,
  PASSWORD: `${ROOTS_USER}/password`,
  BALANCE: `${ROOTS_USER}/balance`,
  AVAILABLE_BALANCE: `${ROOTS_USER}/system-balance`,
  IBAN: `${ROOTS_USER}/iban`,
  EXTERNAL_WALLET: `/external-wallet`,
  CHANGE_2FA: `${ROOTS_USER}/2fa`,
  DELETE_2FA_TOTP: `${ROOTS_USER}/2fa-totp`,
  UPLOAD_AVATAR: `${ROOTS_USER}/avatar`,
  SET_DEFAULT_CURRENCY: `${ROOTS_USER}/set-default-currency`,
};

export const CRYPTO_CURRENCY_API = {
  ROOT: ROOT_CRYPTO_CURRENCY,
  ASSETS: `${ROOT_CRYPTO_CURRENCY}/assets`,
  ACTIVATE: `${ROOT_CRYPTO_CURRENCY}/activate`,
};

export const PATH_USER_PDF_API = {
  ROOT: ROOTS_PDF,
  ACCOUNT_CONFIRMATION: `${ROOTS_PDF}/account-confirmation`,
  ACCOUNT_STATEMENT: `${ROOTS_PDF}/account-statement`,
};

export const PATH_ACCOUNT_STATEMENT_API = {
  ROOT: ROOTS_ACCOUNT_STATEMENTS,
  ACCOUNT_STATEMENT_FILTERS: `${ROOTS_ACCOUNT_STATEMENTS}/filters`,
};

export const PATH_NOTIFICATIONS_API = {
  UNREAD: `${ROOT_NOTIFICATIONS}/unread`,
  READ: `${ROOT_NOTIFICATIONS}/read`,
  ALL: `${ROOT_NOTIFICATIONS}/all`,
  FILTER_VALUES: `${ROOT_NOTIFICATIONS}/filter-values`,
};

export const PATH_TRANSACTIONS_API = {
  WITHDRAW_CRYPTO: '/withdraw/request/crypto',
  WITHDRAW_FIAT: '/withdraw/request/fiat',
  EXCHANGE: '/exchange',
  EXCHANGE_SEND: '/exchange/send',
  EXCHANGE_FEE: '/exchange/fee',
  HISTORY: '/transaction-history',
  RECEIVE_FIAT_LIMIT: '/receive-fiat/limit',
  RECEIVE_FIAT: '/receive-fiat',
  EXCHANGE_FIAT_RATE: '/exchange-fiat/rate',
  CREATE_WITHDRAW_TRANSACTION: '/withdraw/send-crypto',
};

export const PATH_REVIEW_API = {
  ROOT: ROOTS_REVIEW,
  USER: `${ROOTS_REVIEW}/user`,
  MY: `${ROOTS_REVIEW}/my`,
};

export const PATH_GREADER_API = {
  ROOT: ROOT_GREADER,
};
