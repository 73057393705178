import { Divider, Paper, Stack, Typography, styled, Box } from '@mui/material';
import { Dispatch, FC, memo, SetStateAction } from 'react';
import { CryptoOrderBlock } from 'components/crypto_order_block/crypto_order_block';
import { Crypto } from 'store/wallet/types';
import { useAppDispatch } from 'store';
import { useCheckKYC, useServerError } from 'hooks';
import { setModalState } from 'store/ui';
import { useRatesContext } from 'context/RatesContext';
import { useGetCryptoCurrencyAssetsQuery } from '../../services';
import { NoItemsWrapper } from '../../shared';

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

interface ICryptoSection {
  setSelectedCrypto: Dispatch<
    SetStateAction<{ symbol: string | Crypto; nativeAsset: string }>
  >;
}

export const CryptoSection: FC<ICryptoSection> = memo(
  ({ setSelectedCrypto }) => {
    const { check: checkKYC } = useCheckKYC();

    const dispatch = useAppDispatch();

    const { rates, isLoadingRates } = useRatesContext();

    const {
      data: cryptoCurrency,
      isFetching: cryptoCurrencyIsFetching,
      error: cryptoCurrencyError,
      isError: cryptoCurrencyIsError,
    } = useGetCryptoCurrencyAssetsQuery({});

    useServerError({
      isError: cryptoCurrencyIsError,
      error: cryptoCurrencyError,
    });

    const onExchangeOrderClick = ({
      symbol,
      nativeAsset,
    }: {
      symbol: Crypto;
      nativeAsset: string;
    }) => {
      if (!checkKYC()) return;

      dispatch(setModalState({ visible: true, name: 'exchange' }));
      setSelectedCrypto({ symbol, nativeAsset });
    };

    const onDepositOrderClick = (symbol: Crypto) => {
      if (!checkKYC()) return;

      dispatch(setModalState({ visible: true, name: 'deposit', symbol }));
    };

    const onSendCrypto = (symbol: Crypto) => {
      if (!checkKYC()) return;

      dispatch(setModalState({ visible: true, name: 'sendCrypto', symbol }));
    };

    return (
      <Section
        sx={{ mt: 4, padding: { sm: '24px', xs: '16px' } }}
        elevation={4}
      >
        <Typography variant="h5" color="inherit" noWrap align="left">
          Crypto Assets
        </Typography>
        <NoItemsWrapper
          length={cryptoCurrency?.assets?.length as number}
          isLoading={
            !cryptoCurrency?.assets?.length &&
            cryptoCurrencyIsFetching &&
            isLoadingRates
          }
        >
          <Stack spacing={1} width="100%">
            {cryptoCurrency?.assets
              .filter((el) => el.isActive)
              .map((ec, idx) => {
                const key = ec.assetWalletName as keyof typeof rates;

                const rate = rates[key];

                return (
                  <Box key={ec.id + idx}>
                    <CryptoOrderBlock
                      onSend={() => onSendCrypto(ec.symbol)}
                      onExchange={() =>
                        onExchangeOrderClick({
                          symbol: ec.symbol,
                          nativeAsset: ec.nativeAsset,
                        })
                      }
                      onDeposit={() => onDepositOrderClick(ec.symbol)}
                      coinOptions={{
                        ...ec,
                        rate,
                      }}
                    />
                    {idx !== cryptoCurrency?.assets.length - 1 ? (
                      <Divider />
                    ) : null}
                  </Box>
                );
              })}
          </Stack>
        </NoItemsWrapper>
      </Section>
    );
  }
);
