import { Box, Button, Grid, Typography, styled, Skeleton } from '@mui/material';
import React, { FC, memo, useMemo } from 'react';
import { CurrencyExchange, Payment, Send } from '@mui/icons-material';
import { useAppSelector } from 'store';
import { CurrencyIconComponent } from '../currency_image';
import { ExchangeCoin } from '../../shared/currencies/currencies';
import { numberFormatHelper } from '../../helpers/numberFormatHelper';
import { formatPrice } from 'utils/crypto';

const OrderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

type CoinOptions = ExchangeCoin & {
  rate: string;
  nativeAsset: string;
};

type CryptoOrderBlockProps = {
  onExchange: () => void;
  onSend: () => void;
  onDeposit: () => void;
  coinOptions: CoinOptions;
};

const columnStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const CryptoOrderBlock: FC<CryptoOrderBlockProps> = memo(
  ({ onExchange, onDeposit, onSend, coinOptions }) => {
    const { selectedCurrency } = useAppSelector((s) => s.uiReducer);

    const { balance, isLoadingBalance } = useAppSelector(
      (state) => state.accountReducer
    );

    const accountBalance: number = useMemo(() => {
      return (
        (balance?.deposit &&
          Object.values(balance?.deposit).find(
            (el) => el.value === coinOptions?.assetWalletName
          )?.total) ||
        0
      );
    }, [balance, coinOptions]);

    const accountConvertedBalance: number = useMemo(() => {
      return (
        Number(
          balance?.deposit &&
            Object.values(balance?.deposit).find(
              (el) => el.value === coinOptions?.assetWalletName
            )?.convertedTotal
        ) || 0
      );
    }, [balance, coinOptions]);

    const coinRate = formatPrice(coinOptions?.rate, 2);

    return (
      <OrderWrapper sx={{ flexDirection: { lg: 'row', xs: 'column' } }}>
        <Grid
          container
          alignItems="center"
          sx={{
            width: { lg: '70%', xs: '100%' },
            gap: { md: '0', lg: '0', xs: '20px' },
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            minWidth={150}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }} mr={1}>
              <Box width={30} height={30} mr={1}>
                <CurrencyIconComponent
                  iconString={coinOptions.icon as string}
                />
              </Box>
              <Typography mr={1} fontWeight="bold">
                {coinOptions.symbol}
              </Typography>
              <Typography variant="body2" component="p">
                {coinOptions.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Typography ml={1} display="inline-flex">
                1 {coinOptions.symbol} =&nbsp;
                {!!coinOptions?.rate ? (
                  coinRate
                ) : (
                  <Skeleton variant="text" sx={{ width: '48px' }} />
                )}
                &nbsp;
                {selectedCurrency.symbol}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
          >
            <Box sx={columnStyles}>
              <Typography textAlign="left">Balance</Typography>
              <Typography mt={1}>
                {!accountBalance && isLoadingBalance ? (
                  <Skeleton variant="text" sx={{ width: '160px' }} />
                ) : (
                  <>
                    {numberFormatHelper(accountBalance)}
                    {' ≃ '}
                    {formatPrice(accountConvertedBalance, 2)}{' '}
                    {selectedCurrency.symbol}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: { lg: '30%', xs: '100%' },
            gap: { lg: '30px', xs: '20px' },
          }}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              onClick={onDeposit}
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Payment />
              <Typography>Receive</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSend}
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 90,
              }}
            >
              <Send />
              <Typography>Send</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onExchange}
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CurrencyExchange />
              <Typography>Convert</Typography>
            </Button>
          </Grid>
        </Grid>
      </OrderWrapper>
    );
  }
);
