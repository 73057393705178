// created by Artem
import { FC, memo } from 'react';
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  TableCell,
  TableCellProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatPrice } from 'utils';
import {
  IDeposits,
  IExchanges,
  IWithdrawals,
  TransactionTypeEnum,
} from '../../types/operation-history.interface';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useNotification } from '../../hooks';
import {
  getBackgroundColorByStatusHelper,
  getColorByStatusHelper,
} from '../../helpers/getColorByStatusHelper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type OperationHistoryRowComponentProps = {
  data: IExchanges | IDeposits | IWithdrawals;
};

const TOOLTIP_TEXT =
  'This includes both the Gas fee (in fiat) and the System fee.';

export const OperationHistoryRowComponent: FC<OperationHistoryRowComponentProps> =
  memo(({ data }) => {
    const { showNotification } = useNotification();
    const copyHandle = () => {
      navigator?.clipboard?.writeText?.(`${data?.txHash || ''}`);
      showNotification('Hash was copied to the clipboard!', 'info');
    };

    return (
      <TableRow>
        <TableCell id={`id-${data?.id}`}>#{data?.id}</TableCell>
        <TableCell
          id={`details-${data?.id}`}
          sx={{ textTransform: 'capitalize' }}
        >
          {data?.type === TransactionTypeEnum.EXCHANGES
            ? 'Convert'
            : data?.type?.slice(0, -1)}
        </TableCell>
        <TableCell id={`status-${data?.id}`}>
          <Chip
            label={data?.status}
            size="small"
            variant="outlined"
            sx={{
              color: getColorByStatusHelper(data?.status),
              borderColor: getColorByStatusHelper(data?.status),
              background: getBackgroundColorByStatusHelper(data?.status),
            }}
          />
        </TableCell>
        <AmountRow data={data} />
        <TableCell>
          <Typography paragraph m={0}>
            {data?.date}
          </Typography>
          {data?.time && <small>{data.time}</small>}
        </TableCell>
        <TableCell>
          {data?.txHash && (
            <Button
              variant="outlined"
              onClick={copyHandle}
              startIcon={<ContentCopyIcon />}
            >
              Hash
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  });

const AmountRow: FC<{ data: any } & TableCellProps> = ({ data, ...props }) => {
  switch (data?.type) {
    case 'withdrawals':
      return (
        <TableCell {...props} id={`amount-withdrawals-${data?.id}`}>
          <Typography
            display="flex"
            component="div"
            id={`amount-withdrawals-total-${data?.id}`}
          >
            {data?.total}
            <FormHelperText sx={{ ml: '5px' }}>
              {data?.fiat || data?.assetName}
            </FormHelperText>
          </Typography>
          {data?.walletAddress && (
            <FormHelperText>Address: {data?.walletAddress}</FormHelperText>
          )}
          {data?.networkFee && (
            <FormHelperText>
              Network fee: {data?.networkFee} {data?.asset || data?.assetName}
            </FormHelperText>
          )}
        </TableCell>
      );

    case 'exchanges':
      return (
        <TableCell {...props} id={`amount-exchanges-${data?.id}`}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              display="flex"
              component="div"
              id={`amount-exchanges-total-${data?.id}`}
            >
              {Number(data?.assetAmount) || 0}
              <FormHelperText sx={{ ml: '5px' }}>
                {data?.asset || data?.assetName}
              </FormHelperText>
            </Typography>
            <ArrowForwardIcon sx={{ mx: 0.5 }} />
            <Typography
              display="flex"
              component="div"
              id={`amount-exchanges-fiat-${data?.id}`}
            >
              {Number(data?.fiatAmount) || 0}
              <FormHelperText sx={{ ml: '5px' }}>{data?.fiat}</FormHelperText>
            </Typography>
          </Box>
          {data?.fee && (
            <FormHelperText sx={{ display: 'inline-flex' }}>
              Fee{' '}
              <Tooltip title={TOOLTIP_TEXT}>
                <HelpOutlineOutlinedIcon
                  color="inherit"
                  sx={{
                    fontSize: '14px',
                    ml: '1px',
                    transform: 'translateY(2px)',
                  }}
                />
              </Tooltip>
              : {formatPrice(data.fee)} {data?.fiat}
            </FormHelperText>
          )}
          {data?.rate && (
            <FormHelperText>Rate: {formatPrice(data?.rate)}</FormHelperText>
          )}
          {data?.gasFee && (
            <FormHelperText>Gas fee: {data?.gasFee} ETH</FormHelperText>
          )}
        </TableCell>
      );

    case 'deposits':
      return (
        <TableCell {...props} id={`amount-deposits-${data?.id}`}>
          <Typography
            display="flex"
            component="div"
            id={`amount-deposits-total-${data?.id}`}
          >
            {data?.assetAmount}
            <FormHelperText sx={{ ml: '5px' }}>
              {data?.asset || data?.assetName}
            </FormHelperText>
          </Typography>
        </TableCell>
      );

    default:
      return null;
  }
};
