import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { IAddress } from '../../../types/user.interface';
import { Box } from '@mui/material';

const StyledTypography = styled(Typography)(() => ({
  width: '100%',
  textWrap: 'nowrap',
  textAlign: 'left',
  padding: '3px 0',
}));

const StyledSecondTypography = styled(Typography)(() => ({
  textAlign: 'left',
  color: '#666766',
  padding: '3px 0',
}));

interface AddressProps {
  address: IAddress | undefined;
}

export const Address: React.FC<AddressProps> = ({ address }) => {
  return (
    <Paper elevation={2} sx={{ p: 2, mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Address
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {address?.street && <StyledTypography>Street:</StyledTypography>}
          {address?.subStreet && (
            <StyledTypography>Sub street:</StyledTypography>
          )}
          {address?.state && <StyledTypography>State:</StyledTypography>}
          {address?.town && <StyledTypography>Town:</StyledTypography>}
          {address?.postCode && <StyledTypography>Post code:</StyledTypography>}
          {address?.country && <StyledTypography>Country:</StyledTypography>}
        </Box>
        <Box
          sx={{
            display: 'grid',
          }}
        >
          {address?.street && (
            <StyledSecondTypography noWrap title={address?.street}>
              {address?.street}
            </StyledSecondTypography>
          )}
          {address?.subStreet && (
            <StyledSecondTypography noWrap title={address?.subStreet}>
              {address?.subStreet}
            </StyledSecondTypography>
          )}
          {address?.state && (
            <StyledSecondTypography noWrap title={address?.state}>
              {address?.state}
            </StyledSecondTypography>
          )}
          {address?.town && (
            <StyledSecondTypography noWrap title={address?.town}>
              {address?.town}
            </StyledSecondTypography>
          )}
          {address?.postCode && (
            <StyledSecondTypography noWrap title={address?.postCode}>
              {address?.postCode}
            </StyledSecondTypography>
          )}
          {address?.country && (
            <StyledSecondTypography noWrap title={address?.country}>
              {address?.country}
            </StyledSecondTypography>
          )}
        </Box>
      </Box>
      {address?.formattedAddress && (
        <StyledTypography>Formatted address:</StyledTypography>
      )}
      {address?.formattedAddress && (
        <StyledSecondTypography title={address?.formattedAddress}>
          {address?.formattedAddress}
        </StyledSecondTypography>
      )}
    </Paper>
  );
};
