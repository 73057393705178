import React, { FC } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';

type AvailableSoonDialogContentProps = {
  onClose: () => void;
};

export const AvailableSoonDialogContent: FC<
  AvailableSoonDialogContentProps
> = ({ onClose }) => {
  const closeModalHandler = () => {
    onClose();
  };

  return (
    <DialogContent>
      <Typography variant="h6" padding={'127px 0 127px 24px'}>
        This feature will be available soon!
      </Typography>
      <DialogActions sx={{ mt: 3 }}>
        <Button
          onClick={closeModalHandler}
          type="button"
          variant="contained"
          color="primary"
        >
          Back to Dashboard
        </Button>
      </DialogActions>
    </DialogContent>
  );
};
