import { useEffect, useState } from 'react';
import {
  useGetNotificationsQuery,
  useGetReadNotificationsQuery,
} from 'services';
import { Notification } from 'types/wallet.interface';
import { useNotification } from './useNotification';
import { getToken, useSocketIO } from './useSocketIO';

const GET_NOTIFICATIONS_EVENT_LISTENER = 'notification-sent';
const READ_NOTIFICATIONS_EVENT_LISTENER = 'notification-read';

type WSNotificationType = {
  unreadNotifications: Notification[];
  readNotifications: Notification[];
  hasUnreadNotifications: boolean;
  onReadHandler(notification: Notification): void;
  refetch(): void;
  isLoadingReadNotifications: boolean;
  hasMore: boolean;
};

const DEFAULT_LIMIT = 10;

export const useWSNotifications = (): WSNotificationType => {
  const [unreadNotifications, setUnreadNotifications] = useState<
    Notification[]
  >([]);
  const [readNotifications, setReadNotifications] = useState<Notification[]>(
    []
  );

  const { showNotification } = useNotification();
  const { socket } = useSocketIO();
  const token = getToken();

  const {
    data: unreadNotificationsData,
    isLoading: isLoadingUnreadNotifications,
    refetch,
  } = useGetNotificationsQuery({}, { skip: !token });

  const {
    data: readNotificationsData,
    isFetching: isFetchingReadNotifications,
    isSuccess: isSuccessReadNotifications,
  } = useGetReadNotificationsQuery(
    {
      limit: DEFAULT_LIMIT,
    },
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: false,
      refetchOnFocus: false,
    }
  );

  useEffect(() => {
    if (!socket?.connected) {
      socket?.connect();
      socket?.on(
        GET_NOTIFICATIONS_EVENT_LISTENER,
        (notification: Notification) => {
          showNotification('You have new unread notification.', 'info');

          setUnreadNotifications((prevNotifications) => [
            notification,
            ...prevNotifications,
          ]);
        }
      );
    }

    return () => {
      socket?.disconnect();
      socket?.off(GET_NOTIFICATIONS_EVENT_LISTENER);
    };
  }, [socket]);

  useEffect(() => {
    if (!isLoadingUnreadNotifications) {
      setUnreadNotifications(() => unreadNotificationsData as Notification[]);
    }
  }, [isLoadingUnreadNotifications, unreadNotificationsData]);

  useEffect(() => {
    if (isSuccessReadNotifications && readNotificationsData) {
      setReadNotifications(() => readNotificationsData.items as Notification[]);
    }
  }, [isSuccessReadNotifications, readNotificationsData]);

  const onReadHandler = (notification: Notification) => {
    const { id } = notification;

    socket?.emit(READ_NOTIFICATIONS_EVENT_LISTENER, { ids: [id] });

    setUnreadNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== id)
    );

    setReadNotifications((prevState) => [
      { ...notification, read: !notification.read },
      ...prevState,
    ]);
  };

  const hasUnreadNotifications =
    (unreadNotifications || []).filter((notification) => !notification.read)
      .length > 0;

  const hasMore = !!readNotificationsData?.items?.length;

  return {
    unreadNotifications,
    readNotifications,
    hasUnreadNotifications,
    onReadHandler,
    refetch,
    isLoadingReadNotifications: isFetchingReadNotifications,
    hasMore,
  };
};
