import {
  PATH_NOTIFICATIONS_API,
  PATH_TRANSACTIONS_API,
  PATH_USER_API,
} from 'constants/api-routes';
import {
  GetOriginalWalletBalanceResponse,
  MappedWalletBalanceResponse,
  SystemBalance,
  SendCryptoPayload,
  SendFiatPayload,
  ExchangePayload,
  NotificationsResponse,
  ReceiveFiat,
  ConvertFiatFee,
  CreateWithdrawTransactionRequest,
  NotificationsPaginatedResponse,
  NotificationsFilterValuesResponse,
} from 'types/wallet.interface';
import { Crypto, Fiat } from 'store/wallet/types';
import {
  IDeposits,
  IExchanges,
  IWithdrawals,
  TransactionHistoryResponse,
} from 'types/operation-history.interface';
import { baseApi } from './base.api';

export const walletService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<NotificationsResponse, any>({
      query: () => ({
        url: `${PATH_NOTIFICATIONS_API.UNREAD}`,
      }),
      providesTags: ['UnreadNotifications'],
    }),
    getReadNotifications: build.query<
      NotificationsPaginatedResponse,
      { limit?: number; page?: number }
    >({
      query: (params) => ({
        url: `${PATH_NOTIFICATIONS_API.READ}`,
        params,
      }),
      providesTags: ['ReadNotifications'],
    }),
    getAllNotifications: build.query<
      NotificationsPaginatedResponse,
      {
        limit?: number;
        page?: number;
        status?: number | null;
        sort?: number;
        type?: string;
        dateFrom?: string;
        dateTo?: string;
      }
    >({
      query: (params) => ({
        url: `${PATH_NOTIFICATIONS_API.ALL}`,
        params,
      }),
      providesTags: ['Notifications'],
    }),
    getNotificationFilterValues: build.query<
      NotificationsFilterValuesResponse,
      void
    >({
      query: () => ({
        url: `${PATH_NOTIFICATIONS_API.FILTER_VALUES}`,
      }),
      providesTags: ['NotificationsFilters'],
    }),
    getTransactions: build.query<
      IExchanges[] | IDeposits[] | IWithdrawals[],
      { transactionType?: string; lastDays?: string | number } | undefined
    >({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.HISTORY}`,
        params,
      }),
      transformResponse: (response: TransactionHistoryResponse) => {
        return Object.keys(response).reduce((acc: any[], key) => {
          const el: any[] = (response as any)?.[key]?.map((v: any) => {
            const date = v?.createdAt && new Date(v.createdAt);

            return {
              ...v,
              type: key,
              date: `${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()}`,
              time: `${date.getHours()}h:${date.getMinutes()}m`,
            };
          });

          return [...acc, ...(el || [])];
        }, []);
      },
      providesTags: ['Transactions'],
    }),
    sendCrypto: build.mutation<any, SendCryptoPayload>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.WITHDRAW_CRYPTO}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'Transactions'],
    }),
    sendFiat: build.mutation<any, SendFiatPayload>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.WITHDRAW_FIAT}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'Transactions'],
    }),
    receiveFiat: build.mutation<ReceiveFiat, { amount: number; fiat: string }>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.RECEIVE_FIAT}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'Transactions'],
    }),
    getFiatLimit: build.query<{ limit: number }, { fiat: string }>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.RECEIVE_FIAT_LIMIT}`,
        params,
      }),
    }),
    exchange: build.mutation<any, ExchangePayload>({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.EXCHANGE_SEND}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'System Balance', 'Transactions'],
    }),
    getExchangeFee: build.query<
      {
        fee: number;
        ethGasFee: number;
        maxLimit?: number;
        rate?: number;
        quoteId?: number;
        finalValue?: number;
      },
      { assetId: string; amount: number; fiat: string }
    >({
      query: ({ assetId, amount, fiat }) => ({
        url: `${PATH_TRANSACTIONS_API.EXCHANGE_FEE}`,
        params: {
          assetId,
          amount,
          fiat,
        },
      }),
    }),
    getExchangeFiatRate: build.query<
      ConvertFiatFee,
      { amount: number; fiatFrom: string; fiatTo: string }
    >({
      query: ({ amount, fiatFrom, fiatTo }) => ({
        url: `${PATH_TRANSACTIONS_API.EXCHANGE_FIAT_RATE}`,
        params: {
          amount,
          fiatFrom,
          fiatTo,
        },
      }),
    }),
    getBalance: build.query<MappedWalletBalanceResponse, { fiat?: Fiat }>({
      query: ({ fiat }) => ({
        url: `${PATH_USER_API.BALANCE}`,
        params: {
          fiat,
        },
      }),
      providesTags: ['Balance'],
      transformResponse: (
        res: GetOriginalWalletBalanceResponse
      ): MappedWalletBalanceResponse => {
        const notAllowed = ['ETH_TEST3'];
        const mappedCrypto = res.cryptoBalances
          .filter((bal) => !notAllowed.includes(bal.asset))
          .map(({ asset, total, address, convertedTotal, symbol, rate }) => {
            return {
              [symbol as Crypto]: {
                total,
                address,
                value: asset,
                convertedTotal,
                rate,
              },
            };
          })
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as any;
        const mappedFiat = res.fiatBalances
          .map(({ total, fiat, convertedTotal, rate }) => ({
            [fiat]: { total: total as string, convertedTotal, rate },
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as any;
        return {
          withdraw: mappedFiat,
          deposit: mappedCrypto,
          total: res.total,
        };
      },
    }),
    getSystemBalance: build.query<SystemBalance, {}>({
      query: () => ({
        url: `${PATH_USER_API.AVAILABLE_BALANCE}`,
      }),
      providesTags: ['System Balance'],
    }),
    getAmountLimits: build.query<
      { minAmount: number; maxAmount: number },
      { assetId: string }
    >({
      query: ({ assetId }) => ({
        url: `${PATH_TRANSACTIONS_API.EXCHANGE}/${assetId}/amount-limits`,
      }),
    }),
    createWithdrawTransaction: build.mutation<
      void,
      CreateWithdrawTransactionRequest
    >({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.CREATE_WITHDRAW_TRANSACTION}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Balance', 'Transactions'],
    }),
    getCryptoWithdrawNetworkFee: build.query<
      { networkFee: number },
      { assetId: string; amount: number }
    >({
      query: (params) => ({
        url: `${PATH_TRANSACTIONS_API.CREATE_WITHDRAW_TRANSACTION}/network-fee`,
        method: 'GET',
        params: params,
      }),
    }),
    getSendCryptoSettings: build.query<
      { minAmount: number; showNetworkFee: boolean },
      void
    >({
      query: () => ({
        url: `${PATH_TRANSACTIONS_API.CREATE_WITHDRAW_TRANSACTION}/settings`,
      }),
    }),
  }),
});

export const {
  useExchangeMutation,
  useGetBalanceQuery,
  useGetTransactionsQuery,
  useGetSystemBalanceQuery,
  useGetExchangeFeeQuery,
  useSendCryptoMutation,
  useSendFiatMutation,
  useGetNotificationsQuery,
  useGetFiatLimitQuery,
  useReceiveFiatMutation,
  useGetExchangeFiatRateQuery,
  useGetAmountLimitsQuery,
  useCreateWithdrawTransactionMutation,
  useGetCryptoWithdrawNetworkFeeQuery,
  useGetSendCryptoSettingsQuery,
  useGetReadNotificationsQuery,
  useGetAllNotificationsQuery,
  useGetNotificationFilterValuesQuery,
} = walletService;
