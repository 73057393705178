export const CorporateIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16927 22.3757C3.59636 22.3757 3.10573 22.1715 2.6974 21.7632C2.28906 21.3548 2.08524 20.8645 2.08594 20.2923V8.83399C2.08594 8.26107 2.29011 7.77044 2.69844 7.36211C3.10677 6.95378 3.59705 6.74996 4.16927 6.75065H8.33594V4.66732C8.33594 4.0944 8.54011 3.60378 8.94844 3.19544C9.35677 2.78711 9.84705 2.58329 10.4193 2.58399H14.5859C15.1589 2.58399 15.6495 2.78815 16.0578 3.19649C16.4661 3.60482 16.67 4.0951 16.6693 4.66732V6.75065H20.8359C21.4089 6.75065 21.8995 6.95482 22.3078 7.36315C22.7162 7.77149 22.92 8.26176 22.9193 8.83399V20.2923C22.9193 20.8652 22.7151 21.3559 22.3068 21.7642C21.8984 22.1725 21.4082 22.3763 20.8359 22.3757H4.16927ZM4.16927 20.2923H20.8359V8.83399H4.16927V20.2923ZM10.4193 6.75065H14.5859V4.66732H10.4193V6.75065Z"
        fill="#8D8A9B"
      />
    </svg>
  );
};
