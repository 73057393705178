import { createContext, useContext, ReactNode, useEffect } from 'react';
import { useWSRates } from '../hooks';
import { useGetUserQuery } from '../services';
import { GetExchangeRateMessageArgument } from '../hooks/useWSRates';

type RatesContextValues = {
  rates: { [key: string]: any };
  isLoadingRates: boolean;
  sendGetRateMessage: (argument: GetExchangeRateMessageArgument) => void;
};

const RatesContext = createContext<RatesContextValues>({
  rates: {},
  isLoadingRates: true,
  sendGetRateMessage: () => {},
});

const INTERVAL = 10000;

export const useRatesContext = () => useContext(RatesContext);

export const RatesProvider = ({ children }: { children: ReactNode }) => {
  const { data: userData } = useGetUserQuery();

  const { sendGetRateMessage, rates, isSocketInitialized } = useWSRates();

  const onChangeCurrency = () => {
    sendGetRateMessage({
      toCurrency: userData!.defaultCurrency,
      isFuze: userData?.provider === 'FUZE',
    });
  };

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (userData && !Object.keys(rates)?.length && isSocketInitialized) {
      onChangeCurrency();
    }
    if (userData && Object.keys(rates)?.length) {
      interval = setInterval(onChangeCurrency, INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [userData, rates, isSocketInitialized]);

  return (
    <RatesContext.Provider
      value={{
        rates,
        isLoadingRates: !Object.keys(rates)?.length,
        sendGetRateMessage,
      }}
    >
      {children}
    </RatesContext.Provider>
  );
};
