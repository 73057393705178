import { IUser } from 'types/user.interface';
import { FIAT_CURRENCY_LIST } from './currencies';

export function getFilteredCurrencyList(userData: IUser | undefined) {
  if (!userData) return FIAT_CURRENCY_LIST; // Return the default list if no userData

  if (userData.provider === 'FUZE') {
    // If provider is FUZE, include only USD and AED
    return FIAT_CURRENCY_LIST.filter((currency) =>
      ['USD', 'AED'].includes(currency.symbol)
    );
  } else {
    // For all other providers, include all except AED
    return FIAT_CURRENCY_LIST.filter((currency) => currency.symbol !== 'AED');
  }
}
