import { Divider, Paper, Typography, styled, Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import { Container } from 'shared/container/container';
import { useGetUserQuery } from 'services';
import { theme } from '../../assets/theme';
import {
  AvatarComponent,
  LikeToDo,
  SelectAccountCurrency,
  VerificationBanner,
  LatestMarketPriceList,
} from '../../components';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

export const DashboardPage: FC = () => {
  const { data: userData } = useGetUserQuery();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          flexDirection: { sm: 'row', xs: 'column' },
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Typography id="welcomeTitle" variant="h4" color="inherit">
          Crypto currency exchanges made easy with QCE
        </Typography>
      </Box>
      <Section
        sx={{
          boxShadow: 'none',
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
            height: '100%',
            flexWrap: { sm: 'nowrap', xs: 'wrap' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
              height: '100%',
              flexWrap: 'wrap',
            }}
          >
            <AvatarComponent width={90} height={90} disableUpload />
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                height: '100%',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  marginBottom: '9px',
                }}
              >
                <Typography
                  color={theme.palette.grey['400']}
                  noWrap
                  align="left"
                  fontSize="1.125rem"
                  whiteSpace={'pre-wrap'}
                >
                  User ID
                </Typography>
                <Typography noWrap align="right" whiteSpace={'pre-wrap'}>
                  {userData?.id}
                </Typography>
              </Box>
              <Divider orientation="vertical" sx={{ minHeight: '90px' }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  marginBottom: '9px',
                }}
              >
                <Typography
                  color={theme.palette.grey['400']}
                  noWrap
                  fontSize="1.125rem"
                  align="left"
                  whiteSpace={'pre-wrap'}
                >
                  Level
                </Typography>
                <Typography
                  noWrap
                  align="right"
                  whiteSpace={'pre-wrap'}
                  title={userData?.Tier?.name}
                >
                  {userData?.Tier?.name}
                </Typography>
              </Box>
              <Divider orientation="vertical" sx={{ minHeight: '90px' }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  marginBottom: '9px',
                }}
              >
                <Typography
                  color={theme.palette.grey['400']}
                  noWrap
                  fontSize="1.125rem"
                  align="left"
                  whiteSpace={'pre-wrap'}
                >
                  Account number
                </Typography>
                <Typography
                  noWrap
                  align="right"
                  whiteSpace={'pre-wrap'}
                  title={userData?.accountNumber}
                >
                  {userData?.accountNumber}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                sx={{ minHeight: '90px', display: { sm: 'block', xs: 'none' } }}
              />
              <Section
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  gap: '20px',
                  flexWrap: 'wrap',
                  width: { sm: 'auto', xs: '100%' },
                  padding: 0,
                }}
              >
                <SelectAccountCurrency
                  title={'Account Balance'}
                  titleSx={{
                    color: theme.palette.grey['400'],
                    fontSize: '1.125rem',
                  }}
                  sx={{ ml: '10px' }}
                />
              </Section>
            </Box>
          </Box>
        </Box>
      </Section>
      <VerificationBanner />
      <LikeToDo />
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <LatestMarketPriceList />
        </Grid>
      </Grid>
    </Container>
  );
};
