import { useGetUserQuery } from 'services';
import { useNotification } from './useNotification';

export const useCheckKYC = () => {
  const { data: user } = useGetUserQuery();
  const { showNotification } = useNotification();

  const checkHandle = () => {
    if (!user?.kycVerified) {
      showNotification('You need to finished KYC process.', 'warning');
    }
    if (!user?.addressVerifiedObject?.addressVerified) {
      showNotification(
        'You need to wait via admins verified your address.',
        'warning'
      );
    }

    return (
      !!user?.kycVerified && !!user?.addressVerifiedObject?.addressVerified
    );
  };

  return {
    check: checkHandle,
    isVerified:
      !!user?.kycVerified && !!user?.addressVerifiedObject?.addressVerified,
    isAddressVerified: user?.addressVerifiedObject?.addressVerified,
    kycVerified: !!user?.kycVerified,
  };
};
