import { IMetaSkipTake } from './pagination.interface';

export enum TransactionTypeEnum {
  WITHDRAWALS = 'withdrawals',
  EXCHANGES = 'exchanges',
  DEPOSITS = 'deposits',
}

export interface TransactionHistoryResponse {
  items: TransactionHistoryItem[];
  meta: IMetaSkipTake;
}

export interface TransactionHistoryItem {
  externalId: string;
  InternalTo: InternalTo;
  InternalFrom: InternalFrom;
  createdAt: string;
  updatedAt: string;
  status: string;
  asset: string;
  fiat: string;
  assetAmount: string;
  fiatAmount: string;
  txHash: string;
  fee: string;
  id: number | string;
  total: string;
  user: UserInterface;
}

export interface UserInterface {
  id: number;
  email: string;
  name: string;
  lastName: string;
  iban: string;
}

export interface InternalTo {
  id: number;
  email: string;
  name: string;
  lastName: string;
}

export interface InternalFrom {
  id: number;
  email: string;
  name: string;
  lastName: string;
}

export interface IWithdrawals {
  id: number | string;
  fiat: string;
  total: string;
  status: string;
  createdAt: string;
  user: UserInterface;
  type: string;
  assetAmount: string;
  fiatAmount: string;
  date: string;
  internalFrom: number;
  internalTo: number;
  time: string;
  txHash: string;
}

export interface IDeposits {
  id: number;
  externalId: string;
  InternalTo: InternalTo;
  InternalFrom: InternalFrom;
  createdAt: string;
  updatedAt: string;
  status: string;
  asset: string;
  fiat: string;
  assetAmount: string;
  fiatAmount: string;
  txHash: string;
  fee: string;
  type: string;
  date: string;
  internalFrom: number;
  internalTo: number;
  time: string;
}

export interface IExchanges {
  id: number;
  externalId: string;
  InternalTo: InternalTo;
  InternalFrom: InternalFrom;
  createdAt: string;
  updatedAt: string;
  status: string;
  asset: string;
  fiat: string;
  assetAmount: string;
  fiatAmount: string;
  txHash: string;
  fee: string;
  type: string;
  date: string;
  internalFrom: number;
  internalTo: number;
  time: string;
  rate: string;
}
