import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Crypto, Deposit } from 'store/wallet/types';
import { QrCode } from 'components/qr_code/qr_code';
import { useGetCryptoCurrencyAssetsQuery } from '../../../services';
import { CurrencyIconComponent } from '../../currency_image';
import { useAppSelector } from 'store';

type WalletFormProps = {
  selectedSymbol?: string | Crypto;
  setWalletSelectedCurrency?: Dispatch<SetStateAction<string>>;
};

export const WalletForm: FC<WalletFormProps> = ({
  selectedSymbol = 'USDT',
  setWalletSelectedCurrency = () => {},
}) => {
  const [selectedAsset, setSelectedAsset] = useState<Crypto | string>(
    selectedSymbol
  );
  const [isCopied, setIsCopied] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string>('');

  const { balance } = useAppSelector((state) => state.accountReducer);

  const { data: cryptoCurrency } = useGetCryptoCurrencyAssetsQuery({});

  const assetWalletNameObj = useMemo(() => {
    return cryptoCurrency?.assets?.find((el) => el?.symbol === selectedAsset);
  }, [selectedAsset]);

  const walletAddressMemo = useMemo(() => {
    if (balance) {
      const assetWalletName = Object.values(balance?.deposit as Deposit)?.find(
        ({ value }) => value === assetWalletNameObj?.assetWalletName
      );

      return (
        balance?.deposit?.[selectedAsset as Crypto]?.address ||
        assetWalletName?.address ||
        ''
      );
    }
    return '';
  }, [selectedAsset]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(walletAddressMemo);
    setIsCopied(true);
  };

  const handleAssetChange = (event: SelectChangeEvent) => {
    setSelectedAsset(event.target.value as Crypto);
    setWalletSelectedCurrency(event.target.value as Crypto);
    setIsCopied(false);
  };

  const qrCodeWalletAddress = useMemo(() => {
    return walletAddressMemo;
  }, [selectedAsset]);

  useEffect(() => {
    if (balance && walletAddressMemo) {
      setWalletAddress(walletAddressMemo as string);
    }
  }, [walletAddressMemo]);

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="from-currency-label">From Currency</InputLabel>
        <Select
          label="From Currency"
          labelId="from-currency-label"
          id="from-currency-select"
          value={selectedAsset}
          onChange={handleAssetChange}
          defaultValue={selectedAsset}
          renderValue={(selected) =>
            cryptoCurrency?.assets
              .filter((el) => el.isActive)
              .filter((ec) => ec.symbol === selected)
              .map((ec) => (
                <Box
                  key={ec.id}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Box width={30} height={30} mr={1}>
                    <CurrencyIconComponent iconString={ec.icon} />
                  </Box>
                  {ec.symbol}
                </Box>
              ))
          }
        >
          {cryptoCurrency?.assets
            .filter((el) => el.isActive)
            .map((ec) => (
              <MenuItem
                key={ec.id}
                value={ec.symbol}
                selected={selectedAsset === ec.symbol}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box width={30} height={30} mr={1}>
                  <CurrencyIconComponent iconString={ec.icon} />
                </Box>
                {ec.symbol}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        margin="normal"
        fullWidth
        label="Wallet Address"
        title={walletAddress}
        value={walletAddress}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <Tooltip title={isCopied ? 'Copied!' : 'Copy'} placement="top">
              <IconButton
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
      <FormHelperText sx={{ fontSize: '0.875rem' }} error>
        Please be aware that you use {assetWalletNameObj?.name}. Do not send
        assets from a different chain!
      </FormHelperText>
      <Box display="flex" flexDirection="column" alignItems="center" my={2}>
        <Typography>Or</Typography>
        <Typography align="left">Scan QR Code</Typography>
      </Box>
      <QrCode url={qrCodeWalletAddress} />
    </>
  );
};
