import React, { FC, Fragment, useEffect, useMemo } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Tooltip,
  IconButton,
  Divider,
  ClickAwayListener,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { useWSNotifications } from 'hooks/useWSNotifications';
import { Notification } from './notification';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { PAGES_PATH } from '../../constants/spa-routes';

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.9;
  }
`;

type NotificationsProps = {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
};

const NotificationsWrapper = styled('div')`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 1000;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-height: 548px;
  overflow: auto;
  color: #000000;
  padding: 20px;
`;

const Dot = styled('span')`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
`;

export const Notifications: FC<NotificationsProps> = ({
  onToggle,
  isOpen = false,
}) => {
  const {
    unreadNotifications,
    readNotifications,
    hasUnreadNotifications,
    onReadHandler,
    refetch,
    hasMore,
  } = useWSNotifications();

  const tooltipText = useMemo(() => {
    return hasUnreadNotifications
      ? 'You have new unread notifications'
      : 'No new notifications';
  }, [hasUnreadNotifications]);

  const handleToggleNotifications = () => {
    onToggle(!isOpen);
  };

  const onClickAwayListener = () => {
    onToggle(false);
  };

  useEffect(() => {
    if (!hasUnreadNotifications) {
      refetch();
    }
  }, [hasUnreadNotifications]);

  return (
    <>
      <Tooltip title={tooltipText} placement="bottom">
        <IconButton
          size="large"
          aria-label="show notifications"
          aria-haspopup="true"
          onClick={handleToggleNotifications}
          color="inherit"
        >
          <NotificationsIcon />
          {hasUnreadNotifications && <Dot />}
        </IconButton>
      </Tooltip>
      {isOpen && (
        <ClickAwayListener onClickAway={onClickAwayListener}>
          <NotificationsWrapper>
            <Typography textAlign="left" fontWeight="bold" mb={1}>
              Unread
            </Typography>
            {unreadNotifications?.length ? (
              unreadNotifications?.map((notification, index) => (
                <Fragment key={notification.id}>
                  <Notification
                    notification={notification}
                    onReadHandler={onReadHandler}
                  />
                  {index !== unreadNotifications.length - 1 && <Divider />}
                </Fragment>
              ))
            ) : (
              <Box my={3}>
                <Typography sx={{ color: '#666766' }}>
                  No new notifications
                </Typography>
              </Box>
            )}
            <Typography textAlign="left" fontWeight="bold" mb={1}>
              Read
            </Typography>
            {readNotifications &&
              readNotifications?.map((notification, index) => (
                <Fragment key={notification.id}>
                  <Notification
                    notification={notification}
                    onReadHandler={onReadHandler}
                  />
                  {index !== readNotifications.length - 1 && <Divider />}
                </Fragment>
              ))}
            {hasMore && (
              <Box justifyContent="center" mt={2}>
                <Button
                  variant={'contained'}
                  component={NavLink}
                  to={PAGES_PATH.NOTIFICATIONS}
                  onClick={handleToggleNotifications}
                >
                  Check all notifications
                </Button>
              </Box>
            )}
          </NotificationsWrapper>
        </ClickAwayListener>
      )}
    </>
  );
};
