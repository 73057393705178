import { FC, memo, useEffect } from 'react';
import { Button, CardContent, Dialog, Divider, TextField } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import { Loader } from '../../../shared';
import Typography from '@mui/material/Typography';
import { QRCodeSVG } from 'qrcode.react';
import {
  use2faTotpGenerateMutation,
  use2faTotpVerifyMutation,
  useDelete2FaTotpMutation,
  useGetUserQuery,
} from '../../../services';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from '../../../utils';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotification, useServerError } from '../../../hooks';

type GoogleLoginProps = {
  open: boolean;
  onClose: () => void;
};

type GoogleFormType = {
  Code: string;
};

export const GoogleLogin: FC<GoogleLoginProps> = memo(({ open, onClose }) => {
  const { showNotification } = useNotification();

  const { data: userData } = useGetUserQuery();

  const [
    generate2FaTotp,
    {
      data: faTotpData,
      isLoading: generateLoading,
      isError: generateIsError,
      error: generateError,
    },
  ] = use2faTotpGenerateMutation();

  const [
    verify2FaTotp,
    {
      isLoading: verify2FaTotpIsLoading,
      isError: verify2FaTotpIsError,
      error: verify2FaTotpError,
      isSuccess: isSuccessVerify,
    },
  ] = use2faTotpVerifyMutation();

  const [
    delete2FaTotp,
    {
      isLoading: delete2FaTotpIsLoading,
      isError: delete2FaTotpIsError,
      error: delete2FaTotpError,
      isSuccess: isSuccessDelete2FaTotp,
    },
  ] = useDelete2FaTotpMutation();

  const { handleSubmit, reset, control } = useForm<GoogleFormType>({
    defaultValues: {
      Code: '',
    },
    resolver: yupResolver(
      object({
        Code: stringScheme({ required: true }),
      })
    ),
  });

  const onSubmit: SubmitHandler<GoogleFormType> = (data) => {
    verify2FaTotp({
      code: data.Code,
    });
  };

  const deleteAccountHandler = () => {
    delete2FaTotp({});
  };

  useServerError({ isError: generateIsError, error: generateError });
  useServerError({ isError: verify2FaTotpIsError, error: verify2FaTotpError });
  useServerError({ isError: delete2FaTotpIsError, error: delete2FaTotpError });

  const qrLink = `otpauth://totp/qbs:${userData?.email}?secret=${userData?.twoFactorGoogleAuthenticatorSecret}&period=30&digits=6&algorithm=SHA1&issuer=qbs`;

  useEffect(() => {
    if (userData) {
      reset({ Code: '' });
    }

    if (
      open &&
      !userData?.twoFactorGoogleAuthenticatorSecret &&
      !userData?.isGoogleAuthenticatorSetUp
    ) {
      generate2FaTotp({});
    }
  }, [open]);

  useEffect(() => {
    if (isSuccessVerify) {
      showNotification('Success!', 'success');
      onClose();
    }
  }, [isSuccessVerify]);

  useEffect(() => {
    if (isSuccessDelete2FaTotp) {
      showNotification('Deleted!', 'success');
      onClose();
    }
  }, [isSuccessDelete2FaTotp]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <CardContent sx={{ overflow: 'auto' }} onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          sx={{ textAlign: 'center' }}
          title="Google Authenticator (2FA)"
        />
        <Divider />
        {!userData?.isGoogleAuthenticatorSetUp ? (
          <>
            <Typography m={1} variant="h5">
              Configuring Google Authenticator or Authy
            </Typography>
            <Divider />
            <Typography>
              <ol>
                <li>
                  Install Google Authenticator (IOS - Android) or Authy (IOS -
                  Android).
                </li>
                <li>In the authenticator app, select "+" icon.</li>
                <li>
                  Select "Scan a barcode (or QR code)" and use the phone's
                  camera to scan this barcode.
                </li>
              </ol>
            </Typography>
            <Divider />
            <Typography m={1} variant="h5">
              Scan QR Code
            </Typography>
            {generateLoading ? (
              <Loader pad={10} />
            ) : (
              (userData?.twoFactorGoogleAuthenticatorSecret ?? faTotpData) && (
                <Box
                  sx={{
                    m: '30px 0',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <QRCodeSVG
                    value={
                      userData?.twoFactorGoogleAuthenticatorSecret
                        ? qrLink
                        : (faTotpData?.otpauthUrl as string)
                    }
                    size={200}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'L'}
                    includeMargin={false}
                  />
                </Box>
              )
            )}
            <Divider />
            <Typography m={1} variant="h5">
              Verify Code
            </Typography>
            <Controller
              control={control}
              name="Code"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  margin="normal"
                  required
                  value={value}
                  onChange={onChange}
                  id="code"
                  label="Authentication code"
                  name="Authentication code"
                  autoComplete="Authentication Code"
                  size="small"
                  sx={{ mb: '16px' }}
                />
              )}
            />
          </>
        ) : (
          <Typography m={1} variant="h5">
            Delete Google Authenticator for this account?
          </Typography>
        )}

        <Divider />
        <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
          Close
        </Button>
        <LoadingButton
          onClick={
            !userData?.isGoogleAuthenticatorSetUp
              ? handleSubmit(onSubmit)
              : deleteAccountHandler
          }
          endIcon={
            !userData?.isGoogleAuthenticatorSetUp ? (
              <SaveIcon />
            ) : (
              <DeleteIcon />
            )
          }
          loading={verify2FaTotpIsLoading || delete2FaTotpIsLoading}
          loadingPosition="end"
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          type="submit"
        >
          {!userData?.isGoogleAuthenticatorSetUp
            ? 'Verify & Activate'
            : 'Delete'}
        </LoadingButton>
      </CardContent>
    </Dialog>
  );
});
