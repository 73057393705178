import React, { FC, Fragment, useMemo } from 'react';
import {
  Box,
  Divider,
  List,
  ListItemAvatar,
  Paper,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { NoItemsWrapper } from '../../shared';
import { Container } from '../../shared/container/container';
import { theme } from '../../assets/theme';
import { useAppSelector } from '../../store';
import { useServerError } from '../../hooks';
import { useGetCryptoCurrencyAssetsQuery } from '../../services';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { formatPrice } from '../../utils';
import { assetSymbolHelper } from '../../helpers/assetSymbolHelper';
import { CurrencyIconComponent } from '../currency_image';
import { useRatesContext } from '../../context/RatesContext';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  width: '100%',
}));

export const LatestMarketPriceList: FC = () => {
  const { selectedCurrency, isCurrencyLoading } = useAppSelector(
    (s) => s.uiReducer
  );

  const { rates, isLoadingRates } = useRatesContext();

  const {
    data: cryptoAssets,
    isFetching: isFetchingCryptoAssets,
    error: errorCryptoAssets,
    isError: isErrorCryptoAssets,
  } = useGetCryptoCurrencyAssetsQuery({});

  useServerError({
    isError: isErrorCryptoAssets,
    error: errorCryptoAssets,
  });

  const assetsList = useMemo(() => {
    if (!cryptoAssets?.assets?.length || isLoadingRates) {
      return [];
    }

    return cryptoAssets?.assets?.map(
      ({ id, icon, name, symbol, assetWalletName }) => ({
        id,
        icon,
        name,
        symbol,
        rate: rates[assetWalletName],
      })
    );
  }, [cryptoAssets?.assets, isLoadingRates, rates]);

  return (
    <Container sx={{ width: '100%', padding: 0 }}>
      <Section
        sx={{
          mt: 4,
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          alignItems: 'start',
          p: { sm: '24px 0', xs: '16px 0' },
        }}
        elevation={4}
      >
        <Typography
          pl={{ sm: '24px', xs: '16px' }}
          pr={{ xs: '16px', sm: 0 }}
          variant="h5"
        >
          Latest Coin Market Price
        </Typography>
        <NoItemsWrapper
          length={assetsList?.length}
          isLoading={
            isLoadingRates ||
            isCurrencyLoading ||
            isFetchingCryptoAssets ||
            !assetsList.length
          }
        >
          <List sx={{ width: '100%' }}>
            {assetsList.map((asset, index) => (
              <Fragment key={asset.id}>
                <ListItem
                  sx={{
                    paddingX: { sm: '24px', xs: '16px' },
                    minHeight: '82px',
                  }}
                >
                  <ListItemText
                    primary={index + 1}
                    sx={{ maxWidth: '16px', width: '100%', marginRight: '8px' }}
                  />
                  <ListItemAvatar
                    sx={{ width: '30px', height: '30px', minWidth: '30px' }}
                  >
                    <CurrencyIconComponent iconString={asset.icon} />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    sx={{
                      paddingLeft: { sm: '16px', xs: '8px' },
                      paddingRight: { sm: '16px', xs: '0' },
                      display: { xs: 'block', sm: 'flex' },
                      alignItems: { sm: 'center' },
                      gap: { sm: '16px' },
                    }}
                    primary={
                      <Typography noWrap sx={{ mb: { xs: '6px', sm: 0 } }}>
                        {asset.name}
                      </Typography>
                    }
                    secondary={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: { xs: 'center', sm: 'flex-start' },
                          justifyContent: 'space-between',
                          flexDirection: { xs: 'row', sm: 'column' },
                        }}
                      >
                        <Typography
                          component="span"
                          fontSize={14}
                          noWrap
                          sx={{ maxWidth: '100%' }}
                        >
                          {asset.symbol}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            textWrap: 'nowrap',
                            color: 'rgba(0, 0, 0, 0.87)',
                            textAlign: 'end',
                            display: { xs: 'block', sm: 'none' },
                          }}
                        >
                          {isLoadingRates ? (
                            <Skeleton variant="text" sx={{ width: '70px' }} />
                          ) : (
                            `${assetSymbolHelper(
                              selectedCurrency.symbol
                            )}${formatPrice(asset.rate as number, 2)}`
                          )}
                        </Typography>
                      </Box>
                    }
                  />
                  <ListItemText
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      justifyContent: { sm: 'flex-end' },
                    }}
                    primary={
                      isLoadingRates || !asset.rate ? (
                        <Skeleton variant="text" sx={{ width: '70px' }} />
                      ) : (
                        <Typography
                          sx={{
                            textWrap: 'nowrap',
                            textAlign: 'end',
                          }}
                        >
                          {`${assetSymbolHelper(
                            selectedCurrency.symbol
                          )}${formatPrice(asset.rate as number, 2)}`}
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              </Fragment>
            ))}
          </List>
        </NoItemsWrapper>
      </Section>
    </Container>
  );
};
