import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingComponent } from 'shared';
import { baseApi, useGetUserQuery } from 'services';
import { useDispatch } from 'react-redux';
import { INIT_B2C2 } from 'hooks/useSocketIO';
import { useServerError } from 'hooks/useServerError';
import { DEF_CURRENCY_KEY } from 'store/wallet/types';

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const storedToken = localStorage.getItem('token');
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useGetUserQuery(undefined, {
    skip: !storedToken,
  });

  localStorage.setItem(INIT_B2C2, JSON.stringify(false));

  useServerError({ isError, error });

  useEffect(() => {
    if (isError) {
      localStorage.removeItem('token');
    }
  }, [isError]);

  // reset all state
  useEffect(() => {
    dispatch(baseApi.util.resetApiState());
    localStorage.removeItem(DEF_CURRENCY_KEY);
  }, []);

  if (user || Boolean(storedToken)) return <Navigate to="/" />;
  if (isLoading) return <LoadingComponent fullscreen={true} />;

  return <>{children}</>;
};
