import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Button, TextField } from '@mui/material';
import {
  Controller,
  FieldArrayWithId,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  IIbanUpdate,
  UserDataFormInterface,
  UserFormInterface,
} from '../../../types/user.profile.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object } from 'yup';
import {
  booleanScheme,
  emailScheme,
  ibanScheme,
  nameScheme,
  phoneNumberScheme,
  stringScheme,
} from '../../../utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { theme } from '../../../assets/theme';
import Paper from '@mui/material/Paper';
import {
  useAddExternalWalletMutation,
  useGetInactiveCountriesQuery,
  useGetUserQuery,
  useIbanAddMutation,
  useIbanDeleteMutation,
  useIbanUpdateMutation,
  useProfileUpdateMutation,
} from '../../../services';
import { useNotification, useServerError } from '../../../hooks';
import SaveIcon from '@mui/icons-material/Save';
import { Navigate } from 'react-router-dom';
import { AvatarComponent } from '../../../components';
import { TwoFaModalComponent } from '../../../shared';
import { INIT_B2C2 } from '../../../hooks/useSocketIO';
import { UserBankAccounts } from './user.bank.accounts';
import { UserExternalWallets } from './user.external.wallets/user.external.wallets';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { setModalState } from '../../../store/ui';
import { useAppDispatch } from '../../../store';

export const emptyIban = {
  account: '',
  Id: undefined,
  isIban: false,
  bankName: '',
};

const VERIFICATION_NOTIFY_TEXT =
  'Your email address has been successfully updated. A verification code has been sent to your new email. Please verify your new email address.';

const MemoTextField = memo(TextField);

export default function UserForm() {
  const [isLogged, setIsLogged] = useState<boolean>(true);

  const { showNotification } = useNotification();

  const dispatch = useAppDispatch();

  const {
    data: userData,
    isFetching: userDataIsFetching,
    error: userDataError,
    isError: userDataIsError,
    refetch: refetchUserData,
  } = useGetUserQuery();

  const {
    data: countries,
    isError: isErrorInactiveCountries,
    error: errorInactiveCountries,
  } = useGetInactiveCountriesQuery(undefined);

  const excludeCountryCodes = useMemo(() => {
    if (!countries?.length) return [];

    return countries.map(({ isoCode }) => isoCode?.toLowerCase());
  }, [countries]);

  const [
    updateInfo,
    {
      isLoading: isLoadingUpdateUser,
      isError: isErrorUpdateUser,
      error: errorUpdateUser,
      isSuccess: isSuccessUpdateUser,
    },
  ] = useProfileUpdateMutation();
  const [updateIban, { isError: isErrorIbanUpdate, error: errorIbanUpdate }] =
    useIbanUpdateMutation();
  const [addIban, { isError: isErrorAddIban, error: errorAddIban }] =
    useIbanAddMutation();
  const [
    addExternalWallet,
    { isError: isAddExternalWalletError, error: addExternalWalletError },
  ] = useAddExternalWalletMutation();

  const [
    deleteIban,
    {
      isError: isErrorDeleteIban,
      error: errorDeleteIban,
      isSuccess: isSuccessIbanDeleted,
    },
  ] = useIbanDeleteMutation();
  const form = useForm<UserDataFormInterface>({
    defaultValues: {
      email: '',
      name: '',
      middleName: '',
      lastName: '',
      phone: '',
      iban: [emptyIban],
      ExternalWallets: [],
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }),
        name: nameScheme({ required: true, min: 3 }),
        lastName: nameScheme({ required: true, min: 3 }),
        middleName: stringScheme().nullable(),
        phone: phoneNumberScheme({ required: true }),
        iban: array(
          object({
            account: stringScheme({ required: true })
              .label('IBAN')
              .when('isIban', (isIban, schema) => {
                if (isIban[0]) {
                  return ibanScheme(schema, { required: true });
                }
                return schema.label('Bank account');
              }),
            bankName: stringScheme({ required: true }).label('Bank name'),
            isIban: booleanScheme({ required: false }).label('Is IBAN'),
          })
        ),
        ExternalWallets: array(
          object({
            address: stringScheme({ required: true, min: 3 }).label('Address'),
          })
        ),
      })
    ),
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    watch,
    setError,
    formState: { errors },
  } = form;

  const { phone } = getValues();

  const { fields, append, remove } = useFieldArray<
    UserDataFormInterface,
    'iban'
  >({
    name: 'iban',
    control,
  });

  const handleProfileUpdate = (data: UserFormInterface, code: string) => {
    const ibanValues = data?.iban?.map((item) => item.account);
    const duplicates: Record<string, number[]> = {};

    ibanValues?.forEach((value, index) => {
      if (!duplicates[value]) {
        duplicates[value] = [index];
      } else {
        duplicates[value].push(index);
      }
    });
    const duplicateIndexes: number[] = Object.values(duplicates)
      .filter((indexes) => indexes.length > 1)
      .map((indexes) => indexes[indexes.length - 1]);

    if (duplicateIndexes.length > 0) {
      duplicateIndexes.forEach((index) => {
        const isIban = data.iban![index].isIban;
        setError(`iban.${index}.account`, {
          type: 'manual',
          message: isIban ? 'IBAN' : 'Bank account' + ' must be unique',
        });
      });
    } else {
      if (data.iban?.length) {
        const newAccounts = data.iban.filter((item) => !item.Id);
        const accountsForUpdate = data.iban.filter(
          (item, index) =>
            item.Id &&
            (userData?.Ibans[index]?.account !== item?.account ||
              userData?.Ibans[index]?.bankName !== item?.bankName ||
              userData?.Ibans[index]?.isIban !== item.isIban)
        );

        if (newAccounts?.length) {
          addIban({
            accounts: newAccounts?.map((el) => {
              return {
                account: el.account,
                bankName: el.bankName,
                isIban: el.isIban,
              };
            }) as IIbanUpdate[],
          });
        }
        if (accountsForUpdate?.length) {
          updateIban({
            accounts: accountsForUpdate?.map((el) => {
              return {
                id: el.Id,
                account: el.account,
                bankName: el.bankName,
                isIban: el.isIban,
              };
            }) as IIbanUpdate[],
          });
        }
      }
      if (data.ExternalWallets?.length) {
        const newWallets = data.ExternalWallets.filter((item) => !item.id);

        if (newWallets?.length) {
          addExternalWallet({
            wallets: newWallets?.map((el) => {
              return {
                address: el.address,
                currencyId: el.currencyId,
              };
            }),
          });
        }
      }
      updateInfo({
        email: data.email,
        name: data.name,
        lastName: data.lastName,
        phone: data.phone,
        middleName: data.middleName,
        ...(code ? { code } : {}),
      });
    }
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    handleSubmit(async (data) => await handleProfileUpdate(data, code))();
  };

  const inputStyle = {
    width: '100%',
    padding: '10px 14px 10px 84px',
    borderColor: !!errors?.phone?.message ? theme.palette.error.main : '',
    '&:focus': {
      borderColor: theme.palette.error.main,
      boxShadow: '0 0 0 1px #CF3B27',
    },
  };

  const logOutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenKyc');
    localStorage.setItem(INIT_B2C2, 'false');
    setIsLogged(false);
  };

  const phoneHandler = (phoneNumber: string, isErrorMessage: boolean) => {
    setValue('phone', `+${phoneNumber}`);
    if (isErrorMessage) {
      trigger('phone');
    }
  };

  const removeIbanHandler = useCallback(
    (
      item: FieldArrayWithId<UserDataFormInterface, 'iban', 'id'>,
      index: number
    ) => {
      if (item.Id) {
        deleteIban({ id: item.Id as number })
          .unwrap()
          .then(() => remove(index))
          .catch(() => null);
      } else {
        remove(index);
      }
    },
    [deleteIban, remove]
  );

  const handleVerifyClick = () => {
    dispatch(setModalState({ name: 'verifyEmail', visible: true }));
  };

  useServerError({ isError: isErrorUpdateUser, error: errorUpdateUser });
  useServerError({
    isError: isAddExternalWalletError,
    error: addExternalWalletError,
  });
  useServerError({ isError: userDataIsError, error: userDataError });
  useServerError({ isError: isErrorIbanUpdate, error: errorIbanUpdate });
  useServerError({ isError: isErrorDeleteIban, error: errorDeleteIban });
  useServerError({ isError: isErrorAddIban, error: errorAddIban });
  useServerError({
    isError: isErrorInactiveCountries,
    error: errorInactiveCountries,
  });

  useEffect(() => {
    if (userData) {
      reset({
        email: userData?.email,
        name: userData?.name,
        lastName: userData?.lastName,
        phone: userData?.phone,
        middleName: userData?.middleName,
        iban: userData?.Ibans.map((item) => {
          return {
            account: item?.account,
            isIban: item?.isIban,
            bankName: item?.bankName,
            Id: item.id,
          };
        }),
        ExternalWallets:
          userData?.ExternalWallets.map((item) => {
            return {
              address: item?.address,
              currencyId: item?.Currency.id,
              id: item.id,
            };
          }) ?? [],
      });
    }
  }, [userData]);

  useEffect(() => {
    if (isSuccessUpdateUser) {
      refetchUserData();
      showNotification('User details was updated!', 'success');
    }
  }, [isSuccessUpdateUser]);

  useEffect(() => {
    if (isSuccessIbanDeleted) {
      showNotification('IBAN was deleted!', 'success');
    }
  }, [isSuccessIbanDeleted]);

  if (!isLogged) return <Navigate to="/auth" />;
  return (
    <FormProvider {...form}>
      <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
        <AvatarComponent
          hideBadge={false}
          avatarBoxSx={{ margin: 'auto', marginBottom: '15px' }}
          height={80}
          width={80}
        />
        <Typography component="h1" variant="h4" align="center" mb={1}>
          {userData?.name + ' ' + userData?.lastName}
        </Typography>

        <Button color="error" onClick={logOutHandler} variant="outlined">
          <span>Logout</span>
        </Button>
        {!userData?.emailVerified && (
          <Box
            mb={1}
            mt={1.5}
            p={1}
            textAlign="left"
            sx={{
              background: theme.palette.warning.main,
              borderRadius: theme.spacing(0.5),
            }}
          >
            <AnnouncementIcon
              fontSize="small"
              sx={{ float: 'left', marginRight: theme.spacing(1) }}
            />
            <Typography variant="body2">{VERIFICATION_NOTIFY_TEXT}</Typography>
            <Box mt={1} display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="small"
                onClick={handleVerifyClick}
              >
                Verify
              </Button>
            </Box>
          </Box>
        )}
        <form>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="left">
                <Typography variant="h6">User profile details</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MemoTextField
                      error={!!error?.message}
                      helperText={error?.message}
                      margin="normal"
                      required
                      value={value}
                      onChange={onChange}
                      fullWidth
                      disabled={userData && !userData.emailVerified}
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="Email"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MemoTextField
                      error={!!error?.message}
                      helperText={error?.message}
                      margin="normal"
                      required
                      fullWidth
                      value={value}
                      onChange={onChange}
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      placeholder="First Name"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="middleName"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MemoTextField
                      error={!!error?.message}
                      helperText={error?.message}
                      margin="normal"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      id="middleName"
                      label="Middle Name"
                      name="middleName"
                      placeholder="Middle Name"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  name="lastName"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MemoTextField
                      error={!!error?.message}
                      helperText={error?.message}
                      margin="normal"
                      required
                      fullWidth
                      value={value}
                      onChange={onChange}
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="Last Name"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Controller
                  control={control}
                  name="phone"
                  render={({ fieldState: { error } }) => (
                    <>
                      <PhoneInput
                        key={`phone-input-${excludeCountryCodes.length}`}
                        disabled
                        country={'us'}
                        excludeCountries={excludeCountryCodes}
                        value={phone}
                        onChange={(phoneNumber) =>
                          phoneHandler(phoneNumber, !!error?.message)
                        }
                        inputClass="phone-input"
                        inputStyle={inputStyle}
                      />
                      {!!error?.message && (
                        <Typography
                          align="left"
                          variant="caption"
                          color={theme.palette.error.main}
                          sx={{ margin: '4px 14px 0 14px', width: '100%' }}
                        >
                          {error?.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} textAlign="left">
                <Typography variant="h6" mt={2}>
                  Bank accounts
                </Typography>
              </Grid>
              <UserBankAccounts
                userData={userData}
                append={append}
                fields={fields}
                trigger={trigger}
                control={control}
                removeIbanHandler={removeIbanHandler}
                watch={watch}
              />
              <Grid item xs={12} textAlign="left">
                <Typography variant="h6">External wallets</Typography>
              </Grid>
              <UserExternalWallets userData={userData} />
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TwoFaModalComponent
              disabled={Object.keys(errors).length !== 0}
              onComplete={confirm2FaHandle}
              endIcon={<SaveIcon />}
              loading={isLoadingUpdateUser || userDataIsFetching}
              loadingPosition="end"
              variant="contained"
              skip={!userData?.isChangeSettings2faEnabled}
              sx={{ mt: 3, ml: 1 }}
            >
              Update
            </TwoFaModalComponent>
          </Box>
        </form>
      </Paper>
    </FormProvider>
  );
}
