import { Paper, Typography, styled, Box, Button } from '@mui/material';
import React, { FC, memo } from 'react';
import { ExchangeModal } from 'components/modals/exchange';
import { Container } from 'shared/container/container';
import { Crypto, Fiat } from 'store/wallet/types';
import { useAppDispatch, useAppSelector } from 'store';
import { setModalState } from 'store/ui';
import { usePricesAndCrypto } from '../../hooks/usePricesAndCrypto';
import { SelectAccountCurrency, VerificationBanner } from '../../components';
import { FiatSection } from './fiat_section';
import { CryptoSection } from './crypto_section';
import { useGetUserQuery } from '../../services';

type B2C2CryptoNames = Exclude<Crypto, 'USDT' | 'USDC'> | 'USC' | 'UST';

type MapCryptoInstruments<T extends Fiat> = {
  [P in B2C2CryptoNames as `${P}${T}`]: T;
};

export type CryptoInstruments = keyof MapCryptoInstruments<Fiat>;

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

export const ExchangePage: FC = memo(() => {
  const { modalsVisibility } = useAppSelector((s) => s.uiReducer);
  const dispatch = useAppDispatch();

  const { setSelectedCrypto, selectedCrypto } = usePricesAndCrypto();

  const { data: userData } = useGetUserQuery();

  const onModalClose = () => {
    dispatch(setModalState({ visible: false, name: 'exchange' }));
    setSelectedCrypto({ symbol: '', nativeAsset: '' });
  };

  const moreAssetsModalHandler = () => {
    dispatch(setModalState({ visible: true, name: 'moreAssets' }));
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          flexDirection: { sm: 'row', xs: 'column' },
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h4" color="inherit">
          Dashboard
        </Typography>
        <Section
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: '20px',
            flexWrap: 'wrap',
            width: { sm: 'auto', xs: '100%' },
            padding: '0',
          }}
        >
          <SelectAccountCurrency title={'Total Assets'} />
        </Section>
      </Box>
      <VerificationBanner />
      <FiatSection />
      <CryptoSection setSelectedCrypto={setSelectedCrypto} />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <Button
          variant="contained"
          disabled={!userData?.kycVerified}
          onClick={moreAssetsModalHandler}
        >
          Add more assets
        </Button>
      </Box>
      {modalsVisibility.exchange && (
        <ExchangeModal
          isOpen={modalsVisibility.exchange}
          symbol={selectedCrypto.symbol as Crypto}
          onClose={onModalClose}
          nativeAsset={selectedCrypto.nativeAsset}
        />
      )}
    </Container>
  );
});
