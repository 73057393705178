import { useCallback, useEffect, useState } from 'react';

const DEFAULT_PAGE_SIZE = 10;

export const useTransactionsHistoryFilter = (data: any[] = []) => {
  const [sorted, setSorted] = useState<any[]>(data || []);
  const [filters, setFilters] = useState<{ [p: string]: string }>({
    type: 'All',
    order: 'desc',
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [paginatedResult, setPaginatedResult] = useState<any[]>([]);

  const paginateData = useCallback(
    (dataArr: any[], page: number) => {
      const startIndex = page * DEFAULT_PAGE_SIZE;

      return dataArr.slice(startIndex, startIndex + DEFAULT_PAGE_SIZE);
    },
    [currentPage]
  );

  const filterDataHandle = useCallback(
    (initData: any[]) => {
      let result = initData;

      if (filters && Object.keys(filters).length) {
        for (let [key, value] of Object.entries(filters)) {
          if (!value) continue;

          if (key === 'type') {
            result =
              value?.toLowerCase() === 'all'
                ? data
                : data?.filter(
                    (v) => v?.type?.toLowerCase() === value?.toLowerCase()
                  );
          }
          if (key === 'order') {
            result = result?.slice()?.sort((first, second) => {
              const date1 = new Date(first?.createdAt).getTime();
              const date2 = new Date(second?.createdAt).getTime();
              if (value === 'asc') return date1 - date2;
              if (value === 'desc') return date2 - date1;
              return 1;
            });
          }
        }
      }

      const paginatedSortedResult = paginateData(result, 0);

      setPaginatedResult(paginatedSortedResult);
      setSorted(() => result || []);
    },
    [data, filters]
  );

  const filterHandle = (type: string, value: string) => {
    setFilters((prev: any) => ({ ...prev, [type]: value }));
    setCurrentPage(0);
    filterDataHandle(sorted);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);

    const res = paginateData(sorted, newPage);
    setPaginatedResult(res);
  };

  useEffect(() => {
    if (data?.length) filterDataHandle(data);
  }, [data, filterDataHandle]);

  return {
    data: paginatedResult,
    onFilter: filterHandle,
    onPageChange: handlePageChange,
    currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    total: data.length,
  };
};
