import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { getErrorMessage, phoneNumberScheme } from 'utils';
import { Box, Link, TextField, Typography } from '@mui/material';
import {
  Link as NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useNotification, useServerError } from 'hooks';
import { AuthVerifyPhoneValues } from 'types/auth.interface';
import {
  useGetInactiveCountriesQuery,
  useVerifyPhoneCodeMutation,
} from '../../services';
import { SendCodeButton } from '../../components/auth/send.code.button';
import { TwoFactorTypesEnum } from '../../types/user.interface';
import { removeTimerSendCodeStorage } from '../../helpers/removeTimerSendCodeStorage';
import { theme } from '../../assets/theme';
import { styled } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import { LoadingButton } from '../../components/buttons_collection/loading.button';

const StyledPhoneInput = styled(PhoneInput)(({ theme: styledTheme }) => ({
  '&.react-tel-input .form-control:focus': {
    borderColor: styledTheme.palette.primary.main,
    boxShadow: `0px 0px 0px 1px ${styledTheme.palette.primary.main}`,
  },
}));

type AuthVerifyPhonePageProps = {};
export const AuthVerifyPhonePage: FC<AuthVerifyPhonePageProps> = () => {
  const [phoneNumberState, setPhoneNumberState] = useState<string>('');
  const [searchParams] = useSearchParams();
  const { showNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const phoneParamsValue: string =
    searchParams.get('phone') || location.state?.phone || '';

  const {
    data: countries,
    isError: isErrorInactiveCountries,
    error: errorInactiveCountries,
  } = useGetInactiveCountriesQuery(undefined);

  const excludeCountryCodes = useMemo(() => {
    if (!countries?.length) return [];

    return countries.map(({ isoCode }) => isoCode?.toLowerCase());
  }, [countries]);

  const [
    verifyPhoneCode,
    {
      isError: isVerifyPhoneCodeError,
      error: VerifyPhoneCodeError,
      isSuccess: isSuccessVerifyPhoneCode,
      isLoading: isLoadingVerifyPhoneCode,
    },
  ] = useVerifyPhoneCodeMutation();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm<AuthVerifyPhoneValues>({
    defaultValues: { phone: phoneParamsValue, code: '' },
    resolver: yupResolver(
      object({
        code: string().required().min(6).label('Code'),
        phone: phoneNumberScheme({ required: true }),
      })
    ),
  });

  const { phone } = getValues();

  const phoneHandler = (phoneNumber: string, isErrorMessage: boolean) => {
    setPhoneNumberState(`+${phoneNumber}`);
    setValue('phone', `+${phoneNumber}`);
    if (isErrorMessage) {
      trigger('phone');
    }
  };

  const inputPhoneStyle = {
    width: '100%',
    padding: '18px 14px 18px 84px',
    borderColor: !!errors?.phone?.message ? theme.palette.error.main : '',
  };

  const onSubmit: SubmitHandler<AuthVerifyPhoneValues> = (data) => {
    verifyPhoneCode({ code: data.code, phone: data.phone })
      .unwrap()
      .then(() => navigate('/'))
      .catch(() => null);
  };

  const isVerifyErrorIncludesInvalid = getErrorMessage(VerifyPhoneCodeError)
    .toLowerCase()
    .includes('invalid');

  useServerError({
    isError: !isVerifyErrorIncludesInvalid && isVerifyPhoneCodeError,
    error: VerifyPhoneCodeError,
  });

  useServerError({
    isError: isErrorInactiveCountries,
    error: errorInactiveCountries,
  });

  useEffect(() => {
    setPhoneNumberState(phoneParamsValue);
    if (isSuccessVerifyPhoneCode) {
      showNotification('User details was updated!', 'success');
    }
  }, [isSuccessVerifyPhoneCode]);
  useEffect(() => {
    if (isVerifyErrorIncludesInvalid && isVerifyPhoneCodeError) {
      setError('code', {
        message: getErrorMessage(VerifyPhoneCodeError),
      });
    }
  }, [isVerifyPhoneCodeError]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography component="h1" variant="h5">
        A verification code has been sent to your phone {phone}
      </Typography>
      <TextField
        {...register('code')}
        error={!!errors?.code?.message}
        helperText={errors?.code?.message}
        margin="normal"
        required
        fullWidth
        id="code"
        label="QCE Code"
        name="code"
        autoComplete="off"
        autoFocus
      />
      {!phoneParamsValue && (
        <Controller
          control={control}
          name="phone"
          render={({ fieldState: { error } }) => (
            <>
              <StyledPhoneInput
                key={`phone-input-${excludeCountryCodes.length}`}
                country={'us'}
                value={phone}
                excludeCountries={excludeCountryCodes}
                onChange={(phoneNumber) =>
                  phoneHandler(phoneNumber, !!error?.message)
                }
                inputClass="phone-input"
                inputStyle={inputPhoneStyle}
              />
              {!!error?.message && (
                <Typography
                  align="left"
                  variant="caption"
                  color={theme.palette.error.main}
                  sx={{
                    margin: '4px 14px 0 14px',
                    width: '100%',
                    display: 'block',
                  }}
                >
                  {error?.message}
                </Typography>
              )}
            </>
          )}
        />
      )}
      <SendCodeButton
        phone={phone || phoneNumberState}
        sendCodeType={TwoFactorTypesEnum.SMS}
      />
      <LoadingButton
        id="authSubmitButton"
        type="submit"
        isLoading={isLoadingVerifyPhoneCode}
        sx={{ mt: 3, mb: 2 }}
      >
        Submit
      </LoadingButton>
      <Link
        onClick={removeTimerSendCodeStorage}
        variant="body2"
        component={NavLink}
        to="/auth"
      >
        Cancel
      </Link>
    </Box>
  );
};
