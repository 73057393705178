// created by Artem
import { FC, useState, PropsWithChildren, MouseEvent, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useGetUserQuery } from 'services';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'utils';
import { SendCodeButton } from '../../../components/auth/send.code.button';
import { TwoFactorTypesEnum } from '../../../types/user.interface';
import { useSendVerificationCode } from '../../../hooks/useSendVerificationCode';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

type TwoFaModalComponentProps = {
  onComplete: (status: boolean, code: string) => void;
  skip?: boolean;
} & LoadingButtonProps;

export const TwoFaModalComponent: FC<
  PropsWithChildren<TwoFaModalComponentProps>
> = ({ children, onClick, skip = false, onComplete, ...props }) => {
  const [open, setOpen] = useState(false);
  const { data: user } = useGetUserQuery();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ email: string; code: string }>({
    defaultValues: { code: '' },
    resolver: yupResolver(
      object({ code: stringScheme({ required: true, min: 6 }) })
    ),
  });

  const { sendEmailCode } = useSendVerificationCode();

  const openHandle = (e: MouseEvent<any>) => {
    onClick?.(e);
    setOpen(true);
    sendEmailCode({ email: user?.email as string });
  };

  const closeHandle = () => {
    setOpen(false);
    reset({ code: '' });
  };

  const submitHandle: SubmitHandler<{ email: string; code: string }> = async ({
    code,
  }) => {
    if (!user?.id) return;
    onComplete?.(true, code);
    closeHandle();
  };

  const partialEmail = useMemo(() => {
    if (user?.email) {
      const parts = user.email.split('@');
      const part1 = parts[0].substring(
        0,
        parts[0].length - parts[0].length / 2
      );
      const part2 = parts?.[1] || '';

      return part1 + '...@' + part2;
    }
    return 'email';
  }, [user?.email]);

  return (
    <>
      <LoadingButton
        {...props}
        onClick={skip ? () => onComplete(true, '') : openHandle}
      >
        {children}
      </LoadingButton>

      <Dialog open={open} onClose={closeHandle} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm action</DialogTitle>
        <DialogContent>
          <TextField
            {...register('code')}
            error={!!errors?.code?.message}
            helperText={errors?.code?.message}
            margin="normal"
            required
            fullWidth
            id="code"
            label="Code"
            name="code"
            autoComplete="off"
            autoFocus
          />
          <Typography paragraph variant="caption">
            An email with a confirmation code has been sent to{' '}
            <b>{partialEmail}</b>.
          </Typography>
          <SendCodeButton
            withDescription={false}
            sendCodeType={TwoFactorTypesEnum.EMAIL}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandle}>Cancel</Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit(submitHandle)}
            id="confirmConvertCryptoButton"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
