// created by Artem
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, Grid, Link, styled, TextField, Typography } from '@mui/material';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AccountFormValues } from 'types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { emailScheme, getErrorMessage, stringScheme } from 'utils';
import { PATH_REGISTER } from '../../constants/spa-routes';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Circle } from '../../assets/icons/circle';
import { FillCircle } from '../../assets/icons/fill_circle';
import { PersonIcon } from '../../assets/icons/person_icon';
import { CorporateIcon } from '../../assets/icons/corporate_icon';
import { LoadingButton } from '@mui/lab';
import { CountrySelect } from '../../components';
import {
  useCheckEmailMutation,
  useCheckRegistrationNumberMutation,
} from '../../services';
import { useSendVerificationCode } from '../../hooks/useSendVerificationCode';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

type LoginPageProps = {};

const StyledCard = styled(Card)(() => ({
  width: '100%',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
  boxShadow: 'none',
}));

export const AccountPage: FC<LoginPageProps> = () => {
  const [selectedVariant, setSelectedVariant] = useState<number | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  const [lowercaseEmail, setLowercaseEmail] = useState('');

  const { sendEmailFirstStepRegisterCode } = useSendVerificationCode();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    formState: { errors },
  } = useForm<AccountFormValues>({
    defaultValues: {
      Email: '',
      Country: '',
      'Company name': '',
      'Registration number': '',
    },
    resolver: yupResolver(
      object({
        Email: emailScheme({ required: selectedVariant === 1 }),
        Country: stringScheme({ required: true }),
        'Company name': stringScheme({ required: selectedVariant === 2 }),
        'Registration number': stringScheme({
          required: selectedVariant === 2,
        }),
      })
    ),
  });

  const {
    Email,
    ['Company name']: companyName,
    ['Registration number']: registrationNumber,
  } = getValues();

  const [
    checkEmail,
    {
      isError: isErrorCheckEmail,
      error: errorCheckEmail,
      isSuccess: isSuccessCheckEmail,
      isLoading: isLoadingCheckEmail,
    },
  ] = useCheckEmailMutation();

  const [
    checkRegistrationNumber,
    {
      isError: isErrorCheckRegistrationNumber,
      error: errorCheckRegistrationNumber,
      isSuccess: isSuccessCheckRegistrationNumber,
      isLoading: isLoadingCheckRegistrationNumber,
    },
  ] = useCheckRegistrationNumberMutation();

  useEffect(() => {
    if (errorCheckEmail) {
      setError('Email', { message: getErrorMessage(errorCheckEmail) });
    }
    if (errorCheckRegistrationNumber) {
      setError('Registration number', {
        message: getErrorMessage(errorCheckRegistrationNumber),
      });
    }
  }, [isErrorCheckEmail, isErrorCheckRegistrationNumber]);

  const onSubmit: SubmitHandler<AccountFormValues> = (data) => {
    checkEmail({ email: data.Email });
    if (selectedVariant === 2) {
      checkRegistrationNumber({
        registrationNumber: data['Registration number'],
      });
    }
  };

  const changeEmailHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setLowercaseEmail(e.target.value.toLowerCase());

  const handleChangeSelectedVariant = (variant: number) => {
    setSelectedVariant(variant);
  };

  useEffect(() => {
    if (isSuccessCheckEmail) {
      if (selectedVariant === 1) {
        sendEmailFirstStepRegisterCode({ email: Email });
        navigate(PATH_REGISTER.REGISTER, {
          state: {
            email: Email,
            selectedCountry: selectedCountry,
            verifyType: 'register',
            accountVariant: selectedVariant,
          },
        });
      } else if (isSuccessCheckRegistrationNumber && isSuccessCheckEmail) {
        sendEmailFirstStepRegisterCode({ email: Email });
        navigate(PATH_REGISTER.REGISTER, {
          state: {
            email: Email,
            selectedCountry: selectedCountry,
            verifyType: 'register',
            companyName: companyName,
            registrationNumber: registrationNumber,
            accountVariant: selectedVariant,
          },
        });
      }
    }
  }, [isSuccessCheckEmail, isSuccessCheckRegistrationNumber]);

  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '600px',
        width: '100%',
      }}
    >
      <Typography
        id="becomeClientTitle"
        component="h1"
        variant="h5"
        textAlign="left"
        width="100%"
      >
        Become a client
      </Typography>
      <Box sx={{ mt: 1, width: '100%' }} component="form">
        <Box
          sx={{
            display: 'flex',
            gap: '11px',
            width: '100%',
            flexWrap: { sm: 'nowrap', xs: 'wrap' },
          }}
        >
          <StyledCard sx={{ maxWidth: { sm: 294, xs: '100%' } }}>
            <CardActionArea
              id="privateCardAction"
              sx={{ height: '100%' }}
              onClick={() => handleChangeSelectedVariant(1)}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      height: '100%',
                    }}
                  >
                    <Checkbox
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      checked={selectedVariant === 1}
                      {...label}
                      icon={<Circle />}
                      checkedIcon={<FillCircle />}
                    />
                    <Box>
                      <Typography variant="h6" mb={0} textAlign="left">
                        Private
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Natural person
                      </Typography>
                    </Box>
                  </Box>
                  <PersonIcon />
                </Box>
              </CardContent>
            </CardActionArea>
          </StyledCard>
          <StyledCard sx={{ maxWidth: { sm: 294, xs: '100%' } }}>
            <CardActionArea
              id="corporateCardAction"
              sx={{ height: '100%' }}
              onClick={() => handleChangeSelectedVariant(2)}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      height: '100%',
                    }}
                  >
                    <Checkbox
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      checked={selectedVariant === 2}
                      {...label}
                      icon={<Circle />}
                      checkedIcon={<FillCircle />}
                    />
                    <Box>
                      <Typography variant="h6" mb={0} textAlign="left">
                        Corporate
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Legal entity
                      </Typography>
                    </Box>
                  </Box>
                  <CorporateIcon />
                </Box>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Box>
        {selectedVariant === 1 && (
          <Box sx={{ marginTop: { md: '72px', sm: '40px', xs: '30px' } }}>
            <TextField
              {...register('Email')}
              error={!!errors?.Email?.message}
              helperText={errors?.Email?.message}
              margin="normal"
              sx={{ marginTop: 0 }}
              required
              fullWidth
              id="Email"
              label="Email"
              name="Email"
              autoComplete="Email"
              value={lowercaseEmail}
              onChange={changeEmailHandler}
              autoFocus
            />
          </Box>
        )}
        {selectedVariant === 2 && (
          <Box sx={{ marginTop: { md: '72px', sm: '40px', xs: '30px' } }}>
            <TextField
              {...register('Company name')}
              error={!!errors?.['Company name']?.message}
              helperText={errors?.['Company name']?.message}
              margin="normal"
              sx={{ marginTop: 0 }}
              required
              fullWidth
              autoFocus
              id="Company name"
              label="Company name"
              name="Company name"
              autoComplete="Company name"
            />
          </Box>
        )}
        {selectedVariant && (
          <CountrySelect
            errors={errors}
            register={register}
            setSelectedCountry={setSelectedCountry}
            selectedCountry={selectedCountry}
            setValue={setValue}
          />
        )}
        {selectedVariant === 2 && (
          <Box>
            <TextField
              {...register('Registration number')}
              sx={{ marginTop: '28px', mb: '28px' }}
              error={!!errors?.['Registration number']?.message}
              helperText={errors?.['Registration number']?.message}
              margin="normal"
              required
              fullWidth
              id="Registration number"
              label="Registration number"
              name="Registration number"
              autoComplete="Registration number"
            />
            <TextField
              {...register('Email')}
              error={!!errors?.Email?.message}
              helperText={errors?.Email?.message}
              margin="normal"
              sx={{ marginTop: 0 }}
              required
              fullWidth
              id="Email"
              label="Email"
              name="Email"
              autoComplete="Email"
              value={lowercaseEmail}
              onChange={changeEmailHandler}
              autoFocus
            />
          </Box>
        )}
        <LoadingButton
          sx={{ mt: '32px', mb: 2 }}
          disabled={!selectedVariant}
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoadingCheckEmail || isLoadingCheckRegistrationNumber}
          onClick={handleSubmit(onSubmit)}
          id="accountGetStartedButton"
        >
          Get started
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link variant="body2" component={NavLink} to="/auth">
              Already has account
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
