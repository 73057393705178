import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { baseApi } from 'services';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { unauthorizedMiddleware } from './middleware/unauthorized-middleware';
import uiReducer from './ui/slice';
import accountReducer from './account/slice';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  uiReducer,
  accountReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([unauthorizedMiddleware, baseApi.middleware]),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
