import { Paper, Typography, styled, Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../assets/theme';
import { AccountConfirmationBtn } from '../../components';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`;

export const LikeToDo: FC = () => {
  return (
    <Section
      elevation={4}
      sx={{
        mt: 4,
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        justifyContent: 'space-between',
        padding: { sm: '24px', xs: '16px' },
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '15px',
          justifyContent: 'left',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
            height: '100%',
          }}
        >
          <Typography my={1} variant="h5">
            What would you like to do?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '12px',
            flexWrap: 'wrap',
          }}
        >
          <Button variant={'contained'} component={NavLink} to={'dashboard'}>
            Dashboard
          </Button>
          <Button
            variant={'contained'}
            component={NavLink}
            to={'transaction-history'}
          >
            Transaction history
          </Button>
          <Button variant={'contained'} component={NavLink} to={'user-profile'}>
            Profile
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <AccountConfirmationBtn onlyBtn variant={'contained'} />
      </Box>
    </Section>
  );
};
