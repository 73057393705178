import { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  useGetPdfAccountConfirmationQuery,
  useGetUserQuery,
  useRequestToPdfAccountConfirmationMutation,
} from '../../services';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNotification, useServerError } from '../../hooks';
import { PdfBadgeWrapper } from '../../shared';

interface AccountConfirmationProps {
  onlyBtn?: boolean;
  variant?: 'outlined' | 'text' | 'contained' | undefined;
}

export const AccountConfirmationBtn: FC<AccountConfirmationProps> = ({
  onlyBtn = false,
  variant = 'outlined',
}) => {
  const [getPdf, setGetPdf] = useState<boolean>(false);

  const { showNotification } = useNotification();

  const { data: userData } = useGetUserQuery();

  const {
    error: errorGetPdf,
    isError: isErrorGetPdf,
    isFetching: isLoadingGetPdf,
    isSuccess: isSuccessGetPdf,
  } = useGetPdfAccountConfirmationQuery(
    { userName: `${userData?.name}-${userData?.lastName}` },
    {
      skip: !getPdf,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    requestPdfConfirmation,
    {
      isError: isErrorPdf,
      error: errorPdf,
      isLoading: isLoadingPdf,
      isSuccess: isSuccessPdf,
    },
  ] = useRequestToPdfAccountConfirmationMutation();

  useServerError({ isError: isErrorPdf, error: errorPdf });
  useServerError({ isError: isErrorGetPdf, error: errorGetPdf });

  const requestPdgConfirmationHandler = () => requestPdfConfirmation({});

  const getPdgConfirmationHandler = () => {
    setGetPdf(true);
  };

  const isDisabledPdfButton =
    userData?.RequestedPdf && !userData?.RequestedPdf?.isApproved;

  useEffect(() => {
    if (isSuccessPdf) {
      showNotification('Request was send!', 'success');
    }
    if (isSuccessGetPdf) {
      setGetPdf(false);
    }
  }, [isSuccessPdf, isSuccessGetPdf]);

  return onlyBtn ? (
    <PdfBadgeWrapper
      isInvisible={userData?.kycVerified ? !userData?.RequestedPdf : false}
      warningText={!userData?.kycVerified && 'You must verify your KYC'}
    >
      <LoadingButton
        loading={isLoadingGetPdf || isLoadingPdf}
        variant={variant}
        disabled={isDisabledPdfButton || !userData?.kycVerified}
        onClick={
          !userData?.RequestedPdf
            ? requestPdgConfirmationHandler
            : getPdgConfirmationHandler
        }
      >
        {!userData?.RequestedPdf
          ? 'Request PDF account confirmation'
          : 'PDF account confirmation'}
      </LoadingButton>
    </PdfBadgeWrapper>
  ) : (
    <Box sx={{ mt: '30px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        mb={1}
      >
        <Typography variant="h6" mr={1}>
          PDF Account confirmation
        </Typography>
      </Box>
      <PdfBadgeWrapper
        isInvisible={userData?.kycVerified ? !userData?.RequestedPdf : false}
        warningText={!userData?.kycVerified && 'You must verify your KYC'}
      >
        <LoadingButton
          loading={isLoadingGetPdf || isLoadingPdf}
          variant={variant}
          disabled={isDisabledPdfButton || !userData?.kycVerified}
          onClick={
            !userData?.RequestedPdf
              ? requestPdgConfirmationHandler
              : getPdgConfirmationHandler
          }
        >
          {!userData?.RequestedPdf ? 'Request PDF' : 'Download PDF'}
        </LoadingButton>
      </PdfBadgeWrapper>
    </Box>
  );
};
