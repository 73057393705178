import { Divider, Paper, Stack, Typography, styled, Box } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { useCheckKYC, useNotification } from 'hooks';
import { setModalState } from 'store/ui';
import { useGetUserQuery } from 'services';
import { FiatOrderBlock } from 'components/fiat_order_block/fiat_order_block';
import { FiatCurrency } from '../../shared/currencies/currencies';
import { getFilteredCurrencyList } from 'shared/currencies/currencies-list';
import { useRatesContext } from '../../context/RatesContext';

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

export const FiatSection: FC = memo(() => {
  const { check: checkKYC } = useCheckKYC();
  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserQuery();
  const { showNotification } = useNotification();

  const { rates } = useRatesContext();

  const [currencies, setCurrencies] = useState<FiatCurrency[]>([]);

  useEffect(() => {
    const filteredCurrencies = getFilteredCurrencyList(userData);
    setCurrencies(filteredCurrencies);
  }, [userData]);

  const onSendFiat = (symbol: string) => {
    if (!checkKYC()) return;

    if (userData?.Ibans?.length === 0) {
      showNotification('Please add IBAN address in user profile', 'warning');
      return;
    }

    dispatch(
      setModalState({ visible: true, name: 'sendFiat', symbol: symbol })
    );
  };

  const onReceiveFiat = (symbol: string) => {
    if (!checkKYC()) return;

    if (userData?.Ibans?.length === 0) {
      showNotification('Please add IBAN address in user profile', 'warning');
      return;
    }

    dispatch(
      setModalState({ visible: true, name: 'receiveFiat', symbol: symbol })
    );
  };

  const onConvertFiat = (symbol: string) => {
    if (!checkKYC()) return;

    if (userData?.Ibans?.length === 0) {
      showNotification('Please add IBAN address in user profile', 'warning');
      return;
    }

    dispatch(
      setModalState({ visible: true, name: 'convertFiat', symbol: symbol })
    );
  };

  return (
    <Section sx={{ mt: 4, padding: { sm: '24px', xs: '16px' } }} elevation={4}>
      <Typography variant="h5" color="inherit" noWrap align="left">
        Fiat Assets
      </Typography>
      <Stack spacing={1} width="100%">
        {currencies.map((ec, idx) => {
          const key = ec.symbol as keyof typeof rates;

          const rateDetails = rates[key];

          return (
            <Box key={idx}>
              <FiatOrderBlock
                onSend={onSendFiat}
                onReceiveFiat={onReceiveFiat}
                onConvertFiat={onConvertFiat}
                coinOptions={{
                  ...ec,
                  rateDetails,
                }}
              />
              {idx !== currencies.length - 1 ? <Divider /> : null}
            </Box>
          );
        })}
      </Stack>
    </Section>
  );
});
