import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { WalletForm } from '../..';

type WalletModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedSymbol?: string;
};

export const WalletDepositModal: FC<WalletModalProps> = ({
  isOpen,
  onClose,
  selectedSymbol = '',
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        width: '100%',
        margin: 'auto',
        '.MuiPaper-root': { width: '100%' },
      }}
    >
      <DialogTitle>Deposit Wallet</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <WalletForm selectedSymbol={selectedSymbol} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
