import { FC } from 'react';
import { Button, SxProps, Theme } from '@mui/material';

import { Loader } from '../../shared';

type LoadingButtonProps = {
  isLoading: boolean;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'text' | 'contained' | 'outlined';
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  onClick?: () => void;
};
export const LoadingButton: FC<LoadingButtonProps> = ({
  isLoading,
  type = 'button',
  variant = 'contained',
  children,
  sx,
  fullWidth = true,
  onClick,
  id,
}) => {
  return (
    <Button
      id={id}
      disabled={isLoading}
      endIcon={isLoading && <Loader size={15} />}
      type={type}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      sx={sx}
    >
      {children}
    </Button>
  );
};
