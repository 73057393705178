import { useAppDispatch } from '../store';
import { useSocketIO } from './useSocketIO';
import { useEffect } from 'react';
import { setBalance, setBalanceLoading } from '../store/account';
import { transformBalance } from '../utils/balance';
import { Fiat } from '../store/wallet/types';

const API_SOCKET_URL = process.env.REACT_APP_API_URL || '';

export const useWSBalance = () => {
  const dispatch = useAppDispatch();

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const { socket } = useSocketIO({
    url: API_SOCKET_URL,
    options: {
      transports: ['websocket'],
      extraHeaders: {
        'Access-Control-Allow-Origin': '*',
      },
    },
  });

  useEffect(() => {
    if (!socket?.connected) {
      socket?.connect();

      socket?.on('send-user-balance', (balanceRes: any) => {
        dispatch(setBalanceLoading(false));

        const transformedBalance = transformBalance(balanceRes);

        dispatch(setBalance(transformedBalance));
      });
    }

    return () => {
      socket?.off('send-user-balance');

      socket?.disconnect();
    };
  }, [socket]);

  const sendGetBalanceMessage = (symbol: Fiat, changeStatus?: boolean) => {
    if (changeStatus) {
      dispatch(setBalanceLoading(true));
    }

    socket?.emit('get-user-balance', {
      fiat: symbol,
    });
  };

  return {
    isSocketInitialized: !!socket,
    sendGetBalanceMessage,
  };
};
