import { Typography, Box, SxProps, Theme, Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { useAppSelector } from 'store';
import { formatPrice } from '../../utils';
import { CurrenciesSelector } from '../../shared/currencies/currencies.selector';

interface SelectAccountCurrencyProps {
  title?: string;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
}
export const SelectAccountCurrency: FC<SelectAccountCurrencyProps> = ({
  title = '',
  sx,
  titleSx,
}) => {
  const { balance, isLoadingBalance } = useAppSelector(
    (state) => state.accountReducer
  );

  const total = balance?.total || 0;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: '8px',
        flexWrap: 'wrap',
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontSize: { sm: '1.3em', xs: '1.2em' },
          ...titleSx,
        }}
        variant="h5"
        color="inherit"
        align="left"
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: { sm: 'auto', xs: '100%' },
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          variant="h5"
          color="inherit"
          noWrap
          align="left"
          sx={{
            fontSize: { sm: '1.3em', xs: '1.2em' },
          }}
        >
          {!total && isLoadingBalance ? (
            <Skeleton variant="text" sx={{ width: '70px' }} />
          ) : (
            formatPrice(total, 2)
          )}
        </Typography>
        <CurrenciesSelector />
      </Box>
    </Box>
  );
};
