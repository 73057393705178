import React, { FC, memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  FormHelperText,
  Skeleton,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetFiatLimitQuery, useReceiveFiatMutation } from 'services';
import { Fiat } from 'store/wallet/types';
import { assetSymbolHelper } from '../../../helpers/assetSymbolHelper';
import { LoadingButton } from '@mui/lab';
import { useServerError } from '../../../hooks';

type SendFiatModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedSymbol: string;
};

type ReceiveFiatFormData = {
  amount: number;
  iban: string;
  transactionId: string;
};

export const ReceiveFiatModal: FC<SendFiatModalProps> = memo(
  ({ isOpen, onClose, selectedSymbol = 'USD' }) => {
    const [goNext, setGoNext] = useState<boolean>(false);
    // const [isCopied, setIsCopied] = useState<boolean>(false);

    const { data: fiatLimitData, isFetching: isFetchingFiatLimit } =
      useGetFiatLimitQuery({ fiat: selectedSymbol });

    const [
      receiveFiat,
      {
        isError: isErrorReceiveFiat,
        error: errorReceiveFiat,
        isLoading: isLoadingReceiveFiat,
        isSuccess: isSuccessReceiveFiat,
        data: dataReceiveFiat,
      },
    ] = useReceiveFiatMutation();

    useServerError({ isError: isErrorReceiveFiat, error: errorReceiveFiat });

    const validationSchema = yup.object().shape({
      amount: yup
        .number()
        .typeError('Amount must be a number')
        .transform((value) => (isNaN(value) ? undefined : value))
        .default(undefined)
        .positive('Amount must be a positive number')
        .required('Amount is required')
        .max(
          fiatLimitData?.limit ? (fiatLimitData?.limit as number) : Infinity,
          `Do not send more money than ${fiatLimitData?.limit} ${
            selectedSymbol as Fiat
          }.`
        ),
      iban: yup.string(),
      transactionId: yup.string(),
    });

    const {
      register,
      watch,
      control,
      setValue,
      formState: { errors },
    } = useForm<ReceiveFiatFormData>({
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
    });

    const amountInput = watch('amount');

    const changeStepHandler = () => {
      setGoNext((prevState) => !prevState);
    };

    const receiveFiatHandler = () => {
      receiveFiat({ amount: +amountInput, fiat: selectedSymbol });
    };

    const onSubmit = () => {
      onClose();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === '-' || event.key === '+') {
        event.preventDefault();
      }
    };

    useEffect(() => {
      if (isSuccessReceiveFiat) {
        changeStepHandler();
      }
      if (dataReceiveFiat) {
        setValue('iban', dataReceiveFiat.systemBankAccount);
        setValue('transactionId', dataReceiveFiat.transactionId);
      }
    }, [isSuccessReceiveFiat, dataReceiveFiat]);

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitle>Fund Account</DialogTitle>
        <DialogContent>
          {fiatLimitData?.limit && (
            <DialogContentText
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              Max amount:{' '}
              {isFetchingFiatLimit ? (
                <Skeleton variant="text" sx={{ width: '60px' }} />
              ) : (
                fiatLimitData?.limit + '' + assetSymbolHelper(selectedSymbol)
              )}
            </DialogContentText>
          )}
          <form>
            {!goNext ? (
              <TextField
                fullWidth
                disabled={isFetchingFiatLimit}
                margin="normal"
                label="Amount"
                type="number"
                {...register('amount', { required: 'Amount is required' })}
                onKeyDown={handleKeyDown}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                error={!!errors.amount}
                helperText={errors.amount?.message}
              />
            ) : (
              <>
                <Controller
                  control={control}
                  name="iban"
                  render={({ field: { value }, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      value={value}
                      margin="normal"
                      label="IBAN number"
                      type="text"
                      error={!!error?.message}
                      helperText={error?.message}
                      // onMouseLeave={handleCopyLeaveMouse}
                      InputProps={{
                        readOnly: true,
                        // endAdornment: (
                        //   <Tooltip
                        //     title={isCopied ? 'Copied!' : 'Copy'}
                        //     placement="top"
                        //   >
                        //     <IconButton
                        //       onClick={() => handleCopyToClipboard(value)}
                        //       aria-label="Copy to clipboard"
                        //     >
                        //       <FileCopyOutlinedIcon />
                        //     </IconButton>
                        //   </Tooltip>
                        // ),
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="transactionId"
                  render={({ field: { value }, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      value={value}
                      margin="normal"
                      label="Transaction ID"
                      type="text"
                      error={!!error?.message}
                      helperText={error?.message}
                      // onMouseLeave={handleCopyLeaveMouse}
                      InputProps={{
                        readOnly: true,
                        // endAdornment: (
                        //   <Tooltip
                        //     title={isCopied ? 'Copied!' : 'Copy'}
                        //     placement="top"
                        //   >
                        //     <IconButton
                        //       onClick={() => handleCopyToClipboard(value)}
                        //       aria-label="Copy to clipboard"
                        //     >
                        //       <FileCopyOutlinedIcon />
                        //     </IconButton>
                        //   </Tooltip>
                        // ),
                      }}
                    />
                  )}
                />
              </>
            )}
            <FormHelperText sx={{ fontSize: '0.875rem' }} error>
              Warning! Do not send money from unknown sources
            </FormHelperText>
            <DialogActions sx={{ mt: '40px' }}>
              {!goNext ? (
                <>
                  <Button onClick={onClose}>Cancel</Button>
                  <LoadingButton
                    loading={isLoadingReceiveFiat}
                    onClick={receiveFiatHandler}
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={!!errors.amount || !amountInput}
                  >
                    Next
                  </LoadingButton>
                </>
              ) : (
                <>
                  <Button
                    onClick={onSubmit}
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={!!errors.iban || !!errors.transactionId}
                  >
                    Ok
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
);
