import React, { useState, FC } from 'react';
import {
  IconButton,
  Menu,
  List,
  Button,
  MenuItem,
  Grid,
  Typography,
  ListItem,
  Divider,
} from '@mui/material';
import { Wallet } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Crypto } from 'store/wallet/types';
import { useServerError } from 'hooks';
import { formatPrice } from 'utils';
import { Navigate } from 'react-router-dom';
import { AvatarComponent } from '../avatar_component/avatar.component';
import { INIT_B2C2 } from '../../hooks/useSocketIO';
import { useGetCryptoCurrencyAssetsQuery } from '../../services';
import { CurrencyIconComponent } from '../currency_image';
import { NoItemsWrapper } from '../../shared';
import { useAppSelector } from 'store';
import { numberFormatHelper } from '../../helpers/numberFormatHelper';

export const AccountMenu: FC = () => {
  const { balance, isLoadingBalance } = useAppSelector(
    (state) => state.accountReducer
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLogged, setIsLogged] = useState<boolean>(true);

  const {
    data: cryptoCurrency,
    isFetching: cryptoCurrencyIsFetching,
    error: cryptoCurrencyError,
    isError: cryptoCurrencyIsError,
  } = useGetCryptoCurrencyAssetsQuery({}, { skip: !anchorEl });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useServerError({
    isError: cryptoCurrencyIsError,
    error: cryptoCurrencyError,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenKyc');
    localStorage.setItem(INIT_B2C2, 'false');
    setIsLogged(false);
  };

  if (!isLogged) return <Navigate to="/auth" />;

  return (
    <Box>
      <IconButton
        aria-controls="user-account-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: 'background.default',
        }}
      >
        <AvatarComponent
          disableUpload
          height={35}
          width={35}
          textVariant="h6"
        />
      </IconButton>
      <Menu
        id="user-account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Wallet />
            <Typography ml={1}>Fiat</Typography>
          </Box>
          <List sx={{ padding: 0 }}>
            <NoItemsWrapper
              length={Object.entries(balance?.withdraw || {}).length as number}
              isLoading={
                !Object.entries(balance?.withdraw || {}).length &&
                isLoadingBalance
              }
              text="Fiat assets are empty"
            >
              {(Object.entries(balance?.withdraw || {}) || [])?.map(
                ([key, value]) => {
                  return (
                    <ListItem
                      sx={{
                        padding: 0,
                        display: 'flex',
                        mb: 1,
                        flexBasis: '50%',
                      }}
                      key={key}
                    >
                      {key}: {formatPrice(value.total as unknown as string, 2)}
                    </ListItem>
                  );
                }
              )}
            </NoItemsWrapper>
          </List>
        </MenuItem>
        <Divider />
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Wallet />
            <Typography ml={1} mb={1}>
              Crypto
            </Typography>
          </Box>
          <List sx={{ padding: 0, width: '100%' }}>
            <NoItemsWrapper
              length={cryptoCurrency?.assets?.length as number}
              isLoading={
                !cryptoCurrency?.assets?.length && cryptoCurrencyIsFetching
              }
              text="There are no assets"
            >
              {cryptoCurrency?.assets
                .filter((el) => el.isActive)
                ?.map((ec, index) => {
                  const price =
                    balance?.deposit?.[ec.symbol as Crypto]?.total || 0;
                  return (
                    <ListItem
                      sx={{ padding: 0, display: 'flex', mb: 1 }}
                      key={ec.id + index}
                    >
                      <Box width={30} height={30} mr={1}>
                        <CurrencyIconComponent iconString={ec.icon as string} />
                      </Box>
                      {ec.symbol}:&nbsp;{numberFormatHelper(price)}
                    </ListItem>
                  );
                })}
            </NoItemsWrapper>
          </List>
        </MenuItem>
        <Divider />
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: 'default', '&:hover': { background: 'none' } }}
        >
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item flexBasis={1}>
              <Button variant="contained" color="error" onClick={logOutHandler}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </Box>
  );
};
