import {
  useSendEmailCodeMutation,
  useSendEmailFirstStepCodeMutation,
  useSendPhoneCodeMutation,
} from '../services';
import { useServerError } from './useServerError';

interface IUseSendVerificationCode {
  sendEmailCode: ({ email }: { email: string }) => void;
  sendEmailFirstStepRegisterCode: ({ email }: { email: string }) => void;
  sendPhoneCode: ({ phone }: { phone: string }) => void;
  isSuccessSending: boolean;
  isLoadingSending: boolean;
}

export const useSendVerificationCode = (): IUseSendVerificationCode => {
  const [
    sendEmailFirstStepRegisterCode,
    {
      isError: isSendEmailFirstStepRegisterCodeError,
      error: SendEmailFirstStepRegisterCodeError,
      isSuccess: isSuccessSendEmailFirstStepRegisterCode,
      isLoading: isLoadingSendEmailFirstStepRegisterCode,
    },
  ] = useSendEmailFirstStepCodeMutation();

  const [
    sendEmailCode,
    {
      isError: isSendEmailCodeError,
      error: SendEmailCodeError,
      isSuccess: isSuccessSendEmailCode,
      isLoading: isLoadingSendEmailCode,
    },
  ] = useSendEmailCodeMutation();

  const [
    sendPhoneCode,
    {
      isError: isSendPhoneCodeError,
      error: SendPhoneCodeError,
      isSuccess: isSuccessSendPhoneCode,
      isLoading: isLoadingSendPhoneCode,
    },
  ] = useSendPhoneCodeMutation();

  const isSuccessSending =
    isSuccessSendEmailCode ||
    isSuccessSendPhoneCode ||
    isSuccessSendEmailFirstStepRegisterCode;
  const isLoadingSending =
    isLoadingSendEmailCode ||
    isLoadingSendPhoneCode ||
    isLoadingSendEmailFirstStepRegisterCode;

  useServerError({ isError: isSendEmailCodeError, error: SendEmailCodeError });
  useServerError({ isError: isSendPhoneCodeError, error: SendPhoneCodeError });
  useServerError({
    isError: isSendEmailFirstStepRegisterCodeError,
    error: SendEmailFirstStepRegisterCodeError,
  });

  return {
    sendEmailCode,
    sendEmailFirstStepRegisterCode,
    sendPhoneCode,
    isSuccessSending,
    isLoadingSending,
  };
};
