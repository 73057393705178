import { useEffect, useRef, useState } from 'react';

type UseCountdownProps = {
  timeFrom: number;
  timerOffCondition: boolean;
  timerOnCondition: boolean;
  defaultTime?: number;
  finishCallback: () => void;
  skip?: boolean;
};

const DELAY = 1000;

export const useCountdown = ({
  timeFrom,
  timerOffCondition,
  timerOnCondition,
  defaultTime = 0,
  finishCallback,
  skip,
}: UseCountdownProps) => {
  const [refreshTime, setRefreshTime] = useState<number>(defaultTime);
  const [activeTimer, setActiveTimer] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  if (skip) {
    return {
      refreshTime: null,
      activeTimer: false,
    };
  }

  useEffect(() => {
    if (refreshTime === 0 && finishCallback && activeTimer) {
      finishCallback();
    }
  }, [refreshTime, finishCallback, activeTimer]);

  useEffect(() => {
    if (timerOffCondition && activeTimer) {
      intervalRef.current && clearInterval(intervalRef.current);

      setActiveTimer(false);
      setRefreshTime(0);

      return;
    }
    if (!activeTimer && timerOnCondition) {
      setActiveTimer(true);
      setRefreshTime(timeFrom);
    }
  }, [activeTimer, timeFrom, timerOffCondition, timerOnCondition]);

  useEffect(() => {
    if (activeTimer) {
      if (refreshTime) {
        intervalRef.current = setInterval(() => {
          setRefreshTime((prevState) => {
            if (prevState === 1) {
              intervalRef.current && clearInterval(intervalRef.current);
              return 0;
            }

            return prevState - 1;
          });
        }, DELAY);
      } else {
        setActiveTimer(false);
      }
    }

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [activeTimer, finishCallback, refreshTime]);

  return {
    activeTimer,
    refreshTime,
  };
};
