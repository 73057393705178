import { FC, memo, useEffect, useState } from 'react';
import {
  Button,
  CardContent,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  useGetAccountFiltersQuery,
  useGetPdfAccountStatementQuery,
  useGetUserQuery,
} from '../../../services';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useNotification, useServerError } from '../../../hooks';
import { Loader } from '../../../shared';
import { Controller, useForm } from 'react-hook-form';
import { AccountStatementFormInterface } from '../../../types/user.profile.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from '../../../utils';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { transformDate } from '../../../helpers/dateTransformHelper';
import { DateFormatEnum } from '../../../types/dateTimeFormatOption';

type ChangeLoginTypeProps = {
  open: boolean;
  onClose: () => void;
};

export const AccountStatement: FC<ChangeLoginTypeProps> = memo(
  ({ open, onClose }) => {
    const [getPdf, setGetPdf] = useState<boolean>(false);
    const { showNotification } = useNotification();

    const { data: userData } = useGetUserQuery();

    const {
      data: accountStatementsFilters,
      isError: isErrorFilters,
      error: errorFilters,
      isFetching: isFetchingFilters,
      isSuccess: isSuccessFilters,
    } = useGetAccountFiltersQuery({}, { skip: !open });

    const { handleSubmit, reset, control, getValues, watch, setValue } =
      useForm<AccountStatementFormInterface>({
        defaultValues: {
          assets: '',
          statuses: '',
          operationTypes: '',
          monthFrom: null,
          dateFrom: null,
          monthTo: null,
          dateTo: null,
        },
        resolver: yupResolver(
          object({
            assets: stringScheme({ required: false }),
            operationTypes: stringScheme({ required: false }),
            statuses: stringScheme({ required: false }),
            monthFrom: stringScheme({ required: false }).nullable(),
            monthTo: stringScheme({ required: false }).nullable(),
            dateTo: stringScheme({ required: false }).nullable(),
            dateFrom: stringScheme({ required: false }).nullable(),
          })
        ),
      });

    const { assets, operationTypes, statuses } = getValues();

    const monthFromWatch = watch('monthFrom');
    const monthToWatch = watch('monthTo');
    const dateFromWatch = watch('dateFrom');
    const dateToWatch = watch('dateTo');

    const {
      isError: isErrorPdf,
      error: errorPdf,
      isFetching: isFetchingPdf,
      isSuccess: isSuccessPdf,
    } = useGetPdfAccountStatementQuery(
      {
        userName: `${userData?.name}-${userData?.lastName}`,
        status: statuses as string,
        asset: assets as string,
        operationType: operationTypes as string,
        dateFrom:
          transformDate(
            monthFromWatch as string,
            DateFormatEnum.YEAR_MONTH_DAY
          ) ||
          transformDate(dateFromWatch as string, DateFormatEnum.YEAR_MONTH_DAY),
        dateTo:
          transformDate(
            monthToWatch as string,
            DateFormatEnum.YEAR_MONTH_DAY
          ) ||
          transformDate(dateToWatch as string, DateFormatEnum.YEAR_MONTH_DAY),
      },
      {
        skip: !getPdf,
        refetchOnMountOrArgChange: true,
      }
    );

    const onSubmit = () => {
      setGetPdf(true);
    };

    useServerError({ isError: isErrorPdf, error: errorPdf });
    useServerError({ isError: isErrorFilters, error: errorFilters });

    useEffect(() => {
      if (isSuccessPdf) {
        setGetPdf(false);
        onClose();
        showNotification('Success!', 'success');
      }
    }, [isSuccessPdf]);

    useEffect(() => {
      if (isSuccessFilters && accountStatementsFilters) {
        reset({
          assets: '',
          operationTypes: '',
          statuses: '',
        });
      }
    }, [isSuccessFilters, open]);

    useEffect(() => {
      if (accountStatementsFilters) {
        setValue('statuses', accountStatementsFilters?.statuses?.COMPLETED);
        setValue('assets', 0);
        setValue('operationTypes', 0);
      }
    }, [accountStatementsFilters, open]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Account Statements" />
          <Divider />
          <Typography m={1} variant="h5">
            Filter Selection
          </Typography>
          {isFetchingFilters ? (
            <Loader pad={10} />
          ) : (
            accountStatementsFilters && (
              <Box
                sx={{
                  minWidth: 120,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  control={control}
                  name="assets"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth disabled={!isSuccessFilters}>
                      <InputLabel
                        error={!!error?.message}
                        id="demo-simple-select-label"
                      >
                        Assets
                      </InputLabel>
                      <Select
                        label="Assets"
                        labelId="demo-simple-select-label"
                        id="assets"
                        value={value}
                        error={!!error?.message}
                        onChange={onChange}
                      >
                        <MenuItem value={0}>
                          <em>All Assets</em>
                        </MenuItem>
                        {Object.values(accountStatementsFilters?.assets).map(
                          (assetsItem) => (
                            <MenuItem key={assetsItem} value={assetsItem}>
                              {assetsItem}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="operationTypes"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        error={!!error?.message}
                        id="demo-simple-select-label"
                      >
                        Operation Types
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        error={!!error?.message}
                        label="Operation Types"
                        onChange={onChange}
                      >
                        <MenuItem value={0}>
                          <em>All Types</em>
                        </MenuItem>
                        {Object.values(
                          accountStatementsFilters?.operationTypes
                        ).map((operationTypesItem) => (
                          <MenuItem
                            key={operationTypesItem}
                            value={operationTypesItem}
                          >
                            {operationTypesItem}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="statuses"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        error={!!error?.message}
                        id="demo-simple-select-label"
                      >
                        Statuses
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        error={!!error?.message}
                        label="Statuses"
                        onChange={onChange}
                      >
                        <MenuItem value={0}>
                          <em>All Statuses</em>
                        </MenuItem>
                        {Object.values(accountStatementsFilters?.statuses).map(
                          (statusesItem) => (
                            <MenuItem key={statusesItem} value={statusesItem}>
                              {statusesItem}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Controller
                    control={control}
                    name="monthFrom"
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              disabled={!!dateFromWatch || !!dateToWatch}
                              onChange={onChange}
                              value={value}
                              disableFuture
                              label={'Month From'}
                              views={['month', 'year']}
                              slotProps={{
                                actionBar: {
                                  actions: ['clear'],
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="monthTo"
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              disabled={
                                !!dateFromWatch ||
                                !!dateToWatch ||
                                !monthFromWatch
                              }
                              onChange={onChange}
                              value={value}
                              minDate={monthFromWatch}
                              disableFuture
                              label="Month To"
                              views={['month', 'year']}
                              slotProps={{
                                actionBar: {
                                  actions: ['clear'],
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Controller
                    control={control}
                    name="dateFrom"
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              disabled={!!monthFromWatch || !!monthToWatch}
                              onChange={onChange}
                              value={value}
                              disableFuture
                              label="Date From"
                              views={['year', 'month', 'day']}
                              slotProps={{
                                actionBar: {
                                  actions: ['today', 'clear'],
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="dateTo"
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              slotProps={{
                                actionBar: {
                                  actions: ['today', 'clear'],
                                },
                              }}
                              disabled={
                                !!monthFromWatch ||
                                !!monthToWatch ||
                                !dateFromWatch
                              }
                              onChange={onChange}
                              disableFuture
                              value={value}
                              minDate={dateFromWatch}
                              label="Date To"
                              views={['year', 'month', 'day']}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
            )
          )}

          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            loading={isFetchingPdf}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 3, ml: 1 }}
            type="submit"
          >
            Download PDF
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  }
);
