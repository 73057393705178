import { FC, memo, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField, IconButton } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import 'react-phone-input-2/lib/material.css';
import { theme } from '../../../../assets/theme';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { IExternalWallet, IUser } from '../../../../types/user.interface';
import { UserDataFormInterface } from '../../../../types/user.profile.interface';
import { CurrencySelect } from './currency.select';
import {
  useDeleteExternalWalletMutation,
  useGetCryptoCurrencyAssetsQuery,
} from 'services';
import { useNotification, useServerError } from 'hooks';

const MemoTextField = memo(TextField);

type UserExternalWalletsProps = {
  userData: IUser | undefined;
};

export const UserExternalWallets: FC<UserExternalWalletsProps> = memo(
  ({ userData }) => {
    const { showNotification } = useNotification();
    const { control } = useFormContext<UserDataFormInterface>();

    const { fields, append, remove } = useFieldArray<
      UserDataFormInterface,
      'ExternalWallets',
      '_id'
    >({
      name: 'ExternalWallets',
      control,
      keyName: '_id',
    });

    const [
      deleteExternalWallet,
      {
        isError: isErrorDeleteExternalWallet,
        error: errorDeleteExternalWallet,
        isSuccess: isSuccessExternalWalletDeleted,
      },
    ] = useDeleteExternalWalletMutation();

    useServerError({
      isError: isErrorDeleteExternalWallet,
      error: errorDeleteExternalWallet,
    });
    useEffect(() => {
      if (isSuccessExternalWalletDeleted) {
        showNotification('External Wallet was deleted!', 'success');
      }
    }, [isSuccessExternalWalletDeleted]);

    const {
      data: cryptoCurrencyAssets,
      isFetching: cryptoCurrencyIsFetching,
      error: cryptoCurrencyError,
      isError: cryptoCurrencyIsError,
    } = useGetCryptoCurrencyAssetsQuery({});

    useServerError({
      isError: cryptoCurrencyIsError,
      error: cryptoCurrencyError,
    });

    const firstCurrencyId = cryptoCurrencyAssets?.assets?.[0].id;

    const onDelete = (item: IExternalWallet | undefined, index: number) => {
      if (item?.id) {
        deleteExternalWallet({ id: item.id })
          .unwrap()
          .then(() => remove(index))
          .catch(() => null);
      } else {
        remove(index);
      }
    };

    const emptyExternalWallet = {
      address: '',
      currencyId: firstCurrencyId!,
      isVerified: false,
    };

    return (
      <Grid
        item
        xs={12}
        sx={{ gap: '10px', display: 'flex', flexDirection: 'column' }}
      >
        {fields.map((field, index) => {
          const userDataField = userData?.ExternalWallets?.[index];

          return (
            <Box
              key={field._id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Controller
                    control={control}
                    name={`ExternalWallets.${index}.currencyId`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CurrencySelect
                          disabled={!!userDataField}
                          value={value as number}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`ExternalWallets.${index}.address`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <MemoTextField
                          sx={{ margin: '8px 5px 8px 0' }}
                          error={!!error?.message}
                          helperText={error?.message}
                          value={value}
                          disabled={!!userDataField}
                          onChange={onChange}
                          margin="normal"
                          required
                          fullWidth
                          name={`ExternalWallets.${index}.address`}
                          label={'Address'}
                          id={`ExternalWallets.${index}.address`}
                          size="small"
                        />
                      );
                    }}
                  />
                  <Box
                    sx={{
                      fontSize: 12,
                      marginTop: 1,
                      color: userDataField?.isVerified
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                  >
                    {userDataField?.isVerified ? 'Verified' : 'Not verified'}
                  </Box>
                </Box>
              </Box>
              <IconButton
                aria-label="Delete"
                size="large"
                title="Delete external wallet"
                type="button"
                onClick={() => onDelete(userDataField, index)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        })}
        <Button
          sx={{ color: theme.palette.info.main }}
          type="button"
          disabled={cryptoCurrencyIsFetching}
          onClick={() => append(emptyExternalWallet)}
        >
          Add new External Wallet
        </Button>
      </Grid>
    );
  }
);
