import { Stack, Typography, Paper, Box } from '@mui/material';
import { FC } from 'react';
import { Container } from 'shared/container/container';
import { useGetTransactionsQuery } from 'services';
import { useServerError, useTransactionsHistoryFilter } from 'hooks';
import {
  LoadingComponent,
  OperationHistoryFiltersComponent,
  OperationHistoryTableComponent,
} from 'shared';
import { AccountStatement } from './account.statement';

export const OperationHistoryPage: FC = () => {
  const { data, isLoading, isError, error } = useGetTransactionsQuery({});

  const {
    data: transactions,
    onFilter,
    ...pagination
  } = useTransactionsHistoryFilter(data);
  useServerError({ isError, error });

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between', xs: 'center' },
          flexWrap: 'wrap',
          width: '100%',
          gap: '20px',
        }}
      >
        <Box>
          <Typography variant="h4" color="inherit" textAlign="left">
            Transaction History
          </Typography>
          <Typography sx={{ mt: 2 }} textAlign="left">
            There is a list of transactions.
          </Typography>
        </Box>
        <AccountStatement />
      </Box>
      <Stack spacing={1} sx={{ position: 'relative', width: '100%' }}>
        {isLoading ? (
          <Box sx={{ marginTop: '80px' }}>
            <LoadingComponent size={100} />
          </Box>
        ) : (
          <>
            <OperationHistoryFiltersComponent onFilter={onFilter} />
            <Paper elevation={4}>
              <OperationHistoryTableComponent
                data={transactions}
                {...pagination}
              />
            </Paper>
          </>
        )}
      </Stack>
    </Container>
  );
};
