import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Button, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { UserPasswordRecoveryInterface } from '../../types/user.profile.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { passwordScheme } from '../../utils';
import 'react-phone-input-2/lib/material.css';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { usePasswordUpdateMutation } from 'services';
import { useNotification, useServerError } from 'hooks';

export default function UserPasswordRecovery() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  const { showNotification } = useNotification();

  const [
    updatePassword,
    {
      isLoading,
      isError: isErrorPassword,
      error: errorUpdatePassword,
      isSuccess,
    },
  ] = usePasswordUpdateMutation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const { handleSubmit, reset, control } =
    useForm<UserPasswordRecoveryInterface>({
      defaultValues: {
        currentPassword: '',
        newPassword: '',
      },
      resolver: yupResolver(
        object({
          currentPassword: passwordScheme({ required: true }),
          newPassword: passwordScheme({ required: true }),
        })
      ),
    });

  const onSubmit: SubmitHandler<UserPasswordRecoveryInterface> = (data) => {
    updatePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    });
  };

  useServerError({ isError: isErrorPassword, error: errorUpdatePassword });

  useEffect(() => {
    if (isSuccess) {
      showNotification('Password was changed!', 'success');
      reset({
        currentPassword: '',
        newPassword: '',
      });
    }
  }, [isSuccess]);

  return (
    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
      <Typography component="h1" variant="h4" align="center">
        Change password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="currentPassword"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  margin="normal"
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="currentPassword"
                  label="Old password"
                  name="currentPassword"
                  autoComplete="currentPassword"
                  autoFocus
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="newPassword"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error?.message}
                  helperText={error?.message}
                  margin="normal"
                  required
                  value={value}
                  onChange={onChange}
                  fullWidth
                  id="newPassword"
                  label="New password"
                  name="newPassword"
                  autoComplete="newPassword"
                  size="small"
                  type={showNewPassword ? 'text' : 'password'}
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          id="updatePassButton"
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
      </Box>
    </Paper>
  );
}
