// created by Artem
import { FC, useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { countries, CountryType } from '../../constants/countries';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { AccountFormValues } from '../../types/auth.interface';
import { useGetInactiveCountriesQuery } from '../../services';

type CountrySelectProps = {
  register: UseFormRegister<AccountFormValues>;
  errors: FieldErrors<AccountFormValues>;
  setSelectedCountry: (value: string) => void;
  setValue: UseFormSetValue<AccountFormValues>;
  selectedCountry: string;
};

export const CountrySelect: FC<CountrySelectProps> = ({
  register,
  errors,
  setSelectedCountry = () => {},
  selectedCountry,
  setValue,
}) => {
  const { data: inactiveCountries, isFetching: isFetchingInactiveCountries } =
    useGetInactiveCountriesQuery(undefined);

  const countriesList = useMemo(() => {
    if (!inactiveCountries?.length) {
      return countries;
    }

    const inactiveCodes = inactiveCountries.map((item: any) => item?.isoCode);

    return countries.filter(({ code }) => !inactiveCodes.includes(code));
  }, [inactiveCountries]);

  const selectCountryHandler = (value: CountryType | null) => {
    if (value) {
      setSelectedCountry(value.code);
      setValue('Country', value.code);
    } else {
      setSelectedCountry('');
    }
  };

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ marginTop: '20px', mb: 0 }}
      options={countriesList}
      autoHighlight
      disabled={isFetchingInactiveCountries}
      onChange={(event, newValue) => selectCountryHandler(newValue)}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="36"
            srcSet={`https://flagcdn.com/w80/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...register('Country')}
            error={!!errors?.Country?.message}
            helperText={errors?.Country?.message}
            label="Country"
            required
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedCountry && (
                <img
                  style={{ marginLeft: '13px', marginRight: '30px' }}
                  loading="lazy"
                  width="36"
                  height="24"
                  srcSet={`https://flagcdn.com/w80/${selectedCountry?.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w40/${selectedCountry?.toLowerCase()}.png`}
                  alt=""
                />
              ),
            }}
          />
        );
      }}
    />
  );
};
