import { FC, memo, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import {
  Box,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';

import 'react-phone-input-2/lib/material.css';
import { theme } from '../../../assets/theme';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { IUser } from '../../../types/user.interface';
import { UserDataFormInterface } from '../../../types/user.profile.interface';
import { emptyIban } from './user.form';

const MemoTextField = memo(TextField);
const MemoFormControlLabel = memo(FormControlLabel);

type UserBankAccountsProps = {
  watch: UseFormWatch<UserDataFormInterface>;
  fields: FieldArrayWithId<UserDataFormInterface, 'iban', 'id'>[];
  control: Control<UserDataFormInterface, any>;
  trigger: UseFormTrigger<UserDataFormInterface>;
  removeIbanHandler: (
    item: FieldArrayWithId<UserDataFormInterface, 'iban', 'id'>,
    index: number
  ) => void;
  append: UseFieldArrayAppend<UserDataFormInterface, 'iban'>;
  userData: IUser | undefined;
};

export const UserBankAccounts: FC<UserBankAccountsProps> = memo(
  ({
    fields,
    watch,
    control,
    trigger,
    removeIbanHandler,
    append,
    userData,
  }) => {
    const watchIbans = watch('iban');

    const newIbans = useMemo(
      () => watchIbans.filter((iban) => !iban.Id),
      [watchIbans]
    );

    return (
      <Grid
        item
        xs={12}
        sx={{ gap: '10px', display: 'flex', flexDirection: 'column' }}
      >
        {fields.map((field, index) => {
          const isIbanWatch = watch(`iban.${index}.isIban`);
          const handleTriggerAccount = () => {
            trigger(`iban.${index}.account`);
          };
          const handleTriggerBankName = () => {
            trigger(`iban.${index}.bankName`);
          };
          return (
            <Box
              key={field.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Controller
                  control={control}
                  name={`iban.${index}.bankName`}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <MemoTextField
                        sx={{ margin: '8px 5px 8px 0' }}
                        error={!!error?.message}
                        helperText={error?.message}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleTriggerBankName();
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name={`iban.${index}.bankName`}
                        label="Bank name"
                        id={`iban.${index}.bankName`}
                        autoComplete="Bank name"
                        size="small"
                      />
                    );
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Controller
                    control={control}
                    name={`iban.${index}.account`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <MemoTextField
                          sx={{ margin: '8px 5px 8px 0' }}
                          error={!!error?.message}
                          helperText={error?.message}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleTriggerAccount();
                          }}
                          margin="normal"
                          required
                          fullWidth
                          name={`iban.${index}.account`}
                          label={isIbanWatch ? 'IBAN' : 'Bank account'}
                          id={`iban.${index}.account`}
                          autoComplete="iban"
                          size="small"
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`iban.${index}.isIban`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl sx={{ mt: 2 }}>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          shrink
                          sx={{ overflow: 'visible' }}
                        >
                          Is IBAN
                        </InputLabel>

                        <MemoFormControlLabel
                          sx={{ ml: '20px' }}
                          onChange={(e) => {
                            onChange(e);
                            handleTriggerAccount();
                          }}
                          control={
                            <Switch defaultChecked={false} checked={value} />
                          }
                          label={value}
                        />
                        {error && (
                          <FormHelperText error={!!error.message}>
                            {error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
              <IconButton
                sx={{ mt: '5px' }}
                aria-label="Delete"
                size="large"
                title="Delete bank account details"
                type="button"
                disabled={!!field.Id && userData?.Ibans.length === 1}
                onClick={() => removeIbanHandler(field, index)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        })}
        <Button
          sx={{ color: theme.palette.info.main }}
          type="button"
          disabled={newIbans?.length === 1}
          onClick={() => append(emptyIban)}
        >
          Add new Bank Account
        </Button>
      </Grid>
    );
  }
);
