import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import {
  Link as NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RegisterFormValues } from 'types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object, string } from 'yup';
import {
  useCheckPhoneNumberMutation,
  useGetInactiveCountriesQuery,
  useRegisterEntityMutation,
  useRegisterMutation,
} from 'services';
import { useServerError } from 'hooks';
import {
  emailScheme,
  getErrorMessage,
  passwordConfirmScheme,
  passwordScheme,
} from 'utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PhoneInput from 'react-phone-input-2';
import { theme } from '../../assets/theme';
import { styled } from '@mui/system';
import { PATH_REGISTER } from '../../constants/spa-routes';
import { TwoFactorTypesEnum } from '../../types/user.interface';

const StyledPhoneInput = styled(PhoneInput)(({ theme: styledTheme }) => ({
  '&.react-tel-input .form-control:focus': {
    borderColor: styledTheme.palette.primary.main,
    boxShadow: `0px 0px 0px 1px ${styledTheme.palette.primary.main}`,
  },
}));

type RegisterPageProps = {};
export const AuthRegisterFormPage: FC<RegisterPageProps> = () => {
  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password'
  );
  const [confirmPasswordType, setConfirmPasswordType] = useState<
    'password' | 'text'
  >('password');

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    data: countries,
    isError: isErrorInactiveCountries,
    error: errorInactiveCountries,
  } = useGetInactiveCountriesQuery(undefined);

  const excludeCountryCodes = useMemo(() => {
    if (!countries?.length) return [];

    return countries.map(({ isoCode }) => isoCode?.toLowerCase());
  }, [countries]);

  const [
    registerRequest,
    { isLoading, isError: isRegisterError, error: registerError },
  ] = useRegisterMutation();

  const [
    checkPhoneNumber,
    {
      isError: isErrorCheckPhoneNumber,
      isSuccess: isSuccessCheckPhoneNumber,
      isLoading: isLoadingCheckPhoneNumber,
    },
  ] = useCheckPhoneNumberMutation();

  const [
    registerEntityRequest,
    {
      isLoading: isLoadingRegisterEntity,
      isError: isErrorRegisterEntity,
      error: errorRegisterEntity,
    },
  ] = useRegisterEntityMutation();

  const accountVariant: number =
    searchParams.get('accountVariant') || location.state?.accountVariant || '';

  const selectedCountry: string =
    searchParams.get('selectedCountry') ||
    location.state?.selectedCountry ||
    '';

  const emailRoute: string =
    searchParams.get('email') || location.state?.email || '';

  const companyName: string =
    searchParams.get('companyName') || location.state?.companyName || '';

  const registrationNumber: string =
    searchParams.get('registrationNumber') ||
    location.state?.registrationNumber ||
    '';

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    setError,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    defaultValues: {
      name: '',
      email: emailRoute,
      lastName: '',
      middleName: '',
      password: '',
      passwordConfirmation: '',
      phone: '',
    },
    resolver: yupResolver(
      object({
        name: string().required().min(2).label('Name'),
        email: emailScheme({ required: true }).label('Email'),
        lastName: string().required().min(2).label('Last name'),
        middleName: string().nullable().label('Middle name'),
        password: passwordScheme({ required: true }).label('Password'),
        passwordConfirmation: passwordConfirmScheme({ required: true }).label(
          'Password confirmation'
        ),
        phone: string().required().min(4).label('Phone'),
        acceptTerms:
          selectedCountry === 'AE'
            ? boolean()
                .required('You must accept the Terms and Conditions')
                .oneOf([true], 'You must accept the Terms and Conditions')
            : boolean(),
      })
    ),
  });

  const { phone } = getValues();

  const phoneHandler = (phoneNumber: string, isErrorMessage: boolean) => {
    setValue('phone', `+${phoneNumber}`);
    if (isErrorMessage) {
      trigger('phone');
    }
  };

  const inputPhoneStyle = {
    width: '100%',
    padding: '18px 14px 18px 84px',
    borderColor: !!errors?.phone?.message ? theme.palette.error.main : '',
  };

  const navigateToVerify = (email: string, phoneNumber: string) => {
    navigate(PATH_REGISTER.REGISTER_EMAIL_VERIFY, {
      state: {
        email,
        phone: phoneNumber,
        verifyType: 'register',
        twoFactorType: TwoFactorTypesEnum.EMAIL,
      },
    });
  };

  const onSubmit: SubmitHandler<RegisterFormValues> = (data) => {
    if (accountVariant === 1) {
      registerRequest({
        name: data.name,
        email: data.email,
        lastName: data.lastName,
        password: data.password,
        phone: data.phone,
        middleName: data.middleName,
        countryCode: selectedCountry,
        acceptTerms: data.acceptTerms,
      })
        .unwrap()
        .then(() => navigateToVerify(data.email, data.phone))
        .catch(() => null);
    } else {
      registerEntityRequest({
        name: data.name,
        email: data.email,
        lastName: data.lastName,
        password: data.password,
        phone: data.phone,
        middleName: data.middleName,
        companyRegistrationNumber: registrationNumber,
        countryCode: selectedCountry,
        companyName: companyName,
        acceptTerms: data.acceptTerms,
      })
        .unwrap()
        .then(() => navigateToVerify(data.email, data.phone))
        .catch(() => null);
    }
  };

  const changePassTypeHandle = () =>
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));

  const changeConfirmPassTypeHandle = () =>
    setConfirmPasswordType((prev) =>
      prev === 'password' ? 'text' : 'password'
    );

  const isRegisterErrorIncludesPhone =
    getErrorMessage(registerError).includes('phone' || 'valid') ||
    getErrorMessage(errorRegisterEntity).includes('phone' || 'valid');

  useServerError({
    isError: !isRegisterErrorIncludesPhone && isRegisterError,
    error: registerError,
  });
  useServerError({
    isError: !isRegisterErrorIncludesPhone && isErrorRegisterEntity,
    error: errorRegisterEntity,
  });
  useServerError({
    isError: isErrorInactiveCountries,
    error: errorInactiveCountries,
  });

  useEffect(() => {
    if (isRegisterError || isErrorRegisterEntity) {
      checkPhoneNumber({ phone });
    }
  }, [isRegisterError, isErrorRegisterEntity]);

  useEffect(() => {
    if (isErrorCheckPhoneNumber) {
      setError('phone', {
        message: 'This phone already exists.',
      });
    } else if (isSuccessCheckPhoneNumber && isRegisterErrorIncludesPhone) {
      setError('phone', {
        message: 'Phone must be a valid phone number',
      });
    }
  }, [isErrorCheckPhoneNumber, isSuccessCheckPhoneNumber]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        {...register('name')}
        error={!!errors?.name?.message}
        helperText={errors?.name?.message}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoComplete="given-name"
        autoFocus
      />
      <TextField
        {...register('middleName')}
        error={!!errors?.middleName?.message}
        helperText={errors?.middleName?.message}
        margin="normal"
        fullWidth
        id="middleName"
        label="Middle name"
        name="middleName"
        autoComplete="additional-name"
      />
      <TextField
        {...register('lastName')}
        error={!!errors?.lastName?.message}
        helperText={errors?.lastName?.message}
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last name"
        name="lastName"
        autoComplete="family-name"
      />
      <TextField
        {...register('email')}
        error={!!errors?.email?.message}
        helperText={errors?.email?.message}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        disabled
        name="email"
        type="email"
        autoComplete="email"
        sx={{ marginBottom: '24px' }}
      />
      <Controller
        control={control}
        name="phone"
        render={({ fieldState: { error } }) => (
          <>
            <StyledPhoneInput
              key={`phone-input-${excludeCountryCodes.length}`}
              country={selectedCountry?.toLowerCase() || 'us'}
              value={phone}
              excludeCountries={excludeCountryCodes}
              sx={{ '.special-label': { left: '9px' } }}
              onChange={(phoneNumber) =>
                phoneHandler(phoneNumber, !!error?.message)
              }
              inputClass="phone-input"
              inputStyle={inputPhoneStyle}
              specialLabel="Phone *"
              inputProps={{
                autoComplete: 'tel',
                form: {
                  autoComplete: 'tel',
                },
              }}
            />
            {!!error?.message && (
              <Typography
                align="left"
                variant="caption"
                color={theme.palette.error.main}
                sx={{
                  margin: '4px 14px 0 14px',
                  width: '100%',
                  display: 'block',
                }}
              >
                {error?.message}
              </Typography>
            )}
          </>
        )}
      />
      <TextField
        {...register('password')}
        error={!!errors?.password?.message}
        helperText={errors?.password?.message}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={passwordType}
        sx={{ mt: '24px' }}
        id="password"
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={changePassTypeHandle}>
                {passwordType === 'text' ? (
                  <RemoveRedEyeOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        {...register('passwordConfirmation')}
        error={!!errors?.passwordConfirmation?.message}
        helperText={errors?.passwordConfirmation?.message}
        margin="normal"
        required
        fullWidth
        name="passwordConfirmation"
        label="Repeat password"
        type={confirmPasswordType}
        id="passwordConfirmation"
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={changeConfirmPassTypeHandle}>
                {confirmPasswordType === 'text' ? (
                  <RemoveRedEyeOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {selectedCountry === 'AE' && (
        <Controller
          name="acceptTerms"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value || false}
                />
              }
              label={
                <span>
                  I agree to the{' '}
                  <Link
                    href="https://www.fuze.finance/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </Link>
                </span>
              }
            />
          )}
        />
      )}
      {selectedCountry === 'AE' && errors.acceptTerms && (
        <Typography color="error" variant="body2">
          {errors.acceptTerms.message}
        </Typography>
      )}
      <Button
        id="authSignUpButton"
        type="submit"
        disabled={
          isLoading || isLoadingRegisterEntity || isLoadingCheckPhoneNumber
        }
        endIcon={
          isLoading || isLoadingRegisterEntity || isLoadingCheckPhoneNumber ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            <SaveIcon />
          )
        }
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign Up
      </Button>
      <Grid container justifyContent="center">
        <Grid item>
          <Link variant="body2" align="center" component={NavLink} to="/auth">
            Already has account
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
