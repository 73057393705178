import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { setModalState } from '../../store/ui';
import { useDispatch } from 'react-redux';

interface AccountStatementProps {}

export const AccountStatement: FC<AccountStatementProps> = () => {
  const dispatch = useDispatch();

  const accountStatementModalOpen = () =>
    dispatch(setModalState({ visible: true, name: 'accountStatement' }));

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        mb={1}
      >
        <Typography variant="h6" mr={1}>
          PDF Account statement
        </Typography>
      </Box>
      <Button variant={'outlined'} onClick={accountStatementModalOpen}>
        Open Filter
      </Button>
    </Box>
  );
};
