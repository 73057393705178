// created by Andrii
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import UserForm from './user.form/user.form';
import { Tabs, Tab, Box, Grid } from '@mui/material';
import { KycForm } from './kyc.form';
import UserPasswordRecovery from './user.pasword.recovery';
import { Container } from 'shared/container/container';
import { Typography } from '@mui/material';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { Settings } from 'pages/settings/settings.page';
import Sidebar from './sidebar';
import { MoreAssets } from './more.assets/more.assets';
import { useGetUserQuery } from '../../services';
import { Notifications } from './notifications/notifications';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const PANEL_LIST = {
  USER_FORM: 0,
  PASSWORD_CHANGE: 1,
  SUMSUB: 2,
  SETTINGS: 3,
  MORE_ASSETS: 4,
  NOTIFICATIONS: 5,
};

type SubRoutes =
  | 'profile'
  | 'password-change'
  | 'sumsub'
  | 'settings'
  | 'more-assets'
  | 'notifications';

type SubRouteIndexes = 0 | 1 | 2 | 3 | 4 | 5;

type PanelRouteNames = {
  [index: number]: SubRoutes;
};

const PANEL_ROUTES_NAMES: PanelRouteNames = {
  0: 'profile',
  1: 'password-change',
  2: 'sumsub',
  3: 'settings',
  4: 'more-assets',
  5: 'notifications',
};

type PanelRouteIndexes = {
  [key: string]: SubRouteIndexes;
};

const PANEL_ROUTES_INDEXES: PanelRouteIndexes = {
  profile: 0,
  'password-change': 1,
  sumsub: 2,
  settings: 3,
  'more-assets': 4,
  notifications: 5,
};

type UserProfilePage = {};
export const UserProfile: FC<UserProfilePage> = () => {
  const [value, setValue] = useState<number>(PANEL_LIST.USER_FORM);
  const navigator = useNavigate();
  const location = useLocation();

  const { data: userData } = useGetUserQuery();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigator(PANEL_ROUTES_NAMES[newValue as number] as To);
  };

  useEffect(() => {
    const [rootPath, to] = location.pathname.split('/').filter(Boolean);
    if (rootPath === 'user-profile' && !to) {
      navigator(PANEL_ROUTES_NAMES[PANEL_LIST.USER_FORM]);
      setValue(PANEL_ROUTES_INDEXES[PANEL_LIST.USER_FORM] as number);
    } else if (rootPath === 'user-profile' && to) {
      const transformedTo = to.replace('/', '');
      setValue(PANEL_ROUTES_INDEXES[transformedTo as string] as number);
    }
  }, [location, navigator]);

  return (
    <Container>
      <Typography variant="h4" color="inherit" noWrap>
        User Profile
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="Profile tabs"
        >
          <Tab id="userInfoTab" label="User info" />
          <Tab id="changePasswordTab" label="Change password" />
          <Tab id="kycTab" label={userData?.isLegalEntity ? 'KYB' : 'KYC'} />
          <Tab id="settingsTab" label="Settings" />
          <Tab id="moreAssetsTab" label="More Assets" />
          <Tab id="notifications" label="Notifications" />
        </Tabs>
      </Box>
      <Box sx={{ mt: 3, width: '100%' }}>
        <TabPanel value={value} index={PANEL_LIST.USER_FORM}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <UserForm />
            </Grid>
            <Sidebar title="Verified Details" />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={PANEL_LIST.PASSWORD_CHANGE}>
          <UserPasswordRecovery />
        </TabPanel>
        <TabPanel value={value} index={PANEL_LIST.SUMSUB}>
          <KycForm />
        </TabPanel>
        <TabPanel value={value} index={PANEL_LIST.SETTINGS}>
          <Settings />
        </TabPanel>
        <TabPanel value={value} index={PANEL_LIST.MORE_ASSETS}>
          <MoreAssets />
        </TabPanel>
        <TabPanel value={value} index={PANEL_LIST.NOTIFICATIONS}>
          <Notifications />
        </TabPanel>
      </Box>
    </Container>
  );
};
