export type AssetData = {
  total: number;
  address: string;
  value: string;
  convertedTotal: number;
  rate: number;
};

export type Deposit = Partial<Record<Crypto, AssetData>>;

export type Withdraw = Record<
  Fiat,
  Pick<AssetData, 'total' | 'convertedTotal' | 'rate'>
>;

export type UserBalance = {
  deposit: Deposit;
  withdraw: Withdraw;
  total: number;
};

export type OperationType = 'EXCHANGE' | 'WITHDRAW' | 'DEPOSIT';

export const DEF_CURRENCY_KEY = 'defCurrency';

export type Crypto =
  | 'ETH'
  | 'USDT'
  | 'USDC'
  | 'ADA'
  | 'LTC'
  | 'MATIC'
  | 'SOL'
  | 'BTC';

export type Fiat = 'USD' | 'EUR' | 'GBP' | 'AED';

export enum FiatEnum {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AED = 'AED',
}

export type BaseOperation = {
  type: OperationType;
  timestamp: number;
  amount: number;
  currency: Crypto | Fiat;
};

export type ExchangeOperationStatus = 'IN_PROGRESS' | 'DONE';

export type ExchangeOperation = Omit<BaseOperation, 'amount' | 'currency'> & {
  transaction: string;
  status: ExchangeOperationStatus;
  exchange_rate: number;
  fromCurrency: Crypto;
  toCurrency: Fiat;
  from: string;
  to: string;
  pay: number;
  recieve: number;
};

export type DepositOperation = BaseOperation;

export type WithdrawOperation = BaseOperation & {
  iban: string;
};

export type OperationDetails =
  | WithdrawOperation
  | DepositOperation
  | ExchangeOperation;
