// created by Artem
import { FC } from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  Box,
  Container,
  Link as MaterialLink,
  styled,
  Typography,
} from '@mui/material';
import { AuthWrapComponent } from 'shared';
import LogoQBS from 'assets/logo.svg';

const StyledLogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  [theme.breakpoints.down('md')]: {
    paddingTop: '10px',
    img: { width: '50px', height: '50px' },
  },
}));

type AuthLayoutProps = {
  customContainerProps?: Parameters<typeof Container>[0];
};

export const AuthLayout: FC<AuthLayoutProps> = ({ customContainerProps }) => {
  return (
    <AuthWrapComponent>
      <Link to={'/'}>
        <StyledLogoWrapper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <img src={LogoQBS} alt="logo" width={115} height={165} />
        </StyledLogoWrapper>
      </Link>
      <Container
        component="main"
        maxWidth="sm"
        sx={{ pb: 5 }}
        {...customContainerProps}
      >
        <Outlet />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <Typography variant="body2" color="text.secondary" align="center">
            <MaterialLink
              color="text.secondary"
              target="_blank"
              href="/privacy-policy"
            >
              Privacy Policy
            </MaterialLink>
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <MaterialLink
              color="inherit"
              target="_blank"
              href="https://www.qbs.ag/"
            >
              QBS
            </MaterialLink>
            {` ${new Date().getFullYear()}.`}
          </Typography>
        </Box>
      </Container>
    </AuthWrapComponent>
  );
};
