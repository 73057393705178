import { NumericFormat } from 'react-number-format';
import React from 'react';

const numberFormatHelper = (
  value: number,
  decimalVal = 8,
  digitsAfterComma = 0
) => {
  const multiplier = Number(BigInt(10 ** decimalVal));

  const finalValue = Math.floor(value * multiplier) / multiplier;

  return (
    <NumericFormat
      value={finalValue}
      displayType="text"
      decimalScale={!Number(finalValue) ? digitsAfterComma : decimalVal}
      fixedDecimalScale={!Number(finalValue)}
    />
  );
};

export { numberFormatHelper };
