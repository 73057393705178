import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  Box,
  Skeleton,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fiat } from 'store/wallet/types';
import { formatPrice } from '../../../utils';
import Typography from '@mui/material/Typography';
import {
  useGetExchangeFiatRateQuery,
  useGetUserQuery,
} from '../../../services';
import { AvailableSoonDialogContent } from '../../available_soon_dialog_content';
import { FiatCurrency } from '../../../shared/currencies/currencies';
import { getFilteredCurrencyList } from 'shared/currencies/currencies-list';

type SendFiatModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedSymbol: string;
};

type SendFiatFormData = {
  amount: number;
  currency: string;
  iban: string;
};

export const ConvertFiat: FC<SendFiatModalProps> = ({
  isOpen,
  onClose,
  selectedSymbol = 'USD',
}) => {
  const [changeView, setChangeView] = useState<boolean>(true);

  const [selectedCurrency, setSelectedCurrency] = useState<Fiat>(
    selectedSymbol as Fiat
  );

  const [currencies, setCurrencies] = useState<FiatCurrency[]>([]);
  const { data: userData, isLoading: isFetchingUserData } = useGetUserQuery();

  useEffect(() => {
    const filteredCurrencies = getFilteredCurrencyList(userData);
    setCurrencies(filteredCurrencies);
  }, [userData]);

  // const userBalance =
  //   (balance?.withdraw?.[selectedSymbol as Fiat]?.total as number) || 0;

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .transform((value) => (isNaN(value) ? undefined : value))
      .default(undefined)
      .positive('Amount must be a positive number')
      .required('Amount is required'),
    // .max(
    //   userBalance,
    //   `Do not convert more money than ${userBalance} ${selectedSymbol}.`
    // ),
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<SendFiatFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setSelectedCurrency(event.target.value as Fiat);
    trigger();
  };

  const beAvailableSoonHandler = () => {
    setChangeView((prevState) => !prevState);
  };

  const { data: exchangeRate, isFetching: isFetchingExchangeRate } =
    useGetExchangeFiatRateQuery({
      amount: 1,
      fiatFrom: selectedSymbol,
      fiatTo: selectedCurrency,
    });

  const onSubmit = () => {
    beAvailableSoonHandler();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      {changeView ? (
        <>
          <DialogTitle>Convert Fiat</DialogTitle>
          <DialogContent>
            <form>
              <TextField
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                }}
                margin="normal"
                label="Amount"
                type="number"
                {...register('amount', { required: 'Amount is required' })}
                error={!!errors.amount}
                onKeyDown={handleKeyDown}
                helperText={errors.amount?.message}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="currency-label">Currency</InputLabel>
                <Select
                  labelId="currency-label"
                  id="currency-select"
                  label="Currency"
                  {...register('currency', {
                    required: 'Currency is required',
                  })}
                  onChange={handleCurrencyChange}
                  value={selectedCurrency}
                  error={!!errors.currency}
                  defaultValue="USD"
                  disabled={false || isFetchingUserData}
                >
                  {currencies.map((el) => (
                    <MenuItem key={el.id} value={el.symbol}>
                      {el.symbol}
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                    }}
                  >
                    1 {selectedSymbol} ={' '}
                    {isFetchingExchangeRate ? (
                      <Skeleton variant="text" sx={{ width: '50px' }} />
                    ) : (
                      formatPrice(exchangeRate?.rate as number, 3)
                    )}{' '}
                    {selectedCurrency}
                  </Typography>
                </Box>
              </FormControl>
              <DialogActions sx={{ mt: 3 }}>
                <Button onClick={onClose}>Cancel</Button>
                <Box onClick={handleSubmit(onSubmit)} ml={2}>
                  <Button
                    disabled
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Review convert
                  </Button>
                </Box>
              </DialogActions>
            </form>
          </DialogContent>
        </>
      ) : (
        <AvailableSoonDialogContent onClose={onClose} />
      )}
    </Dialog>
  );
};
