import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { useServerError } from 'hooks';
import { useEffect } from 'react';
import { useGetCryptoCurrencyAssetsQuery } from 'services/crypto.currency.service';

type CurrencySelectProps = {
  onChange: (currencyId: number) => void;
  value: number | undefined;
  error?: boolean;
  disabled?: boolean;
};

export const CurrencySelect = ({
  error,
  onChange,
  value,
  disabled,
}: CurrencySelectProps) => {
  const {
    data: cryptoCurrencyAssets,
    isFetching: cryptoCurrencyIsFetching,
    error: cryptoCurrencyError,
    isError: cryptoCurrencyIsError,
  } = useGetCryptoCurrencyAssetsQuery({});

  useEffect(() => {
    const firstCurrencyId = cryptoCurrencyAssets?.assets?.[0].id;

    if (firstCurrencyId && !value) {
      onChange(firstCurrencyId);
    }
  }, [cryptoCurrencyAssets, value]);

  useServerError({
    isError: cryptoCurrencyIsError,
    error: cryptoCurrencyError,
  });

  if (cryptoCurrencyIsFetching) return null;
  return (
    <Select
      labelId="currency-label"
      id="currency-select"
      label="Currency"
      sx={{ margin: '8px 5px 8px 0', height: 40 }}
      onChange={(e) => onChange(e.target.value as number)}
      defaultValue={cryptoCurrencyAssets?.assets?.[0].id}
      value={value}
      error={error}
      disabled={disabled || cryptoCurrencyIsFetching}
    >
      {cryptoCurrencyAssets?.assets?.map((asset) => (
        <MenuItem key={asset.id} value={asset.id}>
          {asset.symbol}
        </MenuItem>
      ))}
    </Select>
  );
};
