// created by Artem
import React, { FC } from 'react';
import { DoNotDisturb } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { OperationHistoryRowComponent } from './operation-history-row.component';
import {
  IDeposits,
  IExchanges,
  IWithdrawals,
} from '../../types/operation-history.interface';

type OperationHistoryTableComponentProps = {
  data?: IExchanges[] | IDeposits[] | IWithdrawals[];
  onPageChange: (event: unknown, newPage: number) => void;
  currentPage: number;
  pageSize: number;
  total: number;
};
export const OperationHistoryTableComponent: FC<
  OperationHistoryTableComponentProps
> = ({ data, onPageChange, currentPage, pageSize, total }) => {
  return (
    <>
      {data?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: 10,
          }}
        >
          <DoNotDisturb />
          <Typography ml={1}>You haven't got any operations yet.</Typography>
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <Typography fontWeight={600}>ID</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Type</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Status</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Amount</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Date</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((row) => (
                  <OperationHistoryRowComponent
                    key={`history-${row?.createdAt}`}
                    data={row}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={total}
            rowsPerPage={pageSize}
            page={currentPage}
            onPageChange={onPageChange}
            backIconButtonProps={{
              disabled: !currentPage,
            }}
          />
        </>
      )}
    </>
  );
};
