import { FC, MouseEvent } from 'react';
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import MarkAsReadIcon from '@mui/icons-material/CheckCircleOutline';
import { Notification as INotification } from 'types/wallet.interface';
import { CalendarMonth } from '@mui/icons-material';
import { transformDate } from 'helpers/dateTransformHelper';
import { DateFormatEnum } from '../../types/dateTimeFormatOption';
import { getContent, getIcon } from '../../utils';

type NotificationProps = {
  notification: INotification;
  onReadHandler: (notification: INotification) => void;
};

export const Notification: FC<NotificationProps> = ({
  notification,
  onReadHandler,
}) => {
  const { id, createdAt, read, message } = notification;

  const handleMarkAsRead = (event: MouseEvent) => {
    event.stopPropagation();
    onReadHandler(notification);
  };

  return (
    <Grid
      container
      sx={{
        padding: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        color: notification.read ? '#666766' : 'black',
        minWidth: { xs: '320px', md: '400px' },
        cursor: 'pointer',
        transition: '.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(150,150,150,0.1)',
        },
      }}
    >
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: '8px',
          }}
        >
          {getIcon(notification)}
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              marginLeft: '10px',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '8px' }}>
              #{id} {message}
            </span>
          </div>
        </Box>
        {!notification.read && (
          <Tooltip title="Mark as Read" placement="top">
            <IconButton size="small" onClick={handleMarkAsRead}>
              <MarkAsReadIcon color={read ? 'disabled' : 'primary'} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Box textAlign="left">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography
            sx={{ display: 'flex', alignItems: 'center', lineHeight: 1.25 }}
          >
            <CalendarMonth
              color="primary"
              sx={{ marginRight: 1, fontSize: '1.25rem' }}
            />{' '}
            {transformDate(createdAt, DateFormatEnum.DATE_WITH_TIME_WITH_DOT)}
          </Typography>
        </Box>
        {getContent(notification)}
      </Box>
    </Grid>
  );
};
