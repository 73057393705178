import { FC } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from 'assets/logo.svg';
import AppBar from '@mui/material/AppBar';
import { Link, Stack } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';

const RootBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

interface FooterProps {
  content?: {
    brand: {
      image?: string;
      width: number;
      text?: string;
    };
    copy: string;
  };
}

export const Footer: FC<FooterProps> = () => {
  const content = {
    brand: { image: Logo, height: 50, text: 'QCE' },
    copy: `© ${new Date().getFullYear()} QBS All rights reserved.`,
    links: {
      'privacy-policy': {
        name: 'Privacy',
        route: '/privacy-policy',
      },
    },
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'grey.900' }}>
      <Box sx={{ maxWidth: '100%', padding: { xs: '0 16px', md: '0 40px' } }}>
        <RootBox
          py={2}
          sx={{
            position: 'relative',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',
          }}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <Link
            sx={{ position: { xs: 'relative', sm: 'absolute' }, left: 0 }}
            color="secondary.main"
            href="/privacy-policy"
            target="_blank"
            variant="caption"
          >
            {content['links']['privacy-policy']['name']}
          </Link>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              color="secondary.main"
              component="p"
              variant="caption"
              lineHeight={1}
              fontWeight="bold"
              mr={1}
            >
              Socials Networks:
            </Typography>
            <Link
              height={24}
              width={24}
              display="block"
              variant="body1"
              href="https://linkedin.com/company/qbs-ag"
              target="_blank"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <LinkedIn sx={{ color: 'secondary.main' }} />
              </Stack>
            </Link>
          </Box>
          <Typography
            sx={{ position: { xs: 'relative', sm: 'absolute' }, right: 0 }}
            color="secondary.main"
            component="p"
            variant="caption"
            gutterBottom={false}
          >
            {content['copy']}
          </Typography>
        </RootBox>
      </Box>
    </AppBar>
  );
};
