import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Dialog,
  Divider,
  FormHelperText,
  Stack,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import {
  useDeleteAvatarMutation,
  useGetUserQuery,
  useUploadAvatarMutation,
} from '../../../services';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useNotification, useServerError } from '../../../hooks';
import Typography from '@mui/material/Typography';
import { formatBytes } from '../../../helpers/bytesTransformHelper';

type ChangeLoginTypeProps = {
  open: boolean;
  onClose: () => void;
};

const AVAILABLE_IMAGE_SIZE = 7 * 1024 * 1024;
const AVAILABLE_IMAGE_SIZE_MB = 7;

export const AvatarUploadModal: FC<ChangeLoginTypeProps> = memo(
  ({ open, onClose }) => {
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [imageFile, setImageFile] = useState<any>(null);

    const { showNotification } = useNotification();

    const { data: userData } = useGetUserQuery();

    const [
      uploadAvatar,
      {
        isError: isErrorUploadAvatar,
        error: errorUploadAvatar,
        isSuccess: isSuccessUploadAvatar,
        isLoading: isLoadingUploadAvatar,
      },
    ] = useUploadAvatarMutation();

    const [
      deleteAvatar,
      {
        isError: isErrorDeleteAvatar,
        error: errorDeleteAvatar,
        isSuccess: isSuccessDeleteAvatar,
        isLoading: isLoadingDeleteAvatar,
      },
    ] = useDeleteAvatarMutation();

    const onSave = () => {
      const data = new FormData();
      data.append('image', imageFile);

      uploadAvatar(data);
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setImageFile(file);
        const reader = new FileReader();

        reader.onloadend = () => {
          setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
      }
    };

    const handleDeletePhoto = () => {
      deleteAvatar(null);
      setImageFile(null);
    };

    const imageSizeError =
      imageFile?.size && +imageFile?.size >= AVAILABLE_IMAGE_SIZE;

    useServerError({ isError: isErrorUploadAvatar, error: errorUploadAvatar });
    useServerError({ isError: isErrorDeleteAvatar, error: errorDeleteAvatar });

    useEffect(() => {
      if (isSuccessUploadAvatar) {
        onClose();
        showNotification('Avatar updated!', 'success');
      }
    }, [isSuccessUploadAvatar]);

    useEffect(() => {
      if (isSuccessDeleteAvatar) {
        showNotification('Avatar deleted!', 'success');
      }
    }, [isSuccessDeleteAvatar]);

    useEffect(() => {
      if (userData?.profileImage) {
        setImageUrl(userData?.profileImage);
      } else {
        setImageUrl(null);
      }
      setImageFile(null);
    }, [userData, open]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <CardContent sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ textAlign: 'center' }} title="Upload Your avatar" />
          <Divider />
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ margin: '10px 0' }}
          >
            <Avatar
              src={imageUrl && typeof imageUrl === 'string' ? imageUrl : ''}
              alt="Uploaded Image"
              sx={{
                height: '300px',
                width: '300px',
                background:
                  'linear-gradient(138deg, #535353 -2.49%, #BFBFBF 51.42%, #4D4A4A 100%)',
              }}
            >
              <Typography variant="h1" fontWeight="bold">
                {userData?.name[0] + '' + userData?.lastName[0]}
              </Typography>
            </Avatar>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'row-reverse',
              }}
            >
              <label
                htmlFor="upload-image"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  component="span"
                  sx={{ width: '100px' }}
                >
                  Upload
                </Button>
                <input
                  id="upload-image"
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>
              <LoadingButton
                disabled={!userData?.profileImage}
                loading={isLoadingDeleteAvatar}
                variant="outlined"
                component="span"
                sx={{ width: '100px' }}
                onClick={handleDeletePhoto}
              >
                Delete
              </LoadingButton>
            </Box>
            {imageSizeError && (
              <FormHelperText sx={{ textAlign: 'center' }} error>
                Your image size {formatBytes(imageFile?.size)} <br />
                Image size must be less or equal {AVAILABLE_IMAGE_SIZE_MB} MB
              </FormHelperText>
            )}
          </Stack>
          <Divider />
          <Button sx={{ mt: 3, ml: 1 }} onClick={onClose} variant="outlined">
            Close
          </Button>
          <LoadingButton
            endIcon={<SaveIcon />}
            loadingPosition="end"
            loading={isLoadingUploadAvatar}
            variant="contained"
            onClick={onSave}
            sx={{ mt: 3, ml: 1 }}
            disabled={imageSizeError || !imageFile}
          >
            Save
          </LoadingButton>
        </CardContent>
      </Dialog>
    );
  }
);
