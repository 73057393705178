import { BTCLogo } from 'assets/icons/btc';
import { ADALogo } from 'assets/icons/ada';
import { ETHLogo } from 'assets/icons/eth';
import { EURLogo } from 'assets/icons/eur';
import { GBPLogo } from 'assets/icons/gbp';
import { AEDLogo } from 'assets/icons/aed';
import { LTCLogo } from 'assets/icons/ltc';
import { MATIC } from 'assets/icons/matic';
import solanaLogo from 'assets/icons/solana-sol-logo.svg';
import { USDLogo } from 'assets/icons/usd';
import { USDCLogo } from 'assets/icons/usdc';
import { USDTLogo } from 'assets/icons/usdt';
import { ReactNode } from 'react';
import { Crypto, Fiat } from 'store/wallet/types';

export type ExchangeCoin = {
  symbol: Crypto;
  icon: ReactNode | string;
  name: string;
  id: number;
  protocol: string;
  network: string;
  assetWalletName?: string;
  isActive: boolean;
};

export const EXCHANGE_COIN_LIST: ExchangeCoin[] = [
  {
    protocol: 'ethereum',
    network: 'mainnet',
    name: 'Ethereum',
    symbol: 'ETH',
    icon: <ETHLogo />,
    id: 1,
    isActive: true,
  },
  {
    protocol: 'ethereum',
    network: 'mainnet',
    name: 'USDC Coin',
    symbol: 'USDC',
    icon: <USDCLogo />,
    id: 2,
    isActive: true,
  },
  {
    protocol: 'ethereum',
    network: 'mainnet',
    name: 'Tether',
    symbol: 'USDT',
    icon: <USDTLogo />,
    id: 3,
    isActive: true,
  },
  {
    protocol: 'cardano',
    network: 'mainnet',
    name: 'Cardano',
    symbol: 'ADA',
    icon: <ADALogo />,
    id: 4,
    isActive: true,
  },
  {
    protocol: 'litecoin',
    network: 'mainnet',
    name: 'Litecoin',
    symbol: 'LTC',
    icon: <LTCLogo />,
    id: 5,
    isActive: true,
  },
  {
    protocol: 'ethereum',
    network: 'polygon',
    name: 'MATIC',
    symbol: 'MATIC',
    icon: <MATIC />,
    id: 6,
    isActive: true,
  },
  {
    protocol: 'solana',
    network: 'mainnet',
    name: 'Solana',
    symbol: 'SOL',
    icon: <img src={solanaLogo} alt="solana" />,
    id: 7,
    isActive: true,
  },
  {
    protocol: 'bitcoin',
    network: 'mainnet',
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: <BTCLogo />,
    id: 8,
    isActive: true,
  },
];

export type FiatCurrency = {
  name: string;
  symbol: Fiat;
  sign: string;
  icon: any;
  id: number;
};

export const FIAT_CURRENCY_LIST: FiatCurrency[] = [
  {
    name: 'United States Dollar',
    symbol: 'USD',
    sign: '$',
    icon: <USDLogo />,
    id: 1,
  },
  { name: 'Euro', symbol: 'EUR', sign: '€', icon: <EURLogo />, id: 2 },
  {
    name: 'Great Britain Pound',
    symbol: 'GBP',
    sign: '£',
    icon: <GBPLogo />,
    id: 3,
  },
  {
    name: 'Arab Emirates Dirham',
    symbol: 'AED',
    sign: 'AED',
    icon: <AEDLogo />,
    id: 4,
  },
];
