import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useGetUserQuery } from '../../../services';
import { styled } from '@mui/material/styles';
import { getIconVerified } from '../../../helpers/getIconVerifiedHelper';

import { getIconLoginTypeHelper } from '../../../helpers/getIconLoginTypeHelper';

const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface VerifiedDetailsProps {
  title: string;
}

export const VerifiedDetails: React.FC<VerifiedDetailsProps> = ({ title }) => {
  const { data: userData } = useGetUserQuery();

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <StyledTypography>
        Email Verified {getIconVerified(userData?.emailVerified)}
      </StyledTypography>
      <StyledTypography>
        Phone Verified {getIconVerified(userData?.phoneVerified)}
      </StyledTypography>
      <StyledTypography>
        {userData?.isLegalEntity ? 'KYB' : 'KYC'} Verified{' '}
        {getIconVerified(userData?.kycVerified)}
      </StyledTypography>
      <StyledTypography>
        Address verified{' '}
        {getIconVerified(userData?.addressVerifiedObject?.addressVerified)}
      </StyledTypography>
      {userData?.isGoogleAuthenticatorSetUp && (
        <StyledTypography>
          Google Auth {getIconVerified(userData?.isGoogleAuthenticatorSetUp)}
        </StyledTypography>
      )}
      {userData?.twoFactorType && (
        <StyledTypography title={userData?.twoFactorType}>
          2FA type {getIconLoginTypeHelper(userData?.twoFactorType)}
        </StyledTypography>
      )}
    </Paper>
  );
};
