const assetSymbolHelper = (symbol: string) => {
  if (symbol === 'USD') {
    return '$';
  }
  if (symbol === 'EUR') {
    return '€';
  }
  if (symbol === 'GBP') {
    return '£';
  }
  if (symbol === 'AED') {
    return 'د.إ';
  }

  return symbol;
};

export { assetSymbolHelper };
