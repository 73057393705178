export interface IIbans {
  account: string;
  createdAt: string;
  id: number;
  isVerified: boolean;
  updatedAt: string;
  bankName: string;
  isIban: boolean;
}

export interface IRequestedPdf {
  createdAt: string;
  expireAt: null;
  id: number;
  isApproved: boolean;
  isValid: boolean;
  updatedAt: string;
}

export enum TwoFactorTypesEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  GOOGLE_AUTHENTICATOR = 'GOOGLE_AUTHENTICATOR',
}

export interface IAddress {
  subStreet: string;
  subStreetEn: string;
  street: string;
  streetEn: string;
  state: string;
  stateEn: string;
  town: string;
  townEn: string;
  postCode: string;
  country: string;
  formattedAddress: string;
}

export interface IExternalWallet {
  isVerified: boolean;
  id: number;
  address: string;
  Currency: {
    id: number;
    externalId: string;
  };
  createdAt: Date;
}

export interface IUser {
  provider: string;
  email: string;
  emailVerified: boolean;
  id?: number | string;
  lastName: string;
  name: string;
  phone: string;
  role?: string;
  kycVerified: boolean;
  vaultAccountId?: string | null;
  middleName: string;
  hasLeaveFeedback: boolean;
  Ibans: IIbans[];
  ExternalWallets: IExternalWallet[];
  isGoogleAuthenticatorSetUp: boolean;
  twoFactorType: TwoFactorTypesEnum;
  phoneVerified: boolean;
  RequestedPdf: IRequestedPdf;
  isWithdraw2faEnabled: boolean;
  isSendCrypto2faEnabled: boolean;
  isLogin2faEnabled: boolean;
  withdrawLimit: number;
  sendCryptoLimit: number;
  profileImage: string | null;
  isChangeSettings2faEnabled: boolean;
  twoFactorGoogleAuthenticatorSecret: string;
  Tier: { id: number; name: string };
  companyName: string | null;
  companyRegistrationNumber: string | null;
  countryCode: string;
  address: IAddress;
  isLegalEntity: boolean;
  accountNumber: string;
  defaultCurrency: string;
  addressVerifiedObject: IAddressVerification;
  kycVerifyObject: IKycVerifyObject;
}

export interface IAddressVerification {
  addressVerified: boolean;
  bannerText1: string;
  bannerText2: string;
}
export interface IKycVerifyObject {
  kycVerified: boolean;
  bannerText1: string;
  bannerText2: string;
}
