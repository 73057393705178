import { Paper, Typography, styled, Box, Button, SxProps } from '@mui/material';
import React, { FC } from 'react';
import { ArrowRight } from '@mui/icons-material';
import KYCLogo from 'assets/kyc-logo.png';
import { useCheckKYC } from 'hooks';
import { useGetUserQuery } from 'services';
import { Link } from 'react-router-dom';
import { theme } from '../../assets/theme';

import { PAGES_PATH } from '../../constants/spa-routes';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  width: '100%',
}));

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`;

type VerificationBannerProps = {
  sectionSx?: SxProps;
};

const DEFAULT_TITLE = 'Currently all operations are disabled.';

export const VerificationBanner: FC<VerificationBannerProps> = ({
  sectionSx,
}) => {
  const { isVerified, kycVerified } = useCheckKYC();

  const { data: userData } = useGetUserQuery();

  const DEFAULT_DESCRIPTION = `   To make them available you need to start or finish ${
    userData?.isLegalEntity ? 'KYB' : 'KYC'
  } verification process.`;

  const titleTextKyc = userData?.kycVerifyObject?.bannerText1 || DEFAULT_TITLE;
  const descriptionTextKyc =
    userData?.kycVerifyObject?.bannerText2 || DEFAULT_DESCRIPTION;
  const titleTextAddress =
    userData?.addressVerifiedObject?.bannerText1 || DEFAULT_TITLE;
  const descriptionTextAddress =
    userData?.addressVerifiedObject?.bannerText2 || DEFAULT_DESCRIPTION;

  return !isVerified ? (
    <Section
      elevation={4}
      sx={{
        mt: 4,
        padding: { sm: '24px', xs: '16px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        flexWrap: { sm: 'nowrap', xs: 'wrap' },
        justifyContent: { xs: 'center' },
        ...sectionSx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <img src={KYCLogo} width="95px" height="95px" alt="kyc" />
        <Typography
          sx={{ typography: { md: 'h5', xs: 'h6' } }}
          color="inherit"
          noWrap
          align="left"
          whiteSpace={'pre-wrap'}
        >
          {!kycVerified ? titleTextKyc : titleTextAddress}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{ typography: { md: 'h6', xs: 'h6' } }}
          color="inherit"
          noWrap
          align="left"
          whiteSpace={'pre-wrap'}
        >
          {!kycVerified ? descriptionTextKyc : descriptionTextAddress}
        </Typography>
        <Button variant="contained" sx={{ mt: 1 }}>
          <NavLink to={PAGES_PATH.USER_SUMSUB}>
            <Typography>Start verification</Typography>
            <ArrowRight />
          </NavLink>
        </Button>
      </Box>
    </Section>
  ) : null;
};
