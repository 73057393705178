// created by Artem
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { emailScheme, getErrorMessage } from 'utils';
import { Box, Link, TextField, Typography } from '@mui/material';
import {
  Link as NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useVerifyMutation } from 'services';
import { useServerError } from 'hooks';
import { AuthVerifyFormValues } from 'types/auth.interface';
import { PATH_REGISTER, StepsRegisterEnum } from '../../constants/spa-routes';
import { SendCodeButton } from '../../components/auth/send.code.button';
import { removeTimerSendCodeStorage } from '../../helpers/removeTimerSendCodeStorage';
import { TwoFactorTypesEnum } from '../../types/user.interface';
import { useSendVerificationCode } from '../../hooks/useSendVerificationCode';
import { LoadingButton } from '../../components/buttons_collection/loading.button';

type AuthVerifyEmailPageProps = {};
export const AuthVerifyEmailPage: FC<AuthVerifyEmailPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { sendPhoneCode } = useSendVerificationCode();

  const verifyType: 'login' | 'register' =
    location.state?.verifyType || 'login';

  const email: string =
    searchParams.get('email') || location.state?.email || '';

  const phone: string = location.state?.phone;

  const codeLabel =
    location.state?.twoFactorType === TwoFactorTypesEnum.SMS
      ? 'QCE Code'
      : 'Code';

  const twoFactorType: string =
    location.state?.twoFactorType ||
    (location.search.includes(StepsRegisterEnum.VERIFY_EMAIL) &&
      TwoFactorTypesEnum.EMAIL) ||
    (location.search.includes(StepsRegisterEnum.VERIFY_PHONE) &&
      TwoFactorTypesEnum.SMS);

  const code: string = searchParams.get('code') || location.state?.code || '';

  const [verify, { isError, error, isLoading }] = useVerifyMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<AuthVerifyFormValues>({
    defaultValues: { code, email, type: verifyType },
    resolver: yupResolver(
      object({
        code: string().required().min(6).label('Code'),
        email: emailScheme({ required: true }),
      })
    ),
  });

  const onSubmit: SubmitHandler<AuthVerifyFormValues> = (data) => {
    if (verifyType === 'login') {
      verify(data)
        .unwrap()
        .then(() => navigate('/'))
        .catch(() => null);
    } else {
      verify(data)
        .unwrap()
        .then(() => {
          sendPhoneCode({ phone });
          navigate(PATH_REGISTER.REGISTER_PHONE_VERIFY, {
            state: {
              email: email,
              phone: phone,
              verifyType: 'register',
            },
          });
        })
        .catch(() => null);
    }
  };

  useServerError({
    isError: !!errors.email?.message,
    error: {
      status: '400',
      error: errors.email?.message,
    },
  });

  useEffect(() => {
    if (isError) {
      setError('code', {
        message: getErrorMessage(error),
      });
    }
  }, [isError]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography component="h1" variant="h5">
        {`A verification code has been sent to your ${
          twoFactorType === TwoFactorTypesEnum.SMS
            ? `phone ${phone}`
            : `email ${email}`
        }`}
      </Typography>
      <TextField
        {...register('code')}
        error={!!errors?.code?.message}
        helperText={errors?.code?.message}
        margin="normal"
        required
        fullWidth
        id="code"
        label={codeLabel}
        name="code"
        autoComplete="off"
        autoFocus
      />
      {!email && (
        <TextField
          {...register('email')}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="off"
        />
      )}
      <SendCodeButton
        email={email}
        phone={phone}
        sendCodeType={twoFactorType}
      />
      <LoadingButton type="submit" isLoading={isLoading} sx={{ mt: 3, mb: 2 }}>
        Submit
      </LoadingButton>
      <Link
        onClick={removeTimerSendCodeStorage}
        variant="body2"
        component={NavLink}
        to="/auth"
      >
        Cancel
      </Link>
    </Box>
  );
};
