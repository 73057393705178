import { FC, useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
  Box,
} from '@mui/material';
import { setCurrency } from 'store/ui';
import { useAppDispatch, useAppSelector } from 'store';
import { DEF_CURRENCY_KEY, Fiat } from 'store/wallet/types';
import { useGetUserQuery, useSetDefaultCurrencyMutation } from '../../services';
import { FiatCurrency } from './currencies';
import { getFilteredCurrencyList } from './currencies-list';
import { useBalanceContext } from '../../context/BalanceContext';
import { useRatesContext } from '../../context/RatesContext';

type CurrenciesSelectorProps = {
  disabled?: boolean;
  currency?: Fiat;
};
export const CurrenciesSelector: FC<CurrenciesSelectorProps> = ({
  disabled = false,
  currency,
}) => {
  const { selectedCurrency } = useAppSelector((s) => s.uiReducer);

  const { sendGetBalanceMessage } = useBalanceContext();

  const [currencies, setCurrencies] = useState<FiatCurrency[]>([]);

  const [isInit, setIsInit] = useState<boolean>(true);

  const { sendGetRateMessage } = useRatesContext();

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const {
    data: userData,
    isLoading: isFetchingUserData,
    refetch,
  } = useGetUserQuery();

  const [setDefaultCurrency, { isSuccess: isSuccessSetDefaultCurrency }] =
    useSetDefaultCurrencyMutation();

  useEffect(() => {
    if (isSuccessSetDefaultCurrency) {
      refetch();
    }
  }, [isSuccessSetDefaultCurrency]);

  useEffect(() => {
    const filteredCurrencies = getFilteredCurrencyList(userData);
    setCurrencies(filteredCurrencies);
  }, [userData]);

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    const newCurrency = event.target.value as Fiat;

    sendGetBalanceMessage(newCurrency, true);

    sendGetRateMessage({
      toCurrency: newCurrency,
      isFuze: userData?.provider === 'FUZE',
    });

    setDefaultCurrency({ currency: newCurrency });

    dispatch(
      setCurrency({
        symbol: newCurrency,
      })
    );

    localStorage.setItem(DEF_CURRENCY_KEY, newCurrency);
  };

  useEffect(() => {
    if (currency) {
      dispatch(
        setCurrency({
          symbol: currency,
        })
      );
    }
  }, [currency]);

  useEffect(() => {
    if (userData?.defaultCurrency && isInit) {
      localStorage.setItem(DEF_CURRENCY_KEY, userData?.defaultCurrency);

      setIsInit(false);

      dispatch(
        setCurrency({
          symbol: userData?.defaultCurrency as Fiat,
        })
      );
    }
  }, [userData, isInit]);

  return currency ? (
    <Box>{currency}</Box>
  ) : (
    <Select
      value={selectedCurrency.symbol}
      onChange={(e) => handleCurrencyChange(e)}
      disabled={disabled || isFetchingUserData}
      size="small"
      sx={{
        transition: '0.2s',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      }}
    >
      {currencies.map((el) => (
        <MenuItem key={el.id} value={el.symbol}>
          {el.symbol}
        </MenuItem>
      ))}
    </Select>
  );
};
