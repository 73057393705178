import { Crypto } from '../store/wallet/types';
import { GetOriginalWalletBalanceResponse } from '../types/wallet.interface';

export const transformBalance = (balance: GetOriginalWalletBalanceResponse) => {
  const mappedCrypto = balance.cryptoBalances
    .map(({ asset, total, address, convertedTotal, symbol, rate }) => {
      return {
        [symbol as Crypto]: {
          total,
          address,
          value: asset,
          convertedTotal,
          rate,
        },
      };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as any;

  const mappedFiat = balance.fiatBalances
    .map(({ total, fiat, convertedTotal, rate }) => ({
      [fiat]: { total: total as string, convertedTotal, rate },
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as any;

  return {
    withdraw: mappedFiat,
    deposit: mappedCrypto,
    total: balance.total,
  };
};
