import { useEffect, useState } from 'react';
import { useSocketIO } from './useSocketIO';

export const getToken = () => localStorage.getItem('token') || '';

const API_SOCKET_URL = process.env.REACT_APP_API_URL || '';

export type GetExchangeRateMessageArgument = {
  toCurrency: string;
  isFuze: boolean;
};

type RateMessage = {
  from: string;
  to: string;
  rate: number;
};

export const useWSRates = () => {
  const [rates, setRates] = useState<{ [key: string]: any }>({});

  const { socket } = useSocketIO({
    url: API_SOCKET_URL,
    options: {
      transports: ['websocket'],
      extraHeaders: {
        'Access-Control-Allow-Origin': '*',
      },
    },
  });

  useEffect(() => {
    if (!socket?.connected) {
      socket?.connect();

      socket?.on('send-fiat-rate', (fiatRate: RateMessage) => {
        setRates((prevState) => ({
          ...prevState,
          [fiatRate.to]: fiatRate,
        }));
      });

      socket?.on('new-exchange-rate', (exchangeRate: RateMessage) => {
        setRates((prevState) => ({
          ...prevState,
          [exchangeRate.from]: exchangeRate.rate,
        }));
      });
    }

    return () => {
      socket?.off('send-fiat-rate');
      socket?.off('new-exchange-rate');

      socket?.disconnect();
    };
  }, [socket]);

  const sendGetRateMessage = (argument: GetExchangeRateMessageArgument) => {
    socket?.emit('get-fiat-rate', {
      fiat: argument.toCurrency,
      isFuze: argument.isFuze,
    });
    socket?.emit('get-exchange-rate', argument);
  };

  return { sendGetRateMessage, rates, isSocketInitialized: !!socket };
};
