import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthVerifyFormValues } from '../../../types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useGetUserQuery, useVerifyMutation } from '../../../services';
import { LoadingButton } from '@mui/lab';
import { useNotification, useServerError } from '../../../hooks';
import { TwoFactorTypesEnum } from '../../../types/user.interface';
import { SendCodeButton } from '../../auth/send.code.button';

type VerifyEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SUCCESS_MESSAGE = 'Your email has been successfully verified';

const VERIFY_TYPE = 'register';

export const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { data: userData } = useGetUserQuery();

  const { showNotification } = useNotification();

  const [verify, { isError, error, isLoading }] = useVerifyMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Pick<AuthVerifyFormValues, 'code'>>({
    defaultValues: { code: '' },
    resolver: yupResolver(
      object({
        code: string().required().min(6).label('Code'),
      })
    ),
  });

  const onSubmit: SubmitHandler<Pick<AuthVerifyFormValues, 'code'>> = (
    data
  ) => {
    if (userData) {
      verify({ ...data, email: userData.email, type: VERIFY_TYPE })
        .unwrap()
        .then(() => {
          showNotification(SUCCESS_MESSAGE, 'success');
          onClose();
        })
        .catch(() => null);
    }
  };

  useServerError({ isError, error });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        width: '100%',
        margin: 'auto',
        '.MuiPaper-root': { width: '100%' },
      }}
    >
      <DialogTitle>Verify email</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <Typography variant="subtitle1">
            {`A verification code has been sent to your email: ${userData?.email}`}
          </Typography>
          <TextField
            {...register('code')}
            error={!!errors?.code?.message}
            helperText={errors?.code?.message}
            margin="normal"
            required
            fullWidth
            id="code"
            label="Code"
            name="code"
            autoComplete="off"
            autoFocus
          />
        </Box>
        <Box>
          <SendCodeButton
            email={userData?.email}
            withDescription={false}
            sendCodeType={TwoFactorTypesEnum.EMAIL}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={Object.keys(errors).length !== 0 || !isDirty}
          id="verifyEmailSubmitButton"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
