import { FC } from 'react';

import 'react-phone-input-2/lib/material.css';
import Paper from '@mui/material/Paper';
import { MoreAssetsForm } from '../../../components';

type MoreAssetsProps = {};
export const MoreAssets: FC<MoreAssetsProps> = () => {
  return (
    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
      <MoreAssetsForm />
    </Paper>
  );
};
