import { Box, Button, Grid, Skeleton, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { CurrencyExchange, Payment, Send } from '@mui/icons-material';
import { Fiat } from 'store/wallet/types';
import { useAppSelector } from 'store';
import { formatPrice } from 'utils';

const OrderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

type FiatOrderBlockProps = {
  onSend: (symbol: string) => void;
  onReceiveFiat: (symbol: string) => void;
  onConvertFiat: (symbol: string) => void;
  coinOptions: any;
};

const columnStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const FiatOrderBlock: FC<FiatOrderBlockProps> = ({
  onSend,
  coinOptions,
  onReceiveFiat,
  onConvertFiat,
}) => {
  const { selectedCurrency } = useAppSelector((s) => s.uiReducer);
  const { balance, isLoadingBalance } = useAppSelector(
    (state) => state.accountReducer
  );

  const accountBalance: number =
    balance?.withdraw?.[coinOptions?.symbol as Fiat]?.total || 0;

  const accountConvertedBalance: number =
    Number(balance?.withdraw?.[coinOptions?.symbol as Fiat]?.convertedTotal) ||
    0;

  const coinRate = formatPrice(coinOptions?.rateDetails?.rate, 2);

  return (
    <OrderWrapper sx={{ flexDirection: { lg: 'row', xs: 'column' } }}>
      <Grid
        container
        alignItems="center"
        sx={{
          width: { lg: '70%', xs: '100%' },
          gap: { md: '0', lg: '0', xs: '20px' },
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          minWidth={150}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }} mr={1}>
            <Box width={30} height={30} mr={1}>
              {coinOptions.icon}
            </Box>
            <Typography mr={1} fontWeight="bold">
              {coinOptions.symbol}
            </Typography>
            <Typography variant="body2" component="p">
              {coinOptions.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            1 {selectedCurrency.symbol} =&nbsp;
            {!!coinOptions?.rateDetails &&
            selectedCurrency.symbol === coinOptions?.rateDetails?.from ? (
              coinRate
            ) : (
              <Skeleton variant="text" sx={{ width: '48px' }} />
            )}
            &nbsp;
            {coinOptions.symbol}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
        >
          <Box sx={columnStyles}>
            <Typography textAlign="left">Balance</Typography>
            <Typography mt={1}>
              {!accountBalance || isLoadingBalance ? (
                <Skeleton variant="text" sx={{ width: '160px' }} />
              ) : (
                Number(accountBalance).toFixed(2) +
                ' ' +
                coinOptions.sign +
                ' ≃ ' +
                formatPrice(accountConvertedBalance, 2) +
                ' ' +
                selectedCurrency.symbol
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: { lg: '30%', xs: '100%' },
          gap: { lg: '30px', xs: '20px' },
        }}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            onClick={() => onReceiveFiat(coinOptions?.symbol)}
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Payment />
            <Typography>Receive</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => onSend(coinOptions?.symbol)}
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 90,
            }}
          >
            <Send />
            <Typography>Send</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => onConvertFiat(coinOptions?.symbol)}
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CurrencyExchange />
            <Typography>Convert</Typography>
          </Button>
        </Grid>
      </Grid>
    </OrderWrapper>
  );
};
