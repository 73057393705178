import { FC } from 'react';
import { NotConnectedModal } from './not_connected/not_connected_modal';
import { useAppDispatch, useAppSelector } from 'store';
import { ModalNames, setModalState } from 'store/ui';
import { ReviewLeftModal } from './review/review.left.modal';
import { ReviewListModal } from './review/review.list.modal';
import { WalletDepositModal } from './wallet_deposit';
import { GoogleLogin } from './google_login';
import { ChangeLoginType } from './change_login_type';
import { SendCryptoModal } from './send_crypto';
import { SendFiatModal } from './send_fiat';
import { AccountStatement } from './account_statement';
import { AvatarUploadModal } from './avatar_upload_modal';
import { ReceiveFiatModal } from './receive_fiat_modal';
import { ConvertFiat } from './convert_fiat';
import { MoreAssetsModal } from './more_assets_modal';
import { VerifyEmailModal } from './verify_email';

export const ModalManager: FC = () => {
  const dispatch = useAppDispatch();
  const { modalsVisibility, selectedSymbol } = useAppSelector(
    (s) => s.uiReducer
  );

  const onModalClose = (name: ModalNames) => {
    dispatch(setModalState({ visible: false, name, symbol: '' }));
  };

  return (
    <>
      {modalsVisibility.deposit && (
        <WalletDepositModal
          isOpen={modalsVisibility.deposit}
          onClose={() => onModalClose('deposit')}
          selectedSymbol={selectedSymbol}
        />
      )}
      <ReviewLeftModal
        open={modalsVisibility.reviews}
        onClose={() => onModalClose('reviews')}
      />
      <ReviewListModal
        open={modalsVisibility.reviewsList}
        onClose={() => onModalClose('reviewsList')}
      />
      <NotConnectedModal
        open={modalsVisibility.notConnected}
        onClose={() => onModalClose('notConnected')}
      />
      <GoogleLogin
        open={modalsVisibility.googleLogin}
        onClose={() => onModalClose('googleLogin')}
      />
      <ChangeLoginType
        open={modalsVisibility.loginType}
        onClose={() => onModalClose('loginType')}
      />
      <AccountStatement
        open={modalsVisibility.accountStatement}
        onClose={() => onModalClose('accountStatement')}
      />
      <AvatarUploadModal
        open={modalsVisibility.avatarUploadModal}
        onClose={() => onModalClose('avatarUploadModal')}
      />
      {modalsVisibility.sendCrypto && (
        <SendCryptoModal
          isOpen={modalsVisibility.sendCrypto}
          onClose={() => onModalClose('sendCrypto')}
          selectedSymbol={selectedSymbol}
        />
      )}
      {modalsVisibility.sendFiat && (
        <SendFiatModal
          isOpen={modalsVisibility.sendFiat}
          onClose={() => onModalClose('sendFiat')}
          selectedSymbol={selectedSymbol}
        />
      )}
      {modalsVisibility.receiveFiat && (
        <ReceiveFiatModal
          isOpen={modalsVisibility.receiveFiat}
          onClose={() => onModalClose('receiveFiat')}
          selectedSymbol={selectedSymbol}
        />
      )}
      {modalsVisibility.convertFiat && (
        <ConvertFiat
          isOpen={modalsVisibility.convertFiat}
          onClose={() => onModalClose('convertFiat')}
          selectedSymbol={selectedSymbol}
        />
      )}
      {modalsVisibility.moreAssets && (
        <MoreAssetsModal
          isOpen={modalsVisibility.moreAssets}
          onClose={() => onModalClose('moreAssets')}
        />
      )}
      {modalsVisibility.verifyEmail && (
        <VerifyEmailModal
          isOpen={modalsVisibility.verifyEmail}
          onClose={() => onModalClose('verifyEmail')}
        />
      )}
    </>
  );
};
