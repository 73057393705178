import { useState } from 'react';
import { Crypto } from '../store/wallet/types';

export const usePricesAndCrypto = () => {
  const [selectedCrypto, setSelectedCrypto] = useState<{
    symbol: Crypto | string;
    nativeAsset: string;
  }>({ symbol: '', nativeAsset: '' });

  return {
    selectedCrypto,
    setSelectedCrypto,
  };
};
