import React, { useState } from 'react';
import { Box } from '@mui/material';

type CurrencyIconComponentType = {
  iconString: string | JSX.Element;
  height?: number | string;
  width?: number | string;
};
export const CurrencyIconComponent = ({
  iconString,
  height = 30,
  width = 30,
}: CurrencyIconComponentType) => {
  const isSVG =
    typeof iconString === 'string' && /<svg[\s\S]*<\/svg>/i.test(iconString);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  if (iconString) {
    if (React.isValidElement(iconString)) {
      return iconString;
    } else if (isSVG) {
      return (
        <Box
          dangerouslySetInnerHTML={{ __html: iconString as string }}
          height={height}
          width={width}
        />
      );
    } else {
      return (
        <img
          src={iconString as string}
          alt="Icon"
          height={height}
          width={width}
          onLoad={handleImageLoad}
          style={{ display: imageLoaded ? 'block' : 'none' }}
        />
      );
    }
  } else return <></>;
};
