import { createBrowserRouter } from 'react-router-dom';
import { AppLayout, AuthLayout } from 'layouts';
import {
  AuthVerifyEmailPage,
  ForgotPage,
  UserProfile,
  ExchangePage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  OperationHistoryPage,
  DashboardPage,
  AccountPage,
  PrivacyPolicyPage,
  NotFoundPage,
  GoogleAuthPage,
  Settings,
} from 'pages';
import { AuthGuard } from 'components/auth_guard/auth_guard';

export const routes = createBrowserRouter([
  {
    path: 'auth',
    element: (
      <AuthGuard>
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      { path: '', element: <LoginPage /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'forgot', element: <ForgotPage /> },
      { path: 'reset-password', element: <ResetPasswordPage /> },
      { path: 'register-verify', element: <AuthVerifyEmailPage /> },
      { path: 'login-verify', element: <AuthVerifyEmailPage /> },
      { path: 'google-verify', element: <GoogleAuthPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
  {
    element: <AuthLayout customContainerProps={{ maxWidth: 'lg' }} />,
    children: [{ path: 'privacy-policy', element: <PrivacyPolicyPage /> }],
  },
  {
    path: '',
    element: <AppLayout />,
    children: [
      { path: '', element: <DashboardPage /> },
      { path: 'dashboard', element: <ExchangePage /> },
      {
        path: 'user-profile',
        element: <UserProfile />,
        children: [
          {
            path: 'sumsub',
            element: <UserProfile />,
          },
          {
            path: 'password-change',
            element: <UserProfile />,
          },
          {
            path: 'profile',
            element: <UserProfile />,
          },
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'more-assets',
            element: <Settings />,
          },
          {
            path: 'notifications',
            element: <UserProfile />,
          },
        ],
      },
      { path: 'transaction-history', element: <OperationHistoryPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
  { path: '*', element: <NotFoundPage /> },
]);
