import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  Autocomplete,
} from '@mui/material';
import { useNotification, useServerError } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetUserQuery, useSendFiatMutation } from 'services';
import { useAppDispatch, useAppSelector } from 'store';
import { setWithdrawalWaiting } from 'store/ui';
import { Fiat } from 'store/wallet/types';
import { TwoFaModalComponent } from 'shared';
import { stringScheme } from '../../../utils';
import { FiatCurrency } from '../../../shared/currencies/currencies';
import { getFilteredCurrencyList } from 'shared/currencies/currencies-list';

type SendFiatModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedSymbol?: string;
};

type SendFiatFormData = {
  amount: number;
  currency: string;
  iban: { label: string; id: string; isIban: boolean };
};

export const SendFiatModal: FC<SendFiatModalProps> = ({
  isOpen,
  onClose,
  selectedSymbol,
}) => {
  const [
    withdraw,
    {
      error: errorWithdraw,
      isError: isErrorWithdraw,
      isSuccess: isSuccessWithdraw,
    },
  ] = useSendFiatMutation(undefined);

  useServerError({
    isError: isErrorWithdraw,
    error: errorWithdraw,
  });

  const dispatch = useAppDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState<Fiat>(
    selectedSymbol as Fiat
  );

  const { balance } = useAppSelector((state) => state.accountReducer);

  const { data: userData } = useGetUserQuery();

  const [currencies, setCurrencies] = useState<FiatCurrency[]>([]);

  useEffect(() => {
    const filteredCurrencies = getFilteredCurrencyList(userData);
    setCurrencies(filteredCurrencies);
  }, [userData]);

  const userBalance =
    (balance?.withdraw?.[selectedCurrency]?.total as number) || 0;

  const { showNotification } = useNotification();
  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .transform((value) => (isNaN(value) ? undefined : value))
      .default(undefined)
      .positive('Amount must be a positive number')
      .required('Amount is required')
      .max(
        userBalance,
        `Do not send more money than ${userBalance} ${selectedCurrency}.`
      ),
    iban: yup
      .object({
        label: stringScheme({ required: true })
          .label('Bank account')
          .when('isIban', (isIban, schema) => {
            if (isIban[0]) {
              return schema.label('IBAN');
            }
            return schema.label('Bank account');
          }),
        id: stringScheme({ required: false }),
        isIban: stringScheme({ required: false }),
      })
      .required()
      .label('Bank account'),
  });

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<SendFiatFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const watchAmount = watch('amount');

  const handleWithdrawal = async (data: SendFiatFormData, code: string) => {
    const { amount, iban } = data;

    await withdraw({
      fiat: selectedCurrency,
      total: Number(amount),
      ibanId: Number(iban.id),
      ...(!code ? {} : { code }),
    });
    dispatch(setWithdrawalWaiting({ status: true }));

    onClose();
  };

  useEffect(() => {
    if (isSuccessWithdraw) {
      showNotification(
        `Withdrawal request of ${watchAmount} ${selectedCurrency} was successful.`
      );
    }
  }, [isSuccessWithdraw]);
  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setSelectedCurrency(event.target.value as Fiat);
    trigger();
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    handleSubmit(async (data) => await handleWithdrawal(data, code))();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  };

  const withdrawAmount = watch('amount');
  const withdrawIban = watch('iban');

  const isMoreThenLimit = useMemo(() => {
    const limit = Number(userData?.withdrawLimit || 0);
    return limit === 0 ? false : limit < withdrawAmount;
  }, [withdrawAmount]);

  const skip2fa = useMemo(() => {
    if (userData?.isWithdraw2faEnabled) {
      return false;
    }

    return !(!userData?.isWithdraw2faEnabled && isMoreThenLimit);
  }, [isMoreThenLimit, userData?.isWithdraw2faEnabled]);

  const verifiedBankAccounts = userData?.Ibans?.filter(
    (bank) => bank.isVerified
  );

  const bankAccountsValue = useMemo(() => {
    return (
      verifiedBankAccounts?.map((item) => {
        return {
          label: `${item?.bankName}, ${item?.account}`,
          id: item?.id,
          isIban: item?.isIban,
        };
      }) || []
    );
  }, [verifiedBankAccounts, userData]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Withdraw Fiat</DialogTitle>
      <DialogContent>
        <form>
          <Controller
            control={control}
            name="amount"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                margin="normal"
                label="Amount"
                type="number"
                required
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="currency"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel id="currency-label">Currency</InputLabel>
                <Select
                  labelId="currency-label"
                  id="currency-select"
                  label="Currency"
                  {...register('currency', {
                    required: 'Currency is required',
                  })}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleCurrencyChange(e);
                  }}
                  error={!!error?.message}
                  defaultValue={selectedSymbol}
                >
                  {currencies.map((el) => (
                    <MenuItem key={el.id} value={el.symbol}>
                      {el.symbol}
                    </MenuItem>
                  ))}
                  {/* <MenuItem disabled value="AED">
                AED
              </MenuItem> */}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="iban"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Autocomplete
                id="combo-box-demo"
                onChange={(event, val) => onChange(val)}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label && option.id === value.id
                }
                options={bankAccountsValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error?.message || !!errors?.iban?.label?.message}
                    helperText={error?.message || errors?.iban?.label?.message}
                    fullWidth
                    required
                    margin="normal"
                    label={withdrawIban?.isIban ? 'IBAN' : 'Bank account'}
                  />
                )}
              />
            )}
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <TwoFaModalComponent
              disabled={Object.keys(errors).length !== 0 || !isDirty}
              type="button"
              variant="contained"
              color="primary"
              onComplete={confirm2FaHandle}
              skip={skip2fa}
            >
              Send
            </TwoFaModalComponent>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
