import {
  DepositInfo,
  EventTypeEnum,
  Notification as INotification,
  NotificationData,
  SendCryptoInfo,
  SendFiatInfo,
} from '../types/wallet.interface';
import {
  AccountBalance,
  AttachMoney,
  CurrencyExchange,
  Payment,
  Send,
  Wallet,
} from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

export const getIcon = (notification: INotification) => {
  switch (notification.type) {
    case EventTypeEnum.EXCHANGE: {
      return (
        <CurrencyExchange htmlColor={!notification.success ? 'red' : 'green'} />
      );
    }
    case EventTypeEnum.DEPOSIT: {
      return <Payment htmlColor={!notification.success ? 'red' : 'green'} />;
    }
    case EventTypeEnum.SEND_CRYPTO_AMOUNT:
    case EventTypeEnum.SEND_FIAT_AMOUNT:
    case EventTypeEnum.WITHDRAW_CRYPTO:
    case EventTypeEnum.WITHDRAW_SUBMIT:
    case EventTypeEnum.WITHDRAW_FIAT: {
      return <Send htmlColor={!notification.success ? 'red' : 'green'} />;
    }
  }
};

export const getContent = (notification: INotification) => {
  const info =
    typeof notification?.additionalInfo === 'string'
      ? (JSON.parse(notification?.additionalInfo || '{}') as NotificationData)
      : notification?.additionalInfo;

  switch (notification?.type) {
    case EventTypeEnum.EXCHANGE: {
      return (
        <Grid container sx={{ flexDirection: 'column' }}>
          <Grid item>
            <Typography
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                lineHeight: 1.25,
              }}
            >
              <AttachMoney
                color="primary"
                sx={{ marginRight: 1, fontSize: '1.25rem' }}
              />{' '}
              {(info as SendCryptoInfo)?.assetAmount}{' '}
              {(info as SendCryptoInfo)?.asset}
            </Typography>
          </Grid>
          {(info as SendCryptoInfo)?.walletAddress && (
            <Grid item>
              <Typography
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  lineHeight: 1.25,
                }}
              >
                <Wallet color="primary" sx={{ fontSize: '1.25rem' }} />{' '}
                {(info as SendCryptoInfo)?.walletAddress}
              </Typography>
            </Grid>
          )}
        </Grid>
      );
    }
    case EventTypeEnum.DEPOSIT: {
      return (
        <Typography
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            lineHeight: 1.25,
          }}
        >
          <AttachMoney
            color="primary"
            sx={{ marginRight: 1, fontSize: '1.25rem' }}
          />{' '}
          {(info as DepositInfo)?.assetAmount?.amount}{' '}
          {(info as DepositInfo)?.asset}
        </Typography>
      );
    }
    case EventTypeEnum.SEND_CRYPTO_AMOUNT:
      return (
        <Grid container sx={{ flexDirection: 'column' }}>
          <Grid item>
            <Typography
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                lineHeight: 1.25,
              }}
            >
              <AttachMoney
                color="primary"
                sx={{ marginRight: 1, fontSize: '1.25rem' }}
              />{' '}
              {(info as SendCryptoInfo)?.assetAmount}{' '}
              {(info as SendCryptoInfo)?.asset}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{ display: 'flex', alignItems: 'center', lineHeight: 1.25 }}
            >
              <Wallet
                color="primary"
                sx={{ marginRight: 1, fontSize: '1.25rem' }}
              />{' '}
              {(info as SendCryptoInfo)?.walletAddress}
            </Typography>
          </Grid>
        </Grid>
      );
    case EventTypeEnum.SEND_FIAT_AMOUNT:
      return info ? (
        <Grid container sx={{ flexDirection: 'column' }}>
          <Grid item>
            <Typography
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                lineHeight: 1.25,
              }}
            >
              <AttachMoney
                color="primary"
                sx={{ marginRight: 1, fontSize: '1.25rem' }}
              />
              {(info as SendFiatInfo)?.amount} {(info as SendFiatInfo)?.fiat}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                lineHeight: 1.25,
              }}
            >
              <AccountBalance
                color="primary"
                sx={{ marginRight: 1, fontSize: '1.25rem' }}
              />{' '}
              {(info as SendFiatInfo)?.iban}
            </Typography>
          </Grid>
        </Grid>
      ) : null;
  }
};
