import { Container } from 'shared/container/container';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { PAGES_PATH } from '../../constants/spa-routes';

const NumbersTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  'span:nth-of-type(even)': {
    color: theme.palette.secondary.main,
  },
  'span:nth-of-type(odd)': {
    color: theme.palette.primary.main,
  },
}));

export const NotFoundPage = () => (
  <Container
    sx={{
      height: '80vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{ justifyContent: 'center', alignItems: 'center', maxWidth: '400px' }}
    >
      <NumbersTypography variant="h1">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </NumbersTypography>
      <Typography variant="h4" color="primary.main" mt={1}>
        Oops, wrong turn...
      </Typography>
      <Typography mt={1}>This page doesn't exist or was removed.</Typography>
      <Typography>We suggest you back to home.</Typography>
      <Button
        variant="contained"
        component={Link}
        to={PAGES_PATH.ROOT}
        sx={{ marginTop: '16px' }}
      >
        Go back to home
      </Button>
    </Box>
  </Container>
);
