import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useWSBalance } from '../hooks';
import { useGetUserQuery } from '../services';
import { useAppSelector } from '../store';
import { Fiat } from '../store/wallet/types';

type BalanceContextValues = {
  sendGetBalanceMessage: (symbol: Fiat, changeStatus?: boolean) => void | null;
};

const BalanceContext = createContext<BalanceContextValues>({
  sendGetBalanceMessage: () => {},
});

const INTERVAL = 10000;

export const useBalanceContext = () => {
  return useContext(BalanceContext);
};

export const BalanceProvider = ({ children }: { children: ReactNode }) => {
  const { balance } = useAppSelector((state) => state.accountReducer);

  const { data: userData } = useGetUserQuery();

  const { sendGetBalanceMessage, isSocketInitialized } = useWSBalance();

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (userData && !balance && isSocketInitialized) {
      sendGetBalanceMessage(userData.defaultCurrency as Fiat, true);
    }

    if (userData && !!balance) {
      interval = setInterval(
        () => sendGetBalanceMessage(userData.defaultCurrency as Fiat),
        INTERVAL
      );
    }

    return () => {
      clearInterval(interval);
    };
  }, [userData, balance, isSocketInitialized]);

  return (
    <BalanceContext.Provider value={{ sendGetBalanceMessage }}>
      {children}
    </BalanceContext.Provider>
  );
};
