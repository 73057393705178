import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Box,
  DialogActions,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from '@mui/material';

import { useNotification, useServerError } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { TwoFaModalComponent } from 'shared';
import { useAppSelector } from 'store';
import { Crypto } from 'store/wallet/types';
import {
  useCreateWithdrawTransactionMutation,
  useGetCryptoCurrencyAssetsQuery,
  useGetCryptoWithdrawNetworkFeeQuery,
  useGetSendCryptoSettingsQuery,
  useGetUserQuery,
} from 'services';
import { CurrencyIconComponent } from '../../currency_image';

type SendCryptoModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  withCloseButton?: boolean;
  sendBtnFullWidth?: boolean;
  selectedSymbol?: string | Crypto;
  setWalletSelectedCurrency?: Dispatch<SetStateAction<string>>;
};

type SendCryptoFormData = {
  amount: number;
  address: string | null;
};

export const WithdrawCryptoForm: FC<SendCryptoModalProps> = ({
  onClose = () => {},
  withCloseButton = false,
  sendBtnFullWidth = false,
  selectedSymbol = 'USDT',
  setWalletSelectedCurrency = () => {},
}) => {
  const [
    createWithdrawTransaction,
    {
      isError: isErrorCreateWithdraw,
      error: errorCreateWithdraw,
      isSuccess: isSuccessCreateWithdraw,
      isLoading: isLoadingCreateWithdraw,
    },
  ] = useCreateWithdrawTransactionMutation();

  const { data: sendSettings } = useGetSendCryptoSettingsQuery();

  useEffect(() => {
    if (isSuccessCreateWithdraw) {
      onClose();
      showNotification('Withdrawal request was created!', 'success');
    }
  }, [isSuccessCreateWithdraw]);

  const { showNotification } = useNotification();

  useServerError({
    isError: isErrorCreateWithdraw,
    error: errorCreateWithdraw,
  });

  const { balance } = useAppSelector((state) => state.accountReducer);

  const [selectedAsset, setSelectedAsset] = useState<Crypto | string>(
    selectedSymbol
  );
  const [selectedWalletAddress, setSelectedWalletAddress] = useState<
    string | undefined
  >(undefined);

  const { data: userData } = useGetUserQuery();
  const { data: cryptoCurrency } = useGetCryptoCurrencyAssetsQuery({});

  const ExternalWallets = userData?.ExternalWallets ?? [];

  const selectedCryptoCurrency = cryptoCurrency?.assets.find(
    (el) => el.isActive && el.symbol === selectedAsset
  );

  const currencyOptions =
    cryptoCurrency?.assets
      .filter((el) => el.isActive)
      .map((el) => {
        const isActive = selectedCryptoCurrency?.symbol === el.symbol;

        return {
          isSelected: isActive,
          value: el.symbol,
          label: el.symbol,
          icon: el.icon,
        };
      }) ?? [];

  const selectedCurrencyExternalWallets = ExternalWallets.filter(
    (wallet) => wallet.Currency.id === selectedCryptoCurrency?.id
  );

  useEffect(() => {
    setValue('address', null);
    setSelectedWalletAddress(undefined);
  }, [selectedCryptoCurrency?.id]);

  const userBalance =
    (balance?.deposit?.[selectedAsset as Crypto]?.total as number) || 0;

  const handleAssetChange = (event: SelectChangeEvent) => {
    setSelectedAsset(event.target.value as Crypto);
    setWalletSelectedCurrency(event.target.value as Crypto);
  };

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .transform((value) => (isNaN(value) ? undefined : value))
      .default(undefined)
      .positive('Amount must be a positive number')
      .required('Amount is required')
      .min(
        sendSettings?.minAmount || 0,
        `Minimum value you can send is ${sendSettings?.minAmount} ${selectedAsset}`
      )
      .max(
        userBalance,
        `Do not send more money than ${userBalance} ${selectedAsset}.`
      ),
    address: yup.string().required('Wallet address is required'),
  });

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<SendCryptoFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const targetWallet = selectedCurrencyExternalWallets.find(
    (item) => item.address === selectedWalletAddress
  );

  const assetId = targetWallet?.Currency.externalId;

  const sendCryptoAmount = watch('amount');

  const onSubmit = () => {
    const data = getValues();

    const walletId = targetWallet?.id;

    if (!walletId || !assetId) {
      return showNotification('Unexpected error: cannot find wallet', 'error');
    }

    createWithdrawTransaction({
      amount: +data.amount,
      walletId,
      assetId,
    })
      .unwrap()
      .then(() => null)
      .catch(() => null);
  };

  const skipNetworkFeeRequest = !assetId || !sendCryptoAmount;

  const {
    data: networkFeeData,
    isFetching: fetchingNetworkFee,
    isError: isNetworkFeeError,
    error: networkFeeError,
  } = useGetCryptoWithdrawNetworkFeeQuery(
    {
      assetId: assetId!,
      amount: +sendCryptoAmount,
    },
    { skip: skipNetworkFeeRequest }
  );

  useServerError({ isError: isNetworkFeeError, error: networkFeeError });

  const onWalletAddressChange = (event: SelectChangeEvent) => {
    setSelectedWalletAddress(event.target.value as string);
    setValue('address', event.target.value as string);
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;
    onSubmit();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  };

  const isMoreThenLimit = useMemo(() => {
    const limit = Number(userData?.sendCryptoLimit || 0);
    return limit === 0 ? false : limit < sendCryptoAmount;
  }, [sendCryptoAmount]);

  const skip2fa = useMemo(() => {
    if (userData?.isSendCrypto2faEnabled) {
      return false;
    }

    return !(!userData?.isSendCrypto2faEnabled && isMoreThenLimit);
  }, [isMoreThenLimit, userData?.isSendCrypto2faEnabled]);

  const showFeeDetails = sendSettings?.showNetworkFee && !skipNetworkFeeRequest;

  return (
    <form>
      <TextField
        fullWidth
        margin="normal"
        label="Amount"
        type="number"
        required
        {...register('amount', { required: 'Amount is required' })}
        onKeyDown={handleKeyDown}
        InputProps={{
          inputProps: { min: 0 },
        }}
        error={!!errors.amount}
        helperText={errors.amount?.message}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="from-currency-label">From Currency</InputLabel>
        <Select
          label="From Currency"
          labelId="from-currency-label"
          id="from-currency-select"
          value={selectedAsset}
          onChange={handleAssetChange}
          renderValue={(selected) =>
            cryptoCurrency?.assets
              .filter((el) => el.isActive)
              .filter((ec) => ec.symbol === selected)
              .map((ec) => (
                <Box
                  key={ec.id}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Box width={30} height={30} mr={1}>
                    <CurrencyIconComponent iconString={ec.icon} />
                  </Box>
                  {ec.symbol}
                </Box>
              ))
          }
        >
          {currencyOptions.map((el) => (
            <MenuItem
              key={el.value}
              value={el.value}
              selected={el.isSelected}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box width={30} height={30} mr={1}>
                <CurrencyIconComponent iconString={el.icon} />
              </Box>
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="wallet-address-label">Wallet address</InputLabel>
        <Select
          label="Wallet address"
          labelId="wallet-address-label"
          id="wallet-address"
          value={selectedWalletAddress}
          defaultValue={selectedWalletAddress}
          placeholder="Add external wallet address in settings"
          onChange={onWalletAddressChange}
        >
          {selectedCurrencyExternalWallets?.map((wallet) => (
            <MenuItem
              key={wallet.id}
              value={wallet.address}
              disabled={!wallet.isVerified}
              selected={selectedWalletAddress === wallet.address}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {wallet.address}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showFeeDetails && (
        <Typography
          variant="body2"
          color="textSecondary"
          gutterBottom
          sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          Network fee:{' '}
          {fetchingNetworkFee ? (
            <Skeleton variant="text" sx={{ width: '50px' }} />
          ) : (
            networkFeeData?.networkFee
          )}{' '}
          {selectedCryptoCurrency?.symbol}
        </Typography>
      )}

      <DialogActions>
        {withCloseButton && (
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
        )}
        <TwoFaModalComponent
          disabled={
            Object.keys(errors).length !== 0 ||
            !isDirty ||
            isLoadingCreateWithdraw ||
            !selectedWalletAddress
          }
          type="button"
          variant="contained"
          color="primary"
          onComplete={confirm2FaHandle}
          skip={skip2fa}
          fullWidth={sendBtnFullWidth}
        >
          Send Crypto
        </TwoFaModalComponent>
      </DialogActions>
    </form>
  );
};
