import { FC } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

type ParseHtmlMarkdownProps = {
  content?: string | null;
};

export const ParseHtmlMarkdown: FC<ParseHtmlMarkdownProps> = ({ content }) => (
  <>{content && parse(DOMPurify.sanitize(content))}</>
);
