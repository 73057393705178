import {
  useGetAllNotificationsQuery,
  useGetNotificationFilterValuesQuery,
} from '../../../services';
import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { transformDate } from '../../../helpers/dateTransformHelper';
import { DateFormatEnum } from '../../../types/dateTimeFormatOption';
import { getContent, getIcon } from '../../../utils';
import { NoItemsWrapper } from '../../../shared';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 0;

enum StatusChipLabelEnum {
  Success = 'Completed',
  Failed = 'Failed',
}

const TYPE_SELECT_ALL_VALUE = 'ALL';

export const Notifications = () => {
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [status, setStatus] = useState<number | null>(null);
  const [type, setType] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  const { data: notifications, isLoading } = useGetAllNotificationsQuery({
    limit: limit,
    page: page + 1,
    status: status || undefined,
    type: type || undefined,
    dateFrom: dateFrom ? dayjs(dateFrom).toISOString() : undefined,
    dateTo: dateTo ? dayjs(dateTo).toISOString() : undefined,
  });

  const { data: filterValues } = useGetNotificationFilterValuesQuery();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(DEFAULT_PAGE);

    setLimit(parseInt(event.target.value, 10));
  };

  const handleStatusChange: SelectInputProps['onChange'] = ({ target }) => {
    setPage(DEFAULT_PAGE);

    setStatus(target.value as number);
  };

  const handleTypeChange: SelectInputProps['onChange'] = ({ target }) => {
    setPage(DEFAULT_PAGE);

    if (target.value === TYPE_SELECT_ALL_VALUE) {
      setType('');

      return;
    }

    setType(`${target.value}`);
  };

  const handleDateFromChange = (value: string | null) => {
    setPage(DEFAULT_PAGE);

    if (!value) {
      setDateFrom('');

      return;
    }

    setDateFrom(value);
  };

  const handleDateToChange = (value: string | null) => {
    setPage(DEFAULT_PAGE);

    if (!value) {
      setDateTo('');

      return;
    }

    setDateTo(value);
  };

  const statusOptions = useMemo(() => {
    if (!filterValues) return [];

    return Object.entries(filterValues.status)
      .map((item) => ({
        label: item[0].charAt(0).toUpperCase() + item[0].slice(1).toLowerCase(),
        value: item[1],
      }))
      .sort((a, b) => b.value - a.value);
  }, [filterValues]);

  const typeOptions = useMemo(() => {
    if (!filterValues) return [];

    const options = Object.values(filterValues.type);
    options.unshift(TYPE_SELECT_ALL_VALUE);

    return options.map((item) => ({
      label:
        item.charAt(0).toUpperCase() +
        item.slice(1).replaceAll('_', ' ').toLowerCase(),
      value: item,
    }));
  }, [filterValues]);

  return (
    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 } }}>
      <Typography component="h1" variant="h4" align="center">
        Notifications
      </Typography>
      {filterValues && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth size="small" sx={{ minWidth: 130 }}>
              <InputLabel id="filter-by-status-label">
                Filter by Status
              </InputLabel>
              <Select
                labelId="filter-by-status-label"
                onChange={handleStatusChange}
                defaultValue={statusOptions[0]?.value}
                label="Filter by Status"
              >
                {statusOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth size="small" sx={{ minWidth: 130 }}>
              <InputLabel id="filter-by-type-label">Filter by Type</InputLabel>
              <Select
                labelId="filter-by-type-label"
                onChange={handleTypeChange}
                defaultValue={typeOptions[0]?.value}
                label="Filter by Type"
              >
                {typeOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth size="small">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleDateFromChange}
                  value={dateFrom}
                  disableFuture
                  label="Date From"
                  views={['year', 'month', 'day']}
                  slotProps={{
                    textField: { size: 'small', error: false },
                    actionBar: {
                      actions: ['today', 'clear'],
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth size="small">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleDateToChange}
                  minDate={dateFrom}
                  value={dateTo}
                  disableFuture
                  label="Date To"
                  views={['year', 'month', 'day']}
                  slotProps={{
                    textField: { size: 'small', error: false },
                    actionBar: {
                      actions: ['today', 'clear'],
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <NoItemsWrapper
        length={notifications?.items?.length as number}
        isLoading={!notifications?.items?.length && isLoading}
        text="There are no notifications"
      >
        <>
          <TableContainer sx={{ mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <Typography fontWeight={600}>ID</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Type</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Status</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Message</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Details</Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography fontWeight={600}>Date</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {notifications?.items?.map((notification) => (
                  <TableRow key={notification?.id}>
                    <TableCell>#{notification?.id}</TableCell>
                    <TableCell>
                      <Tooltip title={notification?.type}>
                        {getIcon(notification)}
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          notification?.success
                            ? StatusChipLabelEnum.Success
                            : StatusChipLabelEnum.Failed
                        }
                        color={notification?.success ? 'success' : 'error'}
                        sx={(theme) => ({
                          background: notification?.success
                            ? theme.palette.success.light
                            : theme.palette.error.light,
                        })}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{notification?.message}</TableCell>
                    <TableCell>{getContent(notification)}</TableCell>
                    <TableCell>
                      <Typography paragraph m={0}>
                        {transformDate(
                          notification?.createdAt,
                          DateFormatEnum.DATE_WITH_SLASH_SHORT_YEAR
                        )}
                      </Typography>
                      <small>
                        {transformDate(
                          notification?.createdAt,
                          DateFormatEnum.ONLY_TIME
                        )}
                      </small>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={notifications?.meta?.total as number}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonProps={{
              disabled: !notifications?.meta?.prev,
            }}
          />
        </>
      </NoItemsWrapper>
    </Paper>
  );
};
