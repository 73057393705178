// created by Artem
import { FC, useEffect, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { AuthRegisterFormPage } from '../auth-register/auth-register-form.page';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useLocation } from 'react-router-dom';
import { AuthVerifyEmailPage } from '../auth-register/auth-verify-email.page';
import { AuthVerifyPhonePage } from '../auth-register/auth-verify-phone.page';
import SecurityIcon from '@mui/icons-material/Security';
import { StepsRegisterEnum } from '../../constants/spa-routes';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.secondary.main,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: theme.palette.secondary.main,
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ['Sign up', 'Email confirmation', 'Phone confirmation'];

type RegisterPageProps = {};

export const RegisterPage: FC<RegisterPageProps> = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { search } = useLocation();

  useEffect(() => {
    if (search === '') {
      setActiveStep(0);
    }
    if (search.includes(StepsRegisterEnum.VERIFY_EMAIL)) {
      setActiveStep(1);
    }
    if (search.includes(StepsRegisterEnum.VERIFY_PHONE)) {
      setActiveStep(2);
    }
  }, [search]);
  return (
    <Box
      sx={{
        marginTop: 24,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        {search === '' ? <LockOutlined /> : <SecurityIcon />}
      </Avatar>
      <Typography component="h1" variant="h5">
        {search === '' ? 'Sign up' : 'Code verification'}
      </Typography>
      <Stepper
        sx={{ m: 2 }}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {search === '' && <AuthRegisterFormPage />}
      {search.includes(StepsRegisterEnum.VERIFY_EMAIL) && (
        <AuthVerifyEmailPage />
      )}
      {search.includes(StepsRegisterEnum.VERIFY_PHONE) && (
        <AuthVerifyPhonePage />
      )}
    </Box>
  );
};
