import {
  Paper,
  Typography,
  styled,
  Box,
  Divider,
  Button,
  ListItemAvatar,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { Container } from 'shared/container/container';
import { theme } from '../../assets/theme';
import { useGetGreaderQuery } from '../../services/greader.service';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { NoItemsWrapper } from '../../shared';
import { useServerError } from '../../hooks';
import ListItemButton from '@mui/material/ListItemButton';
import { transformDate } from '../../helpers/dateTransformHelper';
import { DateFormatEnum } from '../../types/dateTimeFormatOption';
import { LinkIcon } from '../../assets/icons/link_icon';

const Section = styled(Paper)(() => ({
  padding: theme.spacing(2),
  width: '100%',
}));

export const GreaderList: FC = () => {
  const [nextPageToken, setNextPageToken] = useState<string>('');

  const handleFetchNextPage = (token: string) => {
    setNextPageToken(token);
  };

  const {
    data: greaderData,
    isError,
    error,
    isFetching,
    isLoading,
  } = useGetGreaderQuery(
    { continuationToken: nextPageToken },
    { refetchOnMountOrArgChange: true }
  );

  useServerError({ isError: isError, error: error });

  return (
    <Container sx={{ width: '100%', padding: 0 }}>
      <Section
        sx={{
          mt: 4,
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          alignItems: 'start',
          p: { sm: '24px 0', xs: '16px 0' },
        }}
        elevation={4}
      >
        <Typography pl={{ sm: '24px', xs: '16px' }} variant="h5">
          News
        </Typography>
        <NoItemsWrapper length={greaderData?.results?.length as number}>
          <List sx={{ width: '100%' }}>
            {greaderData?.results?.map((el) => {
              return (
                <>
                  <ListItem disablePadding key={el.id}>
                    <ListItemButton
                      component={'a'}
                      href={el.link}
                      target="_blank"
                      sx={{ p: { sm: '8px 24px', xs: '8px 16px' } }}
                      rel="noreferrer"
                      title={el.title}
                    >
                      <ListItemText
                        primary={transformDate(
                          el.publishedAt,
                          DateFormatEnum.DATE_WITH_TIME_WITH_DOT
                        )}
                      />
                      <ListItemText
                        sx={{ width: '100%' }}
                        primary={
                          <Typography
                            noWrap
                            sx={{ maxWidth: '95%', mb: '6px' }}
                          >
                            {el.title}
                          </Typography>
                        }
                        secondary={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography
                              fontSize={14}
                              noWrap
                              sx={{ maxWidth: '100%' }}
                            >
                              Author: {el?.author}
                            </Typography>
                            <Box display="flex" sx={{ alignItems: 'center' }}>
                              <LinkIcon />{' '}
                              {new URL(el.link)?.hostname?.replace('www.', '')}
                            </Box>
                          </Box>
                        }
                      />
                      <ListItemAvatar
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                          width: '100%',
                          maxWidth: '100px',
                          '& > img': {
                            maxWidth: '100%',
                            width: '100%',
                            height: 'auto',
                            borderRadius: '4px',
                            display: 'flex',
                          },
                        }}
                      >
                        {el?.image && <img src={el?.image} alt="Post image" />}
                      </ListItemAvatar>
                    </ListItemButton>
                  </ListItem>
                  <Divider variant="inset" component="li" sx={{ ml: 0 }} />
                </>
              );
            })}
          </List>
        </NoItemsWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            pr: '16px',
          }}
        >
          <Button
            variant={'contained'}
            disabled={isFetching || isLoading}
            onClick={() =>
              handleFetchNextPage(greaderData?.continuationToken || '')
            }
          >
            Show more
          </Button>
        </Box>
      </Section>
    </Container>
  );
};
