import { FC } from 'react';
import { Box } from '@mui/material';
import { ParseHtmlMarkdown } from 'components/html_markdown/parse_html_markdown';
import privacyPolicyContent from 'constants/static-pages/privacy-policy-content';

export const PrivacyPolicyPage: FC = () => {
  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ mt: 1, textAlign: 'left', width: '100%' }}>
        <ParseHtmlMarkdown content={privacyPolicyContent} />
      </Box>
    </Box>
  );
};
