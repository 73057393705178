import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountState } from './types';
import { UserBalance } from '../wallet/types';

const initialState: AccountState = {
  balance: null,
  isLoadingBalance: false,
};

export const accountReducer = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    setBalance(state: AccountState, action: PayloadAction<UserBalance>) {
      state.balance = action.payload;
    },
    setBalanceLoading(state: AccountState, action: PayloadAction<boolean>) {
      state.isLoadingBalance = action.payload;
    },
  },
});

export const { setBalance, setBalanceLoading } = accountReducer.actions;

export default accountReducer.reducer;
