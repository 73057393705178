import { FC, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useGetUserQuery } from '../../services';
import { Badge, Fade, Popper, PopperPlacementType } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface PdfBadgeWrapperProps {
  children: React.ReactNode;
  warningText?: string | boolean | undefined;
  isInvisible?: boolean;
}

export const PdfBadgeWrapper: FC<PdfBadgeWrapperProps> = ({
  children,
  warningText,
  isInvisible = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const { data: userData } = useGetUserQuery();

  const checkIfPdfApprove = userData?.RequestedPdf?.isApproved;

  const isBadgeInvisible =
    userData?.RequestedPdf && userData?.RequestedPdf?.isApproved;

  const handleHoverBadge =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!checkIfPdfApprove && !isInvisible) {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
      }
    };
  const handleClosePopper = () => {
    if (!checkIfPdfApprove && !isInvisible) {
      setOpen(false);
    }
  };

  return (
    <Badge
      color="warning"
      badgeContent={
        <PriorityHighIcon
          color="action"
          sx={{ height: '10px', width: '10px' }}
        />
      }
      invisible={isInvisible || isBadgeInvisible}
      onMouseEnter={handleHoverBadge('top')}
      onMouseLeave={handleClosePopper}
      onClick={handleHoverBadge('top')}
    >
      {children}
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ maxWidth: '350px' }}>
              <Typography sx={{ p: 2 }}>
                {warningText
                  ? warningText
                  : 'Your request is currently being processed! After approval You will be able to download the PDF'}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Badge>
  );
};
