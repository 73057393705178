import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, array } from 'yup';
import 'react-phone-input-2/lib/material.css';
import { IMoreAssets } from '../../types/user.profile.interface';
import { LoadingButton } from '@mui/lab';
import { theme } from '../../assets/theme';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {
  useActivateCryptoCurrencyAssetMutation,
  useGetCryptoCurrencyAssetsQuery,
  useGetUserQuery,
} from '../../services';
import { useNotification, useServerError } from '../../hooks';
import { CurrencyIconComponent } from '../currency_image';
import { NoItemsWrapper } from '../../shared';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Search = styled('div')(() => ({
  position: 'relative',
  borderRadius: '5px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  border: '1px solid rgba(180, 180, 180, 0.50)',
  margin: '10px 0 30px 0',
}));

const SearchIconWrapper = styled('div')(() => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '0.3',
}));

const StyledInputBase = styled(InputBase)(() => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing('14px', 1, '14px', 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

type MoreAssetsProps = {
  handleCancel?: () => void;
};
export const MoreAssetsForm: FC<MoreAssetsProps> = ({ handleCancel }) => {
  const [search, setSearch] = useState<string>('');
  const { showNotification } = useNotification();

  const { data: userData } = useGetUserQuery();

  const {
    data: cryptoCurrency,
    isFetching: cryptoCurrencyIsFetching,
    error: cryptoCurrencyError,
    isError: cryptoCurrencyIsError,
  } = useGetCryptoCurrencyAssetsQuery({});

  const [
    activateCurrencies,
    {
      isError: isErrorActivateCurrencies,
      error: errorActivateCurrencies,
      isSuccess: isSuccessActivateCurrencies,
      isLoading: isLoadingActivateCurrencies,
    },
  ] = useActivateCryptoCurrencyAssetMutation();

  useServerError({
    isError: cryptoCurrencyIsError,
    error: cryptoCurrencyError,
  });
  useServerError({
    isError: isErrorActivateCurrencies,
    error: errorActivateCurrencies,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IMoreAssets>({
    defaultValues: {
      assets: cryptoCurrency?.assets,
    },
    resolver: yupResolver(
      object({
        assets: array(object({})),
      })
    ),
  });

  const { fields } = useFieldArray<IMoreAssets>({
    name: 'assets',
    control,
  });

  const watchAssets = watch('assets');

  const onSubmit = (data: IMoreAssets) => {
    const filteredAssets = data.assets
      ?.filter((item) => item.isActive)
      ?.map((el) => ({ id: el.id }));
    activateCurrencies({ currencies: filteredAssets });
  };

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (cryptoCurrency?.assets) {
      setValue('assets', cryptoCurrency?.assets, { shouldDirty: false });
    }
  }, [cryptoCurrency]);

  useEffect(() => {
    if (isSuccessActivateCurrencies) {
      showNotification('Assets was updated!', 'success');
    }
  }, [isSuccessActivateCurrencies]);

  const isEqualAssets =
    JSON.stringify(watchAssets?.map((el) => el.isActive)) ==
    JSON.stringify(cryptoCurrency?.assets?.map((el) => el.isActive));

  const assetsWithIndex =
    watchAssets?.map((elem, index) => {
      return { ...elem, index: index };
    }) || [];

  const filteredFields = useMemo(() => {
    return assetsWithIndex?.filter((el) =>
      el?.name?.toLowerCase()?.includes(search.toLowerCase())
    );
  }, [assetsWithIndex, search]);

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        More Assets
      </Typography>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onChange={searchHandler}
        />
      </Search>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1, minHeight: '60vh' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <NoItemsWrapper
          length={filteredFields?.length as number}
          isLoading={!fields?.length && cryptoCurrencyIsFetching}
          text="There are no assets"
        >
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            {filteredFields?.map((field) => {
              return (
                <Box
                  key={field.id}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Controller
                    control={control}
                    name={`assets.${field.index}.isActive`}
                    render={({ field: { onChange, value } }) => {
                      const labelId = `checkbox-list-label-${field.name}`;

                      const handleChange = () => {
                        if (!field.status && field.isVisible) return;

                        onChange(!value);
                      };

                      return (
                        <ListItem
                          onClick={handleChange}
                          key={field.id}
                          disabled={!field.status && field.isVisible}
                          secondaryAction={
                            !field.status && field.isVisible ? (
                              <Box p="9px">
                                <Tooltip
                                  title={field.reason}
                                  arrow
                                  placement="top"
                                >
                                  <InfoOutlinedIcon color="disabled" />
                                </Tooltip>
                              </Box>
                            ) : (
                              <Checkbox
                                edge="start"
                                checked={value}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            )
                          }
                          sx={{
                            borderBottom: '1px solid #D9D9D9',
                            padding: 0,
                            minHeight: '70px',
                            '&.Mui-disabled': {
                              opacity: 'unset',
                            },
                          }}
                        >
                          <ListItemButton
                            role={undefined}
                            disabled={!field.status && field.isVisible}
                            dense
                            sx={{ padding: '8px 0' }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box width={30} height={30} mr={1}>
                                <CurrencyIconComponent
                                  iconString={field.icon}
                                />
                              </Box>
                              <Box>
                                <Typography mr={1} fontWeight="bold">
                                  {field.symbol}
                                </Typography>
                                <ListItemText
                                  id={labelId}
                                  primary={field.name}
                                />
                              </Box>
                            </Box>
                          </ListItemButton>
                        </ListItem>
                      );
                    }}
                  />
                </Box>
              );
            })}
            <Grid item>
              {errors.assets ? (
                <Typography
                  align="left"
                  variant="caption"
                  color={theme.palette.error.main}
                  sx={{ margin: '4px 14px 0 14px', width: '100%' }}
                >
                  {errors.assets.message}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </NoItemsWrapper>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        {handleCancel && (
          <Button sx={{ ml: 1 }} onClick={handleCancel}>
            Cancel
          </Button>
        )}
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={isEqualAssets || !userData?.kycVerified}
          variant="contained"
          sx={{ ml: 1 }}
          type="submit"
          loading={isLoadingActivateCurrencies || cryptoCurrencyIsFetching}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};
