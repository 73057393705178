export default `
<h1 style="text-align:center;">Privacy Policy</h1>
<ul>
	<li><strong>General Provisions</strong></li>
	<li>The virtual currency exchange platform QCE, available on the website <a href="http://www.qce.lt">www.qce.lt</a>, our platform (&quot;QCE&quot;, the &quot;Platform&quot;, the &quot;Website&quot;) offers a range of services, provided according to the Platform Terms and Conditions and all documents incorporated therein (the &quot;Services&quot;). The Services on the Platform is provided by QCE UAB (a limited liability company, company number 306371969, registered at Kalvarijų g. 125, LT-08221 Vilnius) incorporated in Lithuania, licensed by the Lithuanian Financial Crime Investigation Service to provide Virtual Currency Services (any of the aforementioned companies further may be referred to as the &quot;Company&quot;, &quot;we&quot; or &quot;us&quot;) depending on the country you access the Platform or use the Services from and subject you conclude the agreement with when accepting General Terms and Conditions and other related documents of the Platform. Kindly be advised that this Privacy Policy (the &quot;Policy&quot;) is applicable to QCE UAB only if you are a citizen of the European Union (the &quot;EU&quot;) or reside there and any of them acts as your Service provider.</li>
	<li>In order to provide Services through the Platform we will process personal data of our customers, their clients or representatives, other related persons, such as family members, beneﬁcial owners, transaction senders, etc. (all together referred to as &quot;Customer&quot; or &quot;you&quot;). Any personal data they gather, use or share about you is processed in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (&quot;GDPR&quot;) and other applicable laws.</li>
	<li>This Policy applies if you use the Platform and any of the Services, available through it and indicates how your personal data is being processed by any Company, which in such a case, acts as a personal data controller. The Policy explains how we process your personal data through any relationship we have, whether it would be a call through the phone, use of the Services on the Platform, a message via email or any other possible means. In case you provide information about other natural persons to the Company, you undertake to make this Policy known to them before the disclosure of such information to the Company.</li>
	<li>By using the Platform or any of the Services offered through it you conﬁrm you have read, understood and agreed with this Policy. The Company reserves the right to make changes to this Policy from time to time. An up-to-date version of the Policy is posted on the Platform, therefore, please do review it regularly.</li>
	<li><strong>Personal Data Management Principles</strong></li>
	<li>The Company or Partners undertakes to ensure your personal data is:
	<ul>
		<li>processed lawfully, fairly, and in a transparent manner in relation to you;</li>
		<li>collected for speciﬁed, explicit and legitimate purposes (e.g. prevention of money laundering and terrorist ﬁnancing, performance of Services, etc.), and not further processed in a manner that is incompatible with those purposes;</li>
		<li>adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;</li>
		<li>accurate and, where necessary, kept up to date;</li>
		<li>kept in a form which permits your identiﬁcation for no longer than is necessary for the purposes for which the personal data are processed;</li>
		<li>processed in a manner that ensures appropriate security of your personal data.</li>
	</ul>
	</li>
	<li>The Company follows the above indicated principles strictly during the processing of your personal data and requests the same from the data processors which it may use to process personal data on behalf of the Company.</li>
	<li><strong>Lawfulness of Personal Data Processing</strong>
	<ul>
		<li>Your personal data will be processed if:</li>
		<li>you have given consent to the processing of your personal data for one or more speciﬁc purposes; and/or</li>
		<li>processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract; and/or</li>
		<li>processing is necessary for compliance with a legal obligation to which we are subject; and/or</li>
		<li>31.4 processing is necessary for the purposes of the legitimate interests pursued by us or the third party;</li>
		<li>3.1.5 processing is necessary for the performance of a task carried out in the public interest or in the exercise of oﬃcial authority vested in us.</li>
	</ul>
	</li>
	<li>The Company may subject its Customers to decisions based solely on automated processing, including proﬁling, only if it is necessary for conclusion of a contract between you and the Company or due to provision of the Services under such contract, it is authorized by the law or you have expressed an explicit consent to such processing.</li>
	<li><strong>Purposes for Which Your Personal Data Is Being Processed</strong></li>
	<li>Your personal data is being processed for the purposes of:
	<ul>
		<li>account establishment;</li>
		<li>performance of the Services (sale and purchase, transfers of funds, payment collection, etc.);</li>
		<li>prevention of money laundering and terrorist ﬁnancing (implementation of the principle &quot;Know Your Customer&quot;);</li>
		<li>crime prevention;</li>
		<li>implementation of international sanctions;</li>
		<li>Services support;</li>
		<li>quality assurance;</li>
		<li>direct marketing;</li>
		<li>use of the Company&#39;s social networks accounts;</li>
		<li>proper and secure operation of the Platform.</li>
	</ul>
	</li>
	<li>The processing of your personal data is necessary for the implementation of the above indicated purpose(s), therefore, if you fail to provide the requested data the Company may not be able to provide your requested Services.</li>
	<li><strong>Methods of How Your Personal Data Is Being Collected</strong></li>
	<li>QCE (&lsquo;<strong>QCE</strong>&rsquo;) collects your personal data directly from you or from the third parties when:
	<ul>
		<li>you use or view the Platform;</li>
		<li>you register to the Platform;</li>
		<li>you use our Services;</li>
		<li>you request Services support;</li>
		<li>we execute Customer&#39;s due diligence or ongoing due diligence;</li>
		<li>we monitor your transactions;</li>
		<li>we check whether you are not related to fraudulent activities;</li>
		<li>your personal data has been provided by the authorized third party;</li>
		<li>we receive requests, orders, decisions or etc. from the third parties regarding you;</li>
		<li>you consent to the call being recorded.</li>
	</ul>
	</li>
	<li>5.2. QCE acts as a data controller. The Company or Partner may also collect your personal data from other QCE companies, as they are deﬁned in General Terms and Conditions of the Platform, upon the change of the QCE company as your service provider.</li>
	<li><strong>Categories of The Processed Personal Data</strong></li>
	<li>The scope of the Customer&#39;s personal data indicated below which could be requested by the Company or Partner and further processed in order to provide the Services for the purposes indicated in article 4 of this Policy may vary depending on the type of Services chosen particularly by the Customer and Company&#39;s applied veriﬁcation procedures to execute it, as well as, legal requirements applied for such provision of Services in order to prevent possible risks and various crimes.</li>
	<li>In order to provide the Services, we or Partner may process your personal data categories, such as (including but not limited):
	<ul>
		<li><strong>General data</strong>: name, surname, personal code, date of birth, citizenship(s), place of birth (city, country), country of residence for tax purpose, taxpayer identiﬁcation number (TIN), address, city/town, postal code, phone number, email, networks account information (i.e. Facebook, Google, Skype) signature, selﬁe with the identity document, video, data about Customer which may be provided in double-checking systems;</li>
		<li><strong>Other Customer&#39;s proﬁle information</strong>: proﬁle type, unique character sequence assigned to the Customer for identiﬁcation, executed Customer&rsquo;s assessment (evaluation) results, 2FA information, member ID, user ID, user PIN, referral code, referral ID, session ID, login status, social sign on type, email conﬁrmed status, phone conﬁrmed status, secret questions information, compliance oﬃcer comments;</li>
		<li><strong>Social network data</strong>: social sign on type, social network proﬁle photo, name, surname, your comments, emotions and other actions expressed via our social network account, other your social network proﬁle information provided by you;</li>
		<li><strong>Identity document data</strong>: ID Type (Passport/Identity Card/Residence permit), its copy, MRZ, document number, date of issue, date of expiry;</li>
		<li><strong>Data obtained and/or created while performing legal obligation</strong>: inquiries, requests, notiﬁcations, orders, courts decisions or other data related to the speciﬁc Customer(s) which may be received by or provided to the police, courts, investigative bodies, notaries, tax administrator, courts, bailiffs and other institutions;
		<ul>
			<li><strong>Information about Customer&lsquo;s occupation and income sources</strong>:
			<ul>
				<li>Speciﬁc occupation: paid employee/owner of legal entity (company name)/registered self-employee/student/retired/unemployed;</li>
				<li>Main sectors of customer&#39;s occupation, individual or business activity;</li>
				<li>Information regarding countries in which customer is employed, carries out individual activity or business: countries, whereas activity or business is conducted or registered in preferential tax zone, percentage of turnover in cash for such activity or business, percentage of turnover in individual or business activity is handled in cryptocurrency;</li>
			</ul>
			</li>
			<li><strong>Account opening information</strong>:
			<ul>
				<li>Services which the client plans to use;</li>
				<li>Source of funds in Customers account;</li>
				<li>Monthly planned account turnover in EUR; countries from which the funds will be received or transferred;</li>
			</ul>
			</li>
			<li><strong>Information about Politically Exposed Person (&quot;PEP&quot;):</strong>
			<ul>
				<li>Information whether the Customer is PEP itself or has an immediate relationship with PEP;</li>
				<li>General information regarding PEP: relation, name, surname, country, PEP&#39;s position;</li>
			</ul>
			</li>
			<li><strong>Information about Beneﬁcial Owner (&quot;UBO&quot;):</strong>
			<ul>
				<li>Information whether the Customer is the UBO of the account and the funds in the account;</li>
				<li>General UBO&#39;s identiﬁcation data: name, surname, date of birth, citizenship, country of residence for tax purposes, tax identiﬁcation number (TIN), place of birth (city, country), registered residential address, share of beneﬁt;</li>
			</ul>
			</li>
			<li><strong>Financial data:</strong>
			<ul>
				<li>information about linked card(s) (i.e. currency, partial card number, validity date, card&#39;s owner name and surname, CVV/CVV2);</li>
				<li>information about pre-paid card(s);</li>
				<li>accounts related to the Customer&#39;s account on the Platform (used for receiving/sending funds);</li>
				<li>information about accounts in other ﬁnancial institutions (i.e. name of institution, country, account number);</li>
				<li>information about other Customer&#39;s cryptocurrencies;</li>
				<li>information about used QCE merchant services;</li>
				<li>transactions information: transaction ID, method, type (credit, deposit, withdrawal, exchange), status, sender, recipient (ID), QR code/wallet/ﬁat account number, payment provider&#39;s information, time and date register, order ID, amount, currency (code), coupon code;</li>
			</ul>
			</li>
			<li><strong>Communication data</strong>: date, time, correspondence, video and voice calls, chats, etc.</li>
			<li><strong>Information related to electronic devices</strong>: IP address(es); time zone; log-in and log-out register; browser information; electronic device&#39;s operating system information; location data (country (code), city), internet service provider (ISP); selected language; information regarding Customer&#39;s actions within Website;</li>
			<li><strong>History data</strong>: customer&#39;s experience using the Website, the register of all Customer&#39;s actions performed on the Platform (i.e. operations, such as funds transactions, linking cards, log-in and log-out register, register of reset passwords, separately expressed Customer&#39;s consents for personal data processing (i.e. for direct marketing);</li>
			<li><strong>Other data which may be requested or gathered by the Company or provided by the Customer herself/himself or any third party.</strong></li>
		</ul>
		</li>
	</ul>
	</li>
	<li><strong>Personal Data Recipients</strong></li>
	<li>Your personal data indicated in article 6 of this Policy may be provided by the Company itself or upon respective request to the below indicated categories of personal data recipients:
	<ul>
		<li>credit, ﬁnancial, payment and (or) electronic money institutions;</li>
		<li>payment services providers, as well as intermediary services providers;</li>
		<li>cards providers;</li>
		<li>authorities (i.e. supervising institutions, law enforcement institutions, courts);</li>
		<li>auditors, legal and ﬁnancial consultants;</li>
		<li>IT providers;</li>
		<li>marketing services providers;</li>
		<li>telecommunication and call recording service providers;</li>
		<li>fraud detection services providers;</li>
		<li>data processors;</li>
		<li>any of the Companies, in order to ensure smooth provision of the Services on the Platform upon change of the Company as your Service provider;</li>
		<li>other Partners of QCE, as they are deﬁned in annexes of General Terms and Conditions of the Platform, in order to ensure smooth and easy delivery of the Services;</li>
		<li>Other service providers which services may include, or which are engaged in personal data processing executed by the Company.</li>
	</ul>
	</li>
	<li>Personal data may also be provided to other recipients if:
	<ul>
		<li>the Company has to comply with a legal obligation to which it is a subject; or</li>
		<li>such requested personal data is necessary for the concrete data recipient to carry out a particular inquiry in the general interest, in accordance with the European Union or Member State law; or</li>
		<li>the data requesting party has a legitimate interest to request for such information;</li>
		<li>Other grounds indicated in Article 6 of GDPR are applied.</li>
	</ul>
	</li>
	<li>The Company maintains strong cooperation with local and international authorities and institutions, therefore, upon request of such party substantiated under article 7.2 of this Policy, your personal data may be provided to the requested party without permission to notify you.</li>
	<li>In general, the Company processes your personal data within the EU or the European Economic Area (the &quot;EEA&quot;), however, there might be some cases when the Company cooperates with the recipients outside the EU or EEA. In such cases the Company makes all reasonable efforts to ensure that at least one of the following GDPR requirements is complied:
	<ul>
		<li>the recipient is located in the territory which is acknowledged by the European Commission as ensuring the adequate level of personal data protection;</li>
		<li>the Company and the recipient have concluded the Standard contractual clauses regarding personal data transferring which were approved by the European Commission;</li>
		<li>The Codes of conduct or other measures indicated in Chapter V of GDPR have been complied.</li>
	</ul>
	</li>
	<li><strong>Personal Data Storage</strong></li>
	<li>The Company processes personal data so that it could achieve the purposes indicated in article 4 of this Policy.</li>
	<li>In order to set the below indicated data retention periods the Company has referred to the legal acts and public recommendations applicable in the European Union and locally such as compliance with legit limitation periods, as well as current business practice.</li>
	<li>Depending of the category of personal data and the purpose it is being processed your data retention period applied within the Company as it is required by the law or business practice to ensure smooth delivery of the Services is:
	<ul>
		<li>For the purposes indicated in articles 4.1.1- 4.1.5 of this Policy we process your personal data throughout the term of our contractual relationship and store it after this relationship ends for as many years as it is required by law (for example, it may be required to store such data for additional 8 years). The personal data processing for such a period is based on the necessity to execute Customer&#39;s due diligence, conduct ongoing monitoring, collect supporting evidence and records of transactions. In case the contractual relationship has not been established based on reasons not related with prevention of money laundering and terrorist ﬁnancing (e.g. you decided not to ﬁnish application or veriﬁcation procedure due to your own personal reasons) your personal data will be stored for 3 years since the last contact with you or action from your side;</li>
		<li>for the purposes indicated in article 4.1.6 - 4.1.7 we process your personal data collected via correspondence with you throughout the term of our contractual relationship and store it after this relationship ends for as many years as it is required by law (for example, it may be required to store such data for additional 5 years). The same storage principle applies to your personal data processed for the aforementioned purposes and collected if we communicate when you have not established or have already terminated a contractual relationship with us (for example, due to the nature of our business, it may be necessary to store such data for 3 years since the day of our last contact with each other via available communication means). The personal data processing for such periods is based on necessity to keep records of communication with you;</li>
		<li>for the purpose indicated in article 4.1.8 we may process your personal data throughout the term you are registered to use the Services and/or have contractual relationship with us, or throughout the term your consent is valid (up to 3 years, if not renewed), except in cases when you have objected to such processing, terminated the contractual relationship, requested erasure of your personal data or other provisions for data erasure have been applied, and after the day of termination of relationship or expiration date/ revocation of the consent we store it for additional period of time due to possible claims (for example, possible prescription period for claims can be 3 years) although newsletters will not be sent during this period;</li>
		<li>the purpose indicated in article 4.1.9 your personal data will be processed for 6 months since the date the record of the call has been made;</li>
		<li>for the purposes indicated in article 4.1.10 we process your personal data until your social network account or Company&rsquo;s social network account is deleted &ndash; whichever comes ﬁrst;</li>
		<li>for the purposes indicated in article 4.1.11 we process your personal data throughout the term we support the Platform.</li>
	</ul>
	</li>
	<li>Upon the end of the retention period, indicated above, your personal data is erased or anonymized irreversibly.</li>
	<li><strong>Direct Marketing</strong></li>
	<li>In order to provide you generic or personalized up-to-date news about Company products, Services and proposals and/or ask your opinion about the Services, your email address and/or telephone number might be processed by the Company for direct marketing purposes.</li>
	<li>We process your personal data for direct marketing purposes based on your explicitly expressed consent. We may also send personalized direct marketing material based on Company&rsquo;s legitimate interest according to the applicable laws and regulations and categorization of your proﬁle information (such as customer&rsquo;s type, last login, last transaction time, Services being ordered/used, source used for accessing the Services, customer&rsquo;s veriﬁcation status, residing country, transaction information (amount, number, etc.), use of referral code/link) in order to inform you about the news that may be mostly interesting and relevant to you and/or ask your opinion about the speciﬁc Services.</li>
	<li>In order to send you the direct marketing material we may use third party services and share your email address and/or telephone number with it.</li>
	<li>Your contact details, indicated in article 9.1, will be processed for the period indicated in article 8.3.3 of this Policy. Together with your email address and/or telephone number your personal data, such as IP address and the date when you gave a consent and or you have renewed it (and later on also the day of termination of Services or expiration/revocation date of the consent), will be processed for the aforementioned period.</li>
	<li>You shall have a right to object at any time to processing of your personal data for the purpose of direct marketing, which includes proﬁling to the extent that is related to such direct marketing, by changing the settings in your proﬁle, clicking on the withdrawal link provided in the received newsletters or by sending us a request at the contacts provided in the Platform.</li>
	<li><strong>Information Security</strong><br />
	10.1 The Company takes various security ensuring technologies and procedures in order to protect your personal data against unauthorized or unlawful processing, accidental loss, misuse, unauthorized access, illegal usage, destruction, disclosure, damage, etc. This includes legal, organizational, technical, and physical security measures, such as latest security systems, two-factor authentication and passwords, ability to detect cyber security attacks and other threats to the integrity of the Platform, working only with trustworthy service providers, etc. However, no transmission of information via email or other telecommunication channels or your access to the Platform or the Services through the internet could be fully secured. Therefore, you should take due care when you are accessing the Platform or using the Services via the internet or sharing conﬁdential information via email or other telecommunication channels.</li>
	<li><strong>Cookies</strong><br />
	11.1 Cookies are small information ﬁles found in the Platform you visit and stored in your computer or mobile device. In order to get to know more about cookies, please read <strong>Cookie Policy </strong>on the Platform</li>
	<li><strong>Your Rights Regarding the Processing of Your Personal Data</strong></li>
	<li>You have certain legal rights in relation to the processing of your personal data, including:
	<ul>
		<li>the right to obtain conﬁrmation as to whether or not personal data concerning you are being processed, and, where that is the case, access to the personal data and the information regarding its processing;</li>
		<li>the right to obtain from us without undue delay the rectiﬁcation of inaccurate personal data concerning you and, taking into account the purpose of the processing, the right to have incomplete personal information completed;</li>
		<li>the right to obtain from us the erasure of personal data concerning you without undue delay if one of the grounds set out in article 17 of the GDPR applies;</li>
		<li>the right to obtain from us restriction of processing where one of the grounds set out in article 18 of the GDPR applies;</li>
		<li>the right to data portability in accordance with article 20 of the GDPR;</li>
		<li>the right to object at any time to processing of your personal data in accordance with article 21 of the GDPR;</li>
		<li>the right not to be subject to automated individual decision-making, including proﬁling in accordance with article 22 of the GDPR.</li>
	</ul>
	</li>
	<li>This Policy does not deprive you of any other legal rights you may enforce under the applicable law.</li>
	<li>The Customer may exercise his/her rights only after the Company has successfully identiﬁed him/her. If the Company is not sure about the identity of the person sending the data request, the Company may not provide the requested information to him/her, unless the Customer&#39;s identity is conﬁrmed. Therefore, if you would like to address a request towards the company regarding execution of your rights, we suggest you do it through Live Chat once you are logged in to your account on the Platform (so that we could identify you). In case you decide to use other communication channels, such as sending a request via email, kindly ask you to indicate in the email your name, surname, your date of birth and the last four digits of your identity document number which you used when entering into business relationship with the company (if you have provided it before). In addition, the Company keeps the right to decide if the other or additional legitimate means of identiﬁcation proof should be requested, such as a selﬁe with your ID document, certiﬁed copy of your ID document, video or voice call, or any other additional document or method which could determine your identity.</li>
	<li>The Customer is provided with information related to the exercise of their rights free of charge. However, the Customer&#39;s request for the exercise of rights may be waived or may be subject to an appropriate fee if the request is manifestly unfounded or excessive, in particular because of their repetitive character.</li>
	<li>The Company shall provide the Customer with information on the actions taken upon receipt of the Customer&#39;s request for the exercise of his rights or the reasons for the inaction no later than within 1 month from the receipt of the request. The period for submitting the requested information may be extended, if necessary, for 2 more months, depending on the complexity and number of requests. When the Customer submits the request by electronic means, the information shall also be provided by electronic means.</li>
	<li>If the Customer considers that his/her personal data is being processed in violation of his/her rights and legitimate interests in accordance with applicable law, the Customer shall have the right to ﬁle a complaint against the processing of personal data to the State Data Protection Inspectorate located in the country where your data controller is incorporated.</li>
	<li><strong>Your Responsibilities</strong></li>
	<li>You conﬁrm that you have provided correct data about yourself in every required form and that afterwards, when changing or adding any data at the Platform, you will enter only correct data. The Company will not tolerate invalid, false or otherwise incorrect data and will pursue actions in accordance with its legal obligations. You shall bear any losses that occur with regard to the submission of invalid, false or otherwise incorrect data.</li>
	<li>You are responsible for maintaining adequate security and control of every identiﬁcation number, password, and/or any other code that you use to access the Platform. If you have not complied with this obligation and/or could, but have not prevented it and/or performed it on purpose or due to own negligence, you assume the losses and undertake to reimburse the losses of other persons incurred as a result of your (in)action.</li>
	<li>In the event of loss of any password by yourself or if the password(s) are disclosed not due to your or Company&#39;s fault, or in case of a real threat that has occurred or may occur to your account, you undertake to change the password(s) immediately or, if you do not have a possibility to do that, not later than within 1 calendar day notify Company. The Company shall not be liable for consequences that have originated due to the notiﬁcation failure.</li>
	<li>After the Company receives the notiﬁcation from you as indicated above, the Company shall immediately suspend access to your account and provision of the Company&rsquo;s Services until a new password is provided/created for you.</li>
	<li>The Company draws your attention to the fact that email address and any other contact information you have chosen to link to your account are used for your identiﬁcation and communication. You undertake responsibility to protect these instruments and logins to them. You are responsible for password disclosure and for all operations performed after you use the password for a relevant account. We recommend memorizing your passwords and not to write them down or enter anywhere where they may be seen by other persons.</li>
	<li><strong>Contact Details</strong></li>
	<li>The designated representative is QCE UAB (a limited liability company, company number 306371969, registered at Kalvarijų g. 125, LT-08221 Vilnius) incorporated in Lithuania, contact email: <a href="mailto:dpo@QCE.lt">dpo@QCE.lt</a>.</li>
	<li>If you have any questions regarding this Policy or your personal data protection or if you want to withdraw your consent, or execute your rights you may contact our Data Protection Oﬃcer who monitors that your data processing executed by Company complies with the applicable data protection laws. You can reach our Data Protection Oﬃcer via email: <a href="mailto:dpo@QCE.lt">dpo@QCE.lt</a> or mail via postal address: <strong>QCE UAB</strong> (a limited liability company, company number 306371969, registered at Kalvarijų g. 125, LT-08221 Vilnius, Lithuania) with a notice &quot;Data Protection Oﬃcer&quot;.</li>
	<li><strong>Final Provisions</strong></li>
	<li>This Policy shall be viewed and applied in accordance with the GDPR and other applicable laws.</li>
	<li>The Company may change, amend, delete any of the provisions contained in this Privacy Policy at any time and in its sole discretion. Any such changes will be effective upon the posting of the revised Policy on the Platform and you are solely responsible for reviewing it. Your continued use of the Platform and Services following any such revisions to the Policy will constitute your acceptance of such changes. If you do not agree to any such changes, do not continue to use our Services.</li>
	<li>The Platform and Services may contain links to our partners or other third-party websites. If you use the services of our partners or other third-parties, their own privacy policies apply, and you will be covered by such respective policies.</li>
</ul>
`;
