import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Crypto } from 'store/wallet/types';

import { ExchangeForm } from '../..';

type ExchangeModalProps = {
  isOpen: boolean;
  symbol: Crypto;
  nativeAsset: string;
  onClose: () => void;
};

export const ExchangeModal: FC<ExchangeModalProps> = ({
  isOpen,
  onClose,
  symbol,
  nativeAsset,
}) => {
  const onModalClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onModalClose} fullWidth>
      <DialogTitle>Convert</DialogTitle>
      <DialogContent>
        <ExchangeForm
          symbol={symbol}
          nativeAsset={nativeAsset}
          onClose={onClose}
          withCloseButton
          loadingSx={{ top: 0, left: 0 }}
        />
      </DialogContent>
    </Dialog>
  );
};
