import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Crypto, Fiat } from 'store/wallet/types';
import { EXCHANGE_COIN_LIST, FiatCurrency } from 'shared/currencies/currencies';
import {
  useCountdown,
  useDebounce,
  useNotification,
  useServerError,
} from 'hooks';
import { TwoFaModalComponent } from 'shared';
import {
  useExchangeMutation,
  useGetAmountLimitsQuery,
  useGetCryptoCurrencyAssetsQuery,
  useGetExchangeFeeQuery,
  useGetUserQuery,
} from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'store';
import { setExchangeWaiting, setModalState } from 'store/ui';
import { CurrencyIconComponent } from '../../currency_image';
import { theme } from 'assets/theme';
import { numberFormatHelper } from '../../../helpers/numberFormatHelper';
import { getFilteredCurrencyList } from 'shared/currencies/currencies-list';
import { useRatesContext } from '../../../context/RatesContext';

type ExchangeFormProps = {
  withCloseButton?: boolean;
  exchangeBtnFullWidth?: boolean;
  symbol?: Crypto | string;
  onClose?: () => void;
  loadingSx?: SxProps<Theme>;
  setWalletSelectedCurrency?: Dispatch<SetStateAction<string>>;
  withAllOptions?: boolean;
  nativeAsset?: string;
};

type ExchangeFormData = {
  amount: number;
  fromCurrency: string;
  toCurrency: string;
};

const FUZE_PROVIDER = 'FUZE';

const FEE_INFO_INTERVAL = 9;

export const ExchangeForm: FC<ExchangeFormProps> = ({
  onClose = () => {},
  symbol = 'ETH',
  withCloseButton = false,
  exchangeBtnFullWidth = false,
  setWalletSelectedCurrency = () => {},
  withAllOptions,
  nativeAsset,
}) => {
  const { selectedCurrency: globalSelectedCurrency } = useAppSelector(
    (s) => s.uiReducer
  );
  const [selectedSymbol, setSymbol] = useState<Crypto | string>(symbol);
  const [finalValue, setFinalValue] = useState<number>(0);
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const [exchangeRate, setExchangeRate] = useState<number | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<Fiat | Crypto>(
    globalSelectedCurrency.symbol
  );

  const [
    exchange,
    {
      isError: isErrorExchange,
      error: errorExchange,
      isSuccess: isSuccessExchange,
      isLoading: isLoadingExchange,
    },
  ] = useExchangeMutation();
  const dispatch = useAppDispatch();
  const { data: userProfile } = useGetUserQuery();

  const { rates } = useRatesContext();

  const [currencies, setCurrencies] = useState<FiatCurrency[]>([]);

  useEffect(() => {
    const filteredCurrencies = getFilteredCurrencyList(userProfile);
    setCurrencies(filteredCurrencies);
  }, [userProfile]);

  const { balance } = useAppSelector((state) => state.accountReducer);

  const { data: cryptoCurrency } = useGetCryptoCurrencyAssetsQuery({});

  const walletSymbolMemo = useMemo(() => {
    return cryptoCurrency?.assets?.find(
      (item) => item?.symbol === selectedSymbol
    );
  }, [selectedSymbol]);

  const { data: amountLimit } = useGetAmountLimitsQuery({
    assetId: walletSymbolMemo?.assetWalletName as string,
  });

  useServerError({ isError: isErrorExchange, error: errorExchange });

  const { showNotification } = useNotification();

  const cryptoBalance = useMemo(
    () => balance?.deposit?.[selectedSymbol as Crypto]?.total || 0,
    [selectedSymbol, balance?.deposit]
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    watch,
    trigger,
    reset,
    setError,
    control,
  } = useForm<ExchangeFormData>({
    resolver: yupResolver(
      yup.object().shape({
        amount: yup
          .number()
          .typeError('Amount must be a number')
          .transform((value) => (isNaN(value) ? undefined : value))
          .default(undefined)
          .moreThan(0, 'Amount must be a positive number')
          .test(
            'enough-balance',
            'Not enough balance to exchange.',
            (val = 0) => val <= cryptoBalance
          )
          .max(
            amountLimit?.maxAmount || 0,
            `Maximum amount for ${selectedSymbol} is ${amountLimit?.maxAmount}`
          )
          .min(
            amountLimit?.minAmount || 0,
            `Minimum amount for ${selectedSymbol} is ${amountLimit?.minAmount}`
          )
          .required('Amount is required'),
      })
    ),
    mode: 'onChange',
  });

  const watchAmount = watch('amount');
  const watchSymbol = watch('fromCurrency');

  const {
    data: feeInfo,
    isFetching: isFetchingFee,
    isSuccess: isSuccessFee,
    isError: isErrorFee,
    error: feeInfoError,
    refetch: refetchFeeInfo,
  } = useGetExchangeFeeQuery(
    {
      amount: selectedAmount,
      assetId: balance?.deposit?.[selectedSymbol as Crypto]?.value as string,
      fiat: selectedCurrency,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !Number(selectedAmount) || !!errors.amount?.message,
    }
  );

  const selectedAmountHandler = () => {
    const valAfterComma = watchAmount?.toString().split('.')[1];
    if (valAfterComma?.length > 4) {
      setError('amount', {
        message: 'Amount must have at most 4 decimal places',
      });
    }
    if (!Object.entries(errors).length) {
      setSelectedAmount(watchAmount);
    } else {
      setSelectedAmount(0);
    }
  };

  useServerError({ isError: isErrorFee, error: feeInfoError });

  useDebounce({
    effect: selectedAmountHandler,
    dependencies: [watchAmount, selectedSymbol],
    delay: 500,
  });

  useEffect(() => {
    if (watchAmount) {
      trigger('amount');
    }
  }, [cryptoBalance, watchAmount, selectedSymbol]);

  useEffect(() => {
    if (watchSymbol) {
      const finalRate = feeInfo?.rate ?? 0;

      setSymbol(() => watchSymbol as Crypto);
      setWalletSelectedCurrency(watchSymbol as Crypto);
      setExchangeRate(() => (finalRate ? finalRate : null));
    }

    if (rates && watchAmount) {
      const finalRate = feeInfo?.rate ?? 0;

      const feeRes = feeInfo?.fee ?? 0;

      const isFinalValueValid =
        Number(watchAmount) && watchAmount * finalRate > feeRes;

      const finalValueRes = isFinalValueValid
        ? watchAmount * finalRate - feeRes
        : 0;

      setFinalValue(
        userProfile?.provider === FUZE_PROVIDER
          ? feeInfo?.finalValue || finalValueRes
          : finalValueRes
      );
    }
  }, [rates, watchSymbol, watchAmount, feeInfo, walletSymbolMemo]);

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    const newCurrency = event.target.value as Fiat;
    setSelectedCurrency(newCurrency);
  };

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleExchange = async (data: ExchangeFormData, code: string) => {
    const { amount, fromCurrency } = data;
    const asset = balance?.deposit?.[fromCurrency as Crypto]?.value;
    if (!asset) {
      return;
    }
    try {
      await exchange({
        assetId: asset,
        amount,
        fiat: selectedCurrency,
        code,
        ...(userProfile?.provider === FUZE_PROVIDER
          ? { quoteId: feeInfo?.quoteId }
          : {}),
      });
      dispatch(setExchangeWaiting({ status: true }));

      if (!userProfile?.hasLeaveFeedback) {
        dispatch(setModalState({ visible: true, name: 'reviews' }));
      }
    } catch {
      showNotification('Transaction is failed', 'error');
    }
  };

  const confirm2FaHandle = (status: boolean, code: string) => {
    if (!status) return;

    handleSubmit(async (data) => await handleExchange(data, code))();
  };

  const toCurrencyOptions = useMemo(() => {
    if (withAllOptions) {
      const cryptoAssets =
        cryptoCurrency?.assets.filter((el) => el.isActive) || [];
      return [...currencies, ...cryptoAssets].filter(
        (option) => option.symbol !== selectedSymbol
      );
    }

    return currencies;
  }, [selectedSymbol, currencies, withAllOptions]);

  const isToCrypto = EXCHANGE_COIN_LIST.map((item) => item.symbol).includes(
    selectedCurrency as Crypto
  );

  const isConertBtnDisabled =
    Object.keys(errors).length !== 0 ||
    isToCrypto ||
    !isDirty ||
    !Number(finalValue.toFixed(2)) ||
    isFetchingFee ||
    !!feeInfoError;

  const isNativeAssetEtherium = nativeAsset?.split('_').includes('ETH');

  useEffect(() => {
    if (selectedSymbol === selectedCurrency) {
      setSelectedCurrency('USD');
    }
  }, [selectedSymbol, selectedCurrency]);

  useEffect(() => {
    if (isSuccessExchange) {
      showNotification('Convert is success!', 'success');
      onModalClose();
    }
  }, [isSuccessExchange]);

  const timerCondition = useMemo(
    () =>
      !!watchAmount &&
      userProfile?.provider === FUZE_PROVIDER &&
      !errors.amount?.message,
    [watchAmount, userProfile?.provider, errors.amount?.message]
  );

  const finishTimerCallback = useCallback(
    () => refetchFeeInfo(),
    [refetchFeeInfo]
  );

  const { activeTimer, refreshTime } = useCountdown({
    timeFrom: FEE_INFO_INTERVAL,
    timerOnCondition:
      timerCondition && !isFetchingFee && isSuccessFee && !isErrorFee,
    timerOffCondition: !timerCondition,
    defaultTime: 0,
    finishCallback: finishTimerCallback,
    skip: userProfile?.provider !== FUZE_PROVIDER,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form>
        <Controller
          control={control}
          name="amount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              margin="normal"
              label="Amount"
              type="number"
              error={!!error?.message}
              helperText={error?.message}
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          )}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="from-currency-label">From Currency</InputLabel>
          <Select
            label="From Currency"
            labelId="from-currency-label"
            id="from-currency-select"
            {...register('fromCurrency', {
              required: 'From Currency is required',
            })}
            error={!!errors.fromCurrency}
            defaultValue={symbol}
            renderValue={(selected) =>
              cryptoCurrency?.assets
                .filter((el) => el.isActive)
                .filter((ec) => ec.symbol === selected)
                .map((ec) => (
                  <Box
                    key={ec.id}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    <Box width={30} height={30} mr={1}>
                      <CurrencyIconComponent iconString={ec.icon} />
                    </Box>
                    {ec.symbol}
                  </Box>
                ))
            }
          >
            {cryptoCurrency?.assets
              .filter((el) => el.isActive)
              .map((ec) => (
                <MenuItem
                  key={ec.id}
                  value={ec.symbol}
                  selected={symbol === ec.symbol}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box width={30} height={30} mr={1}>
                    <CurrencyIconComponent iconString={ec.icon} />
                  </Box>
                  {ec.symbol}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="to-currency-label">To Currency</InputLabel>
          <Select
            label="To Currency"
            labelId="to-currency-label"
            id="to-currency-select"
            {...register('toCurrency', {
              required: 'To Currency is required',
            })}
            error={!!errors.toCurrency}
            defaultValue="USD"
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            renderValue={(selected) => {
              const selectedOption = toCurrencyOptions.find(
                (option) => option.symbol === selected
              );
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Box width={30} height={30} mr={1}>
                    <CurrencyIconComponent
                      iconString={selectedOption?.icon as string}
                    />
                  </Box>
                  {selectedOption?.symbol}
                </Box>
              );
            }}
          >
            {toCurrencyOptions.map((option) => (
              <MenuItem
                key={`${option.symbol}-${option.id}`}
                value={option.symbol}
                selected={symbol === option.symbol}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box width={30} height={30} mr={1}>
                  <CurrencyIconComponent iconString={option?.icon as string} />
                </Box>
                {option.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {watchSymbol && symbol && (
          <>
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              noWrap
              title={`${numberFormatHelper(cryptoBalance)} ${watchSymbol}`}
            >
              Balance: {numberFormatHelper(cryptoBalance)} {watchSymbol}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              noWrap
              title={`${amountLimit?.minAmount} ${watchSymbol}`}
            >
              Minimum available amount: {amountLimit?.minAmount} {watchSymbol}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              noWrap
              title={`${amountLimit?.maxAmount} ${watchSymbol}`}
            >
              Maximum available amount:{' '}
              {feeInfo?.maxLimit || amountLimit?.maxAmount} {watchSymbol}
            </Typography>
            {Number(watchAmount) &&
            watchSymbol &&
            !Object.entries(errors).length &&
            !feeInfoError ? (
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                Exchange Rate:{' '}
                {isFetchingFee ? (
                  <Skeleton variant="text" sx={{ width: '50px' }} />
                ) : (
                  <>{numberFormatHelper(exchangeRate || 0)}</>
                )}
                {selectedCurrency} ≃ 1 {watchSymbol}
              </Typography>
            ) : null}
            {Number(watchAmount) &&
            watchSymbol &&
            !Object.entries(errors).length &&
            !feeInfoError ? (
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                Fee:{' '}
                {isFetchingFee ? (
                  <Skeleton variant="text" sx={{ width: '50px' }} />
                ) : (
                  feeInfo && !isErrorFee && feeInfo?.fee?.toFixed(2)
                )}{' '}
                {selectedCurrency}
              </Typography>
            ) : null}
            {Number(watchAmount) &&
            isNativeAssetEtherium &&
            !Object.entries(errors).length &&
            !feeInfoError ? (
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                Gas fee:{' '}
                {isFetchingFee ? (
                  <Skeleton variant="text" sx={{ width: '50px' }} />
                ) : (
                  feeInfo && !isErrorFee && feeInfo?.ethGasFee
                )}{' '}
                ETH
              </Typography>
            ) : null}
            {(finalValue || finalValue === 0) &&
            !Object.entries(errors).length &&
            Number(watchAmount) &&
            !feeInfoError ? (
              <Typography
                variant="body2"
                color={
                  !isFetchingFee && Number(finalValue.toFixed(2)) === 0
                    ? theme.palette.error.main
                    : 'textSecondary'
                }
                gutterBottom
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                Final Value:{' '}
                {isFetchingFee ? (
                  <Skeleton variant="text" sx={{ width: '50px' }} />
                ) : (
                  feeInfo &&
                  !isErrorFee &&
                  !Object.entries(errors).length &&
                  finalValue.toFixed(2)
                )}{' '}
                {selectedCurrency}
              </Typography>
            ) : null}
            {timerCondition &&
            !Object.entries(errors).length &&
            Number(watchAmount) &&
            !feeInfoError ? (
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                Time left:{' '}
                {isFetchingFee || !activeTimer ? (
                  <Skeleton variant="text" sx={{ width: '50px' }} />
                ) : (
                  feeInfo &&
                  !isErrorFee &&
                  !Object.entries(errors).length &&
                  `00:0${refreshTime}`
                )}
              </Typography>
            ) : null}
          </>
        )}
        <DialogActions>
          {withCloseButton && (
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          )}
          <TwoFaModalComponent
            disabled={isConertBtnDisabled}
            type="button"
            fullWidth={exchangeBtnFullWidth}
            variant="contained"
            color="primary"
            onComplete={confirm2FaHandle}
            skip={!userProfile?.isWithdraw2faEnabled}
            id="convertCryptoButton"
            loading={isLoadingExchange}
          >
            Convert
          </TwoFaModalComponent>
        </DialogActions>
      </form>
    </Box>
  );
};
