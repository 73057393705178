import { CRYPTO_CURRENCY_API } from '../constants/api-routes';
import { baseApi } from './base.api';
import { ICryptoCurrencyAssets } from '../types/crypto.currency.interface';

export const cryptoCurrencyService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCryptoCurrencyAssets: build.query<ICryptoCurrencyAssets, any>({
      query: (params) => ({
        url: CRYPTO_CURRENCY_API.ASSETS,
        params,
      }),
      providesTags: ['CryptoCurrency'],
    }),
    activateCryptoCurrencyAsset: build.mutation<
      any,
      { currencies: { id: number }[] }
    >({
      query: (body) => ({
        url: CRYPTO_CURRENCY_API.ACTIVATE,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CryptoCurrency'],
    }),
  }),
});

export const {
  useGetCryptoCurrencyAssetsQuery,
  useActivateCryptoCurrencyAssetMutation,
} = cryptoCurrencyService;
