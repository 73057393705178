export const PersonIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6615 9.8737C16.6615 10.9788 16.2225 12.0386 15.4411 12.82C14.6597 13.6014 13.5999 14.0404 12.4948 14.0404C11.3897 14.0404 10.3299 13.6014 9.54851 12.82C8.76711 12.0386 8.32813 10.9788 8.32812 9.8737C8.32813 8.76863 8.76711 7.70882 9.54851 6.92742C10.3299 6.14602 11.3897 5.70703 12.4948 5.70703C13.5999 5.70703 14.6597 6.14602 15.4411 6.92742C16.2225 7.70882 16.6615 8.76863 16.6615 9.8737ZM14.5781 9.8737C14.5781 10.4262 14.3586 10.9561 13.9679 11.3468C13.5772 11.7375 13.0473 11.957 12.4948 11.957C11.9423 11.957 11.4124 11.7375 11.0217 11.3468C10.631 10.9561 10.4115 10.4262 10.4115 9.8737C10.4115 9.32116 10.631 8.79126 11.0217 8.40056C11.4124 8.00986 11.9423 7.79036 12.4948 7.79036C13.0473 7.79036 13.5772 8.00986 13.9679 8.40056C14.3586 8.79126 14.5781 9.32116 14.5781 9.8737Z"
        fill="#8D8A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4974 1.54102C6.16927 1.54102 1.03906 6.67122 1.03906 12.9993C1.03906 19.3275 6.16927 24.4577 12.4974 24.4577C18.8255 24.4577 23.9557 19.3275 23.9557 12.9993C23.9557 6.67122 18.8255 1.54102 12.4974 1.54102ZM3.1224 12.9993C3.1224 15.1764 3.8651 17.1806 5.1099 18.7723C5.9841 17.6242 7.11188 16.6939 8.40514 16.0538C9.6984 15.4138 11.1221 15.0814 12.5651 15.0827C13.9894 15.0813 15.3952 15.4051 16.6755 16.0293C17.9557 16.6536 19.0765 17.5618 19.9526 18.6848C20.8552 17.501 21.4629 16.1194 21.7254 14.6541C21.988 13.1889 21.8978 11.6822 21.4625 10.2587C21.0271 8.83522 20.2591 7.53586 19.2219 6.46814C18.1846 5.40042 16.9081 4.59504 15.4978 4.11863C14.0875 3.64222 12.5841 3.50849 11.1118 3.72849C9.63961 3.94849 8.24093 4.5159 7.03153 5.38378C5.82213 6.25166 4.83678 7.39505 4.15701 8.71935C3.47724 10.0436 3.12258 11.5108 3.1224 12.9993ZM12.4974 22.3744C10.3453 22.3776 8.25809 21.6372 6.58906 20.2785C7.26087 19.3168 8.15504 18.5316 9.19551 17.9896C10.236 17.4477 11.392 17.1652 12.5651 17.166C13.7236 17.1651 14.8656 17.4406 15.8963 17.9696C16.9269 18.4986 17.8165 19.2659 18.4911 20.2077C16.8092 21.6105 14.6876 22.3774 12.4974 22.3744Z"
        fill="#8D8A9B"
      />
    </svg>
  );
};
