// created by Artem
import { FC, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import {
  Link as NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ResetPasswordFormValues } from 'types/auth.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { passwordConfirmScheme, passwordScheme } from 'utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useResetPassMutation } from 'services';
import { useNotification, useServerError } from 'hooks';

type ResetPasswordPageProps = {};
export const ResetPasswordPage: FC<ResetPasswordPageProps> = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { showNotification } = useNotification();

  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password'
  );
  const [resetPass, { isError, error }] = useResetPassMutation();

  const code: string = searchParams.get('code') || location.state?.code || '';
  const email: string =
    searchParams.get('email') || location.state?.email || '';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    defaultValues: { password: '', passwordConfirmation: '', code, email },
    resolver: yupResolver(
      object({
        password: passwordScheme({ required: true }),
        passwordConfirmation: passwordConfirmScheme({ required: true }),
      })
    ),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormValues> = (data) => {
    if (!data.code || !data.email)
      return showNotification('The recovery link is incorrect', 'error');

    resetPass({
      newPassword: data.password,
      email: data.email,
      code: data.code,
    })
      .unwrap()
      .then(() => navigate('/'))
      .catch(() => null);
  };

  const changePasswordType = () =>
    setPasswordType((prev) => (prev === 'text' ? 'password' : 'text'));

  useServerError({ isError, error });

  return (
    <Box
      sx={{
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          {...register('password')}
          error={!!errors?.password?.message}
          helperText={errors?.password?.message}
          margin="normal"
          required
          fullWidth
          id="password"
          type={passwordType}
          label="Password"
          name="password"
          autoComplete="new-password"
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={changePasswordType}>
                  {passwordType === 'text' ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...register('passwordConfirmation')}
          error={!!errors?.passwordConfirmation?.message}
          helperText={errors?.passwordConfirmation?.message}
          margin="normal"
          required
          fullWidth
          id="passwordConfirmation"
          type={passwordType}
          label="Repeat password"
          name="passwordConfirmation"
          autoComplete="new-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </Button>
        <Link variant="body2" component={NavLink} to="/auth">
          Cancel
        </Link>
      </Box>
    </Box>
  );
};
