import { PATH_GREADER_API } from '../constants/api-routes';
import { baseApi } from './base.api';
import { GreaderInterface } from '../types/greader.interface';

export const reviewService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGreader: build.query<GreaderInterface, { continuationToken: string }>({
      query: (params) => ({
        url: `${PATH_GREADER_API.ROOT}`,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (!otherArgs.arg.continuationToken) {
          return {
            continuationToken: newItems.continuationToken,
            results: newItems.results,
          };
        }
        return {
          continuationToken: newItems.continuationToken,
          results: [...currentCache.results, ...newItems.results],
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetGreaderQuery } = reviewService;
