import { FC, useState } from 'react';
import { Avatar, Box, SxProps, Theme } from '@mui/material';

import { useGetUserQuery } from '../../services';
import { useDispatch } from 'react-redux';
import { setModalState } from '../../store/ui';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})<{ isHovered: boolean }>(({ theme, isHovered }) => ({
  '& .MuiBadge-badge': {
    top: 13,
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: 'white',
    transition: '0.2s',
    fontSize: '1rem',
    background: isHovered ? 'rgb(29,105,0)' : 'rgb(44,152,0)',
  },
}));

interface AvatarComponentProps {
  width?: number | string;
  height?: number | string;
  avatarSx?: SxProps<Theme>;
  avatarBoxSx?: SxProps<Theme>;
  disableUpload?: boolean;
  hideBadge?: boolean;
  textVariant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | undefined;
}

export const AvatarComponent: FC<AvatarComponentProps> = ({
  width = 65,
  height = 65,
  avatarSx,
  avatarBoxSx,
  disableUpload = false,
  hideBadge = true,
  textVariant = 'h4',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const { data: userData } = useGetUserQuery();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const uploadAvatarHandler = () => {
    dispatch(setModalState({ visible: true, name: 'avatarUploadModal' }));
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        cursor: disableUpload ? 'inherit' : 'pointer',
        ...avatarBoxSx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={disableUpload ? () => {} : uploadAvatarHandler}
    >
      <StyledBadge
        badgeContent={'+'}
        invisible={hideBadge}
        isHovered={isHovered}
      >
        <Avatar
          alt={userData?.name + '' + userData?.lastName}
          src={userData?.profileImage ? userData?.profileImage : ''}
          sx={{
            width,
            height,
            background:
              'linear-gradient(138deg, #535353 -2.49%, #BFBFBF 51.42%, #4D4A4A 100%)',
            ...avatarSx,
          }}
        >
          <Typography variant={textVariant} fontWeight="bold" lineHeight={0}>
            {userData?.name[0] + '' + userData?.lastName[0]}
          </Typography>
        </Avatar>
      </StyledBadge>
    </Box>
  );
};
