import React, { FC } from 'react';

import { Dialog, DialogContent } from '@mui/material';
import { MoreAssetsForm } from '../../more_assets_form';

type MoreAssetsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MoreAssetsModal: FC<MoreAssetsModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogContent>
        <MoreAssetsForm handleCancel={onClose} />
      </DialogContent>
    </Dialog>
  );
};
