import { AnyObject, boolean, number, ref, string, StringSchema } from 'yup';
import IBAN from 'iban';

interface ISchemeOptions {
  required?: boolean;
  min?: number;
  max?: number;
}

const EMAIL_REGEXP =
  /^[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const emailScheme = (opt?: ISchemeOptions) => {
  const rule = string()
    .email('Email must be valid')
    .required('Email is required field')
    .matches(EMAIL_REGEXP, 'Email must be valid')
    .strict();

  if (opt?.required) return rule.required();

  return rule;
};

export const booleanScheme = (opt?: ISchemeOptions) => {
  const rule = boolean();
  if (opt?.required) return rule.required();

  return rule;
};

export const passwordScheme = (opt?: ISchemeOptions) => {
  return string()
    .test('required', 'Password is required field', (value) => {
      return opt?.required ? !!value : true;
    })
    .min(opt?.min || 8)
    .max(opt?.max || 16)
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter');
};

export const passwordConfirmScheme = (opt?: ISchemeOptions) => {
  return string()
    .test('required', 'Password confirmation is required field', (value) => {
      return opt?.required ? !!value : true;
    })
    .min(opt?.min || 8)
    .max(opt?.max || 16)
    .oneOf([ref<any>('password')], 'Your passwords do not match.');
};

export const stringScheme = (opt?: ISchemeOptions) => {
  const rule = string().min(opt?.min || 0);
  if (opt?.required) return rule.required();

  return rule;
};

export const ibanScheme = (
  schema: StringSchema<string | undefined | null, AnyObject, undefined, ''>,
  opt?: ISchemeOptions
) => {
  const rule = schema.test(
    'iban',
    'Invalid IBAN (example: BE68539007547034)',
    (value) => (value ? IBAN?.isValid?.(value) : true)
  );

  if (opt?.required) return rule.required();

  return rule;
};

export const nameScheme = (opt?: ISchemeOptions) => {
  const rule = string()
    .min(opt?.min || 0)
    .max(opt?.max || 99999);

  if (opt?.required) return rule.required();

  return rule;
};

export const totalScheme = (opt?: ISchemeOptions) => {
  const rule = number()
    .min(opt?.min || 0)
    .max(opt?.max || 99999);

  if (opt?.required) return rule.required();

  return rule;
};

export const phoneNumberScheme = (opt?: ISchemeOptions) => {
  return string()
    .test('required', 'Phone number is required field', (value) => {
      return opt?.required ? !!value : true;
    })
    .min(opt?.min || 5, 'Minimum 5 digits')
    .max(opt?.max || 15, 'Maximum 15 digits');
};
